import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  role: null,
  isVerified: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      state.role = user.role;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.role = null;
      state.isVerified = false;
    },
    updateIsVerified: (state, action) => {
      state.isVerified = action.payload.isVerified;
    },
  },
});

export const { setUser, logout, updateIsVerified } = authSlice.actions;

export default authSlice.reducer;

export const useCurrentToken = (state) => state.auth || {};
export const useCurrentUser = (state) => state.auth || {};
export const useCurrentRole = (state) => state?.auth?.role || {};
export const useIsVerified = (state) => state?.auth?.isVerified || {};
