/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select"; // Import react-select
import "../login/loginsignup.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import toast, { Toaster } from "react-hot-toast";

import { useCheckUserEmailMutation } from "../../redux/api/userApi";
import {
  useLoginMutation,
  useSignUpMutation,
} from "../../redux/features/auth/authApi";
import { useDispatch } from "react-redux";
import { setUser, updateIsVerified } from "../../redux/features/auth/authSlice";

function Signup() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(null); // State for country code dropdown
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  // Sample country code options
  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+880", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" }, // Added placeholders for BGD and US
    // Add more country options as needed
  ];
  const handleFirstNameChange = (event) => {
    const firstNameValue = event.target.value;
    if (firstNameValue.length <= 20) {
      setFirstName(firstNameValue);
      validateName(firstNameValue, "firstName");
    }
  };

  const handleLastNameChange = (event) => {
    const lastNameValue = event.target.value;
    if (lastNameValue.length <= 20) {
      setLastName(lastNameValue);
      validateName(lastNameValue, "lastName");
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validatePassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    validateConfirmPassword(event.target.value);
  };

  const handlePhoneChange = (selectedOption) => {
    // When a country code is selected from the dropdown, update the phone state accordingly
    setCountryCode(selectedOption);
    setPhone(""); // Clear phone number when country code changes
  };

  const handlePhoneInputChange = (event) => {
    const phoneValue = event.target.value;

    if (phoneValue.length <= 10) {
      setPhone(phoneValue);
      validatePhone(phoneValue);
    }
  };

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const validateName = (name, fieldName) => {
    if (!name) {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: false,
      }));
      return;
    }
    const minLength = 2; // Minimum length for name
    const namePattern = /^[a-zA-Z ""]+$/;
    const isValidName = name.length >= minLength && namePattern.test(name);
    setErrors((prev) => ({
      ...prev,
      [fieldName]: isValidName
        ? null
        : `Minimum ${minLength} characters required & Only letters allowed.`,
    }));
  };

  const validateEmail = async (email, fieldName) => {
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        email: false,
      }));
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(email);

    setErrors((prev) => ({
      ...prev,
      email: isValidEmail ? null : "Invalid email address",
    }));

    const alreadyExists = await checkEmail(email);
    if (alreadyExists) {
      setErrors((prev) => ({
        ...prev,
        email: "Email already registered",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
      }));
    }
  };

  const [CheckUserEmail, { data }] = useCheckUserEmailMutation(email);

  const checkEmail = async (email) => {
    try {
      const response = await CheckUserEmail(email);
      const data = response.data;
      return data.message === email;
    } catch (error) {
      console.error("Error checking email:", error);
      return false;
    }
  };

  const validatePassword = (password) => {
    const uppercasePattern = /[A-Z]/;
    const specialCharacterPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const numericPattern = /\d/;

    let isValidPassword = true;
    let errorMessage = "";

    if (password.length < 8) {
      isValidPassword = false;
      errorMessage = "Password must be at least 8 characters long";
    } else if (!uppercasePattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one uppercase letter";
    } else if (!specialCharacterPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one special character";
    } else if (!numericPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one numeric digit";
    }

    setErrors((prev) => ({
      ...prev,
      password: isValidPassword ? null : errorMessage,
    }));
  };

  const validateConfirmPassword = (confirmPassword) => {
    const isValidConfirmPassword = confirmPassword === password;
    setErrors((prev) => ({
      ...prev,
      confirmPassword: isValidConfirmPassword ? null : "Passwords do not match",
    }));
  };

  const validatePhone = (phoneValue) => {
    const isNumeric = /^\d+$/.test(phoneValue);
    if (phoneValue.length === 0) {
      setErrors((prev) => ({
        ...prev,
        phone: false,
      }));
      return;
    }
    if (phoneValue.length < 10) {
      setErrors((prev) => ({
        ...prev,
        phone: "Phone number must be at least 10 digits",
      }));
    } else if (phoneValue.length > 10) {
      setErrors((prev) => ({
        ...prev,
        phone: "Phone number must be less than 10 digits",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        phone: isNumeric ? null : "Phone number must be numeric",
      }));
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const isSignUpButtonDisabled =
    !firstName ||
    !lastName ||
    !email ||
    !password ||
    !confirmPassword ||
    !phone ||
    !countryCode ||
    !termsAccepted ||
    Object.values(errors).some((error) => error !== null);

  const fullPhone = countryCode?.value + phone;

  const registerUser = {
    firstName,
    lastName,
    email,
    password,
    phone,
    countryCode: countryCode?.value,
  };

  const [signUp, { isError, status }] = useSignUpMutation(registerUser);
  const [login, { error }] = useLoginMutation();
  const handleSignUp = async (event) => {
    event.preventDefault();

    try {
      const { data } = await signUp(registerUser);

      if (data.statusCode === 200) {
        toast.success("Registration Successful", {
          duration: 2500,
          style: { background: "#0B5ED7" },
          className: "text-white",
        });
        // navigate("/");
        console.log("Registered Successfully");
      } else {
        throw new Error(
          `Registration Failed with status code: ${data.statusCode}`
        );
      }

      const loginData = {
        email,
        password,
      };
      const response = await login(loginData).unwrap();
      dispatch(
        setUser({
          user: response.data.user,
          token: response.data.accessToken,
          role: response.data.user.role,
        }),
        updateIsVerified({
          isVerified: response.data.isVerified,
        })
      );

      if (response.data.user.banStatus === false) {
        console.log("registered and logged in successfully");
      }

      const isVerified = response.data.user.verified;
      if (!isVerified) {
        navigate(`/verification/${email}`);
        return;
      }
    } catch (error) {
      toast.error("Registration or Login Failed");
      console.error(
        "Error during registration or login:",
        error.message || error
      );
    }
  };
  // const handleSignUp = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const response = await axiosSecure.post("/users/register", registerUser);
  //     console.log(response);
  //     if (response.status === 201) {
  //       toast.success("Registration Successful", {
  //         duration: 2500,
  //         style: { background: "#0B5ED7" },
  //         className: "text-white",
  //       });
  //       navigate("/");
  //       console.log("Registered Successfully");
  //     } else {
  //       toast.error("Registration Failed");
  //       console.error("Error creating account:", response.status);
  //     }
  //   } catch (error) {
  //     toast.error("Registration Failed");
  //     console.error("Error creating account:", error);
  //   }
  // };

  return (
    <div className="">
      <div className="bg-intro-custom ">
        <div className="row ">
          <div className="col-md-6 ">
            <div className="d-flex bg-left justify-content-center align-items-center fixed sticky-top">
              <Swiper
                autoplay={{
                  delay: 1600,
                  disableOnInteraction: false,
                }}
                speed={1500}
                loop={true}
                navigation={true}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper slide_login mt-5 px-1"
              >
                <SwiperSlide>
                  <div className="login_text margin-slide">
                    <p>
                      <strong>Update</strong> July 07 2024
                    </p>
                    <h1 className="login_h1">
                      Open Your Business In the United States
                    </h1>
                    <p className="margin-slide-text">
                      We provide the professional services to start & run a
                      company Digitally in the United States from Bangladesh.
                    </p>
                  </div>
                  <img
                    src="https://digitxgroup.com/wp-content/uploads/2024/05/Group-12-1.png"
                    className="w-100 h-100 slide_img_login px-5"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <div className="login_text margin-slide">
                    <p>
                      <strong>Update</strong> July 07 2024
                    </p>
                    <h1 className="login_h1">
                      All-in-one Platform for Managing your Business
                    </h1>
                    <p className="margin-slide-text">
                      Start your company with our online incorporation service
                      and maintain accounts and operation with Inocorplink.
                    </p>
                  </div>
                  <img
                    src="https://digitxgroup.com/wp-content/uploads/2024/05/Group-12-1.png"
                    className="w-100 h-100 slide_img_login px-5"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="login_text margin-slide">
                    <p>
                      <strong>Update</strong> July 07 2024
                    </p>
                    <h1 className="login_h1">Get your Payment Instantly</h1>
                    <p className="margin-slide-text">
                      Process your client payment instantly and transfer in your
                      bank account.
                    </p>
                  </div>
                  <img
                    src="https://digitxgroup.com/wp-content/uploads/2024/05/Group-12-1.png"
                    className="w-100 h-100 slide_img_login px-5"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-white d-flex justify-content-center align-items-center h-100">
              <div className=" px-3 pb-3 mb-5 w-100 log_tex intro-margin-custom ">
                <iframe
                  src="https://lottie.host/embed/4b9dda67-f761-4a97-b410-00e381c992f2/eUw500Zccr.json"
                  className="lottie_login"
                ></iframe>
                <h2 className="login">Create an account 👨‍💻</h2>
                <p className="">
                  We will get started by making an account to enter & access
                  your company information{" "}
                </p>
                <form onSubmit={handleSignUp}>
                  <div className="mb-3">
                    <label htmlFor="firstName">
                      <strong>First Name</strong>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your First Name"
                      value={firstName}
                      onChange={handleFirstNameChange}
                      className="form-control rounded-2"
                    />
                    {errors.firstName && (
                      <span className="text-danger"> {errors.firstName}</span>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="lastName">
                      <strong>Last Name</strong>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Last Name"
                      value={lastName}
                      onChange={handleLastNameChange}
                      className="form-control rounded-2"
                    />
                    {errors.lastName && (
                      <span className="text-danger"> {errors.lastName}</span>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      value={email}
                      onChange={handleEmailChange}
                      className="form-control rounded-2"
                    />
                    {errors.email && (
                      <span className="text-danger"> {errors.email}</span>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="phone">
                      <strong>Phone</strong>
                    </label>
                    <div className="d-flex align-items-center gap-1  ">
                      <div className=" phone-box-selector phone_box rounded-2 gap-1">
                        <Select
                          className="select-country-box"
                          value={countryCode}
                          onChange={handlePhoneChange}
                          options={countryCodes}
                          isSearchable={false} // Disable text input
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "130px",
                            }),
                          }}
                        />
                      </div>
                      <input
                        type="number"
                        placeholder={
                          countryCode
                            ? `${countryCode.placeholder}`
                            : "Enter Your Phone Number"
                        }
                        value={phone}
                        onChange={handlePhoneInputChange}
                        className="form-control rounded-2"
                      />
                    </div>
                    {errors.phone && (
                      <span className="text-danger"> {errors.phone}</span>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password">
                      <strong>Password</strong>
                    </label>
                    <div className="input-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter Your Password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="form-control rounded-2"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {errors.password && (
                      <span className="text-danger"> {errors.password}</span>
                    )}
                    <div className="text-muted" style={{ fontSize: "12px" }}>
                      (Minimum 8 characters with one uppercase, one number, and
                      one special character. Example: P@ss1234)
                    </div>{" "}
                    {/* Text indicating password requirements */}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="confirmPassword">
                      <strong>Confirm Password</strong>
                    </label>
                    <div className="input-group">
                      <input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        placeholder="Confirm Your Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="form-control rounded-2"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {errors.confirmPassword && (
                      <span className="text-danger">
                        {" "}
                        {errors.confirmPassword}
                      </span>
                    )}
                  </div>

                  <div className="mb-3">
                    <input
                      type="checkbox"
                      id="terms"
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                    />
                    <label htmlFor="terms" style={{ marginLeft: "10px" }}>
                      I accept the Incorplink{" "}
                      <Link
                        className="text-decoration-none "
                        to="/termsAndConditions"
                      >
                        terms and condition
                      </Link>
                    </label>
                  </div>

                  <button
                    className={`btn btn-primary w-100 rounded-2 ${
                      isSignUpButtonDisabled ? "disabled" : ""
                    }`}
                    disabled={isSignUpButtonDisabled}
                    type="submit"
                  >
                    <strong>Sign up</strong>
                  </button>
                </form>
                <p className="text-center mt-3">
                  <strong>
                    Already have an account? <Link to="/">Sign In</Link>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
