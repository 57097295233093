import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../login/loginsignup.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useUpdatePasswordMutation } from "../../redux/features/auth/authApi";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const UpdatePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [activeButton, setActiveButton] = useState("password");
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };
  const validatePassword = (password) => {
    const uppercasePattern = /[A-Z]/;
    const specialCharacterPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const numericPattern = /\d/;

    let isValidPassword = true;
    let errorMessage = "";

    if (password.length < 8) {
      isValidPassword = false;
      errorMessage = "Password must be at least 8 characters long";
    } else if (!uppercasePattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one uppercase letter";
    } else if (!specialCharacterPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one special character";
    } else if (!numericPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one numeric digit";
    }

    setErrors((prev) => ({
      ...prev,
      password: isValidPassword ? null : errorMessage,
    }));
  };
  const validateConfirmPassword = (confirmPassword) => {
    const isValidConfirmPassword = confirmPassword === password;
    setErrors((prev) => ({
      ...prev,
      confirmPassword: isValidConfirmPassword ? null : "Passwords do not match",
    }));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e.target.value);
  };

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = {
      oldPassword: currentPassword,
      newPassword: password,
    };
    try {
      const result = await updatePassword(credentials).unwrap();
      console.log(result);
      if (result?.success === true) {
        toast.success("Password Reset Successfull", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
      } else {
        toast.error("Failed to change password");
      }
    } catch (err) {
      toast.error("Failed to change password");
      console.error("Error updating password:", err);
    }
  };

  const isSignUpButtonDisabled =
    !currentPassword ||
    !password ||
    !confirmPassword ||
    Object.values(errors).some((error) => error !== null);

  return (
    <div style={{}}>
      <div className="card p-4 m-md-4">
        <div className="profile_bg">
          <div className="row Id-row">
            <div class="lottie_update">
              <iframe src="https://lottie.host/embed/1f8b6006-1c0d-4437-a4d9-0fdd711b537c/6JnettE4OX.json"></iframe>
            </div>
            <div className="col-md-3">
              <div className="profile_button d-md-block d-flex gap-1">
                <Link
                  className={`profilebtn ${
                    activeButton === "profile" ? "active" : ""
                  }`}
                  to="/dashboards/profile"
                  onClick={(e) => handleButtonClick(e, "profile")}
                >
                  Profile
                </Link>{" "}
                <br />
                <Link
                  className={`profilebtn ${
                    activeButton === "password" ? "active" : ""
                  }`}
                  to="/dashboards/updatepassword"
                  onClick={(e) => handleButtonClick(e, "password")}
                >
                  Password
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="edit_profile">
                <div className="update_password">
                  <h4>Update Password</h4>
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="currentPassword">Current Password:</label>
                    <div className="input-group">
                      <input
                        type={isCurrentPasswordVisible ? "text" : "password"}
                        className="form-control"
                        value={currentPassword}
                        onChange={handleCurrentPassword}
                        required
                      />{" "}
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={toggleCurrentPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isCurrentPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    <label htmlFor="password">New Password:</label>
                    <div className="input-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="form-control"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    <label htmlFor="confirmPassword">
                      Confirm New Password:
                    </label>
                    <div className="input-group">
                      <input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        className="form-control"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {errors.confirmPassword && (
                      <span className="text-danger">
                        {" "}
                        {errors.confirmPassword}
                      </span>
                    )}
                    {errors.password && (
                      <span className="text-danger"> {errors.password}</span>
                    )}
                    <div className="text-muted" style={{ fontSize: "12px" }}>
                      (Minimum 8 characters with one uppercase, one number, and
                      one special character. Example: P@ss1234)
                    </div>
                    <button
                      disabled={isSignUpButtonDisabled}
                      className={`btn  my-2  py-1  px-4 btn_up update-profile-button btn-primary${
                        !isSignUpButtonDisabled
                          ? "disabled text-white bg-primary"
                          : ""
                      }`}
                      type="submit"
                    >
                      Update Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
