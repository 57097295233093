import baseApi from "../../api/baseApi";

const adminApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUser: builder.query({
      query: () => "/users/get-all-user",
      providesTags: ["user"],
    }),
    getSingleUser: builder.query({
      query: (_id) => `/users/get-single-user/${_id}`,
      providesTags: ["user"],
    }),
    getUserPayments: builder.query({
      query: (_id) => `/payment/get-single-user-payment-info/${_id}`,
      providesTags: ["user"],
    }),
    userAddedCompanyCheckAdmin: builder.query({
      query: (_id) => `/company/user-added-company-check-admin/${_id}`,
      providesTags: ["user"],
    }),
    getAllAdmin: builder.query({
      query: () => "/users/get-all-admin",
      providesTags: ["user"],
    }),

    getUserAllAddedCompany: builder.mutation({
      query: (_id) => ({
        url: `/users/get-users-all-added-company/${_id}`,
        method: "GET",
      }),
      providesTags: ["company"],
    }),
    makeUserAdmin: builder.mutation({
      query: (userId) => ({
        url: `/users/make-admin`,
        method: "POST",
        body: userId,
      }),
      invalidatesTags: ["user"],
    }),
    removeAdmin: builder.mutation({
      query: (userId) => ({
        url: `/users/remove-admin`,
        method: "POST",
        body: userId,
      }),
      invalidatesTags: ["user"],
    }),
    deleteUserFromDatabase: builder.mutation({
      query: (userId) => ({
        url: `/users/delete-user-with-company-info`,
        method: "POST",
        body: userId,
      }),
      invalidatesTags: ["user"],
    }),
    banUser: builder.mutation({
      query: (userId) => ({
        url: `/users/ban-user`,
        method: "POST",
        body: userId,
      }),
      invalidatesTags: ["user"],
    }),
    unBanUser: builder.mutation({
      query: (userId) => ({
        url: `/users/unBan-user`,
        method: "POST",
        body: userId,
      }),
      invalidatesTags: ["user"],
    }),
    getAllAdminNotifications: builder.query({
      query: () => "/admin/get-admin-notifications",
      providesTags: ["notifications"],
    }),
    readAdminNotifications: builder.mutation({
      query: () => ({
        url: `/admin/read-admin-notifications`,
        method: "POST",
      }),
      invalidatesTags: ["notifications"],
    }),
    unreadAdminNotifications: builder.query({
      query: () => "/admin/get-unread-admin-notifications",
      providesTags: ["notifications"],
    }),
    selectedPlan: builder.query({
      query: (planId) => `/servicePlans/get-single-plan/${planId}`,
      providesTags: ["user"],
    }),
    getEmiPayments: builder.query({
      query: () => ({
        url: "/payment/get-all-emi-payments",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    getEMIDetailsByTransactionId: builder.query({
      query: (transaction_id) => ({
        url: `/payment/emi-payment-details/${transaction_id}`,
        method: "GET",
      }),
      providesTags: ["user"],
    }),
  }),
});

export const {
  useGetAllUserQuery,
  useGetAllAdminQuery,
  useGetUserAllAddedCompanyMutation,
  useMakeUserAdminMutation,
  useRemoveAdminMutation,
  useDeleteUserFromDatabaseMutation,
  useBanUserMutation,
  useUnBanUserMutation,
  useGetSingleUserQuery,
  useUserAddedCompanyCheckAdminQuery,
  useGetUserPaymentsQuery,
  useGetAllAdminNotificationsQuery,
  useReadAdminNotificationsMutation,
  useUnreadAdminNotificationsQuery,
  useSelectedPlanQuery,
  useGetEmiPaymentsQuery,
  useGetEMIDetailsByTransactionIdQuery,
} = adminApi;
