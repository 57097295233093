// store.js
import { configureStore } from "@reduxjs/toolkit";
import { baseApi } from "./api/baseApi";
import authReducer from "./features/auth/authSlice";
import fileReducer from "./features/files/fileSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import socketMiddleware from "./middlewares/socketMiddleware";
import socketSlice from "./features/socket/socketSlice";
import companySlice from "./features/company/companySlice";
import nonPersistCompany from "./features/company/nonPersistCompany";

const persistConfig = {
  key: "auth",
  storage,
};
const companyPersistConfig = {
  key: "company",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedCompanyReducer = persistReducer(
  companyPersistConfig,
  companySlice
);

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: persistedAuthReducer,
    files: fileReducer,
    socket: socketSlice,
    company: persistedCompanyReducer,
    nonPersistCompany: nonPersistCompany,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware, socketMiddleware), // Add socketMiddleware here
});

export const persistor = persistStore(store);
