import { useEffect, useState } from "react";
import Select from "react-select";
import EditSingleDirector from "./EditSingleDirector";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import { setNewDirectors } from "../../../../redux/features/company/nonPersistCompany";

export default function CompanyDirectorEdit({
  company_information,
  setActiveEdit,
}) {
  const directorSlice = useSelector(
    (state) => state.nonPersistCompany.allDirectors
  );
  const dispatch = useDispatch();
  const companyData = company_information;
  const { _id } = companyData || {};

  const [form, setForm] = useState({
    fullName: "",
    email: "",
    phone: "",
    countryCode: null,
    role: "",
    country: "",
    emailError: "",
    phoneError: "",
    roleError: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+880", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" },
  ];

  useEffect(() => {
    checkFormValidity();
  }, [form]);

  const handleInputChange = (name, value) => {
    const newForm = {
      ...form,
      [name]: value,
      emailError:
        name === "email"
          ? validateEmail(value) || checkDuplicateEmail(value)
          : form.emailError,
    };
    setForm(newForm);
  };

  const handlePhoneChange = (selectedOption) => {
    handleInputChange("countryCode", selectedOption);
  };

  const handleSubmit = async () => {
    if (!isFormValid) {
      return;
    }

    const cleanData = {
      company_id: _id,
      full_name: form.fullName,
      directors_email: form.email,
      directors_country_code: form.countryCode ? form.countryCode.value : "",
      directors_phone_number: form.phone,
      role_of_directors: form.role,
      directors_country_name: form.country,
    };

    dispatch(setNewDirectors(cleanData));
    resetForm();
  };

  const checkDuplicateEmail = (email) => {
    if (directorSlice.some((director) => director.directors_email === email)) {
      return "This email is already associated with another director";
    }
    return "";
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email address is required";
    } else if (!emailPattern.test(email)) {
      return "Invalid email address";
    }
    return "";
  };

  const checkFormValidity = () => {
    const isValid =
      form.fullName.trim() &&
      form.country.trim() &&
      form.email.trim() &&
      !form.emailError &&
      form.phone.trim() &&
      form.role.trim();
    setIsFormValid(isValid);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible((prevVisible) => !prevVisible);
  };

  const resetForm = () => {
    setForm({
      fullName: "",
      email: "",
      phone: "",
      countryCode: null,
      role: "",
      country: "",
      emailError: "",
      phoneError: "",
      roleError: "",
    });
    setIsFormVisible(false);
    setIsFormValid(false);
  };

  const handleGoShareholder = () => {
    setActiveEdit("shareholder");
  };

  return (
    <div className="card">
      <div>
        <div className="p-md-3">
          <EditSingleDirector directors={directorSlice} company_id={_id} />
        </div>
        {isFormVisible && (
          <div className="d-flex justify-content-center align-items-center h-100 step_first_top">
            <div className="p-3 mb-5 w-100 m-3 singleDirectorCard">
              <form>
                <div className="mb-3">
                  <label htmlFor="fullName">Full name as per IC/Passport</label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Full name as per IC"
                    className="form-control rounded-0"
                    value={form.fullName}
                    onChange={(e) =>
                      handleInputChange("fullName", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email">Email address</label>
                  <br />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email address"
                    className="form-control rounded-0"
                    value={form.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  {form.emailError && (
                    <div className="text-danger">{form.emailError}</div>
                  )}
                  <small>
                    Please ensure this is the actual email of the individual!{" "}
                    <br />
                    This will be the email address used for SSM & Company
                    matters
                  </small>
                </div>

                <div className="mb-3">
                  <label htmlFor="phone">
                    <strong>Phone</strong>
                  </label>
                  <div className="d-flex align-items-center gap-1">
                    <div className="phone-box-selector phone_box rounded-2 gap-1">
                      <Select
                        value={form.countryCode}
                        onChange={handlePhoneChange}
                        options={countryCodes}
                        className="mr-2"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: "130px",
                          }),
                        }}
                      />
                    </div>
                    <input
                      type="number"
                      placeholder={
                        form.countryCode
                          ? `${form.countryCode.placeholder}`
                          : "Enter Your Phone Number"
                      }
                      value={form.phone}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                      className="form-control rounded-0"
                    />
                  </div>
                  <small></small>
                </div>
                <small>
                  Please ensure this is the actual phone number of the
                  individual!
                </small>

                <div className="mb-3">
                  <label htmlFor="role">Role of Director</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      name="role"
                      placeholder="Role of Director"
                      className="form-control rounded-0"
                      value={form.role}
                      onChange={(e) =>
                        handleInputChange("role", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="country">
                    Which country does this Director live?
                  </label>{" "}
                  <br />
                  <select
                    name="country"
                    id="country"
                    className="countryselect py-2"
                    value={form.country}
                    onChange={(e) =>
                      handleInputChange("country", e.target.value)
                    }
                  >
                    <option value="">Select Country</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="USA">USA</option>
                  </select>
                </div>
              </form>
              <button
                className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                <strong>Save</strong>
              </button>
            </div>
          </div>
        )}

        <div className="text-center">
          <Fab
            color="secondary"
            aria-label="add"
            onClick={toggleFormVisibility}
          >
            {!isFormVisible ? <AddIcon /> : <RemoveIcon />}
          </Fab>

          <h6 className="mt-2">Add More Director</h6>
        </div>
        <button
          onClick={handleGoShareholder}
          className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1 "
        >
          <strong>Give Share Between Directors</strong>
        </button>
      </div>
    </div>
  );
}
