import React, { useState } from "react";
import "./Admin/userDatails.css";
import circlePng from "../../assets/dashboards/circle.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logoSvg from "../../assets/logo.svg";
import {
  useGetSingleUserQuery,
  useGetUserPaymentsQuery,
  useUserAddedCompanyCheckAdminQuery,
} from "../../redux/features/admin/adminApi";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import { useGetSinglePaymentInfoQuery } from "../../redux/features/payment/paymentApi";
import { FaSign } from "react-icons/fa";
import { BiSolidBank } from "react-icons/bi";
export default function Expenses() {
  const user = useSelector((state) => state?.auth?.user);
  const { data: userPayments } = useGetUserPaymentsQuery(user._id);
  const payments = userPayments?.data || [];
  const { data: allCompanyList } = useUserAddedCompanyCheckAdminQuery(user._id);
  const companyList = allCompanyList?.data || [];
  const { data: userDetails } = useGetSingleUserQuery(user._id);

  const { firstName, lastName, email, role, phone, avatar, createdAt } =
    userDetails?.data || {};
  const totalAmount =
    payments?.length > 0
      ? payments.reduce(
          (acc, payment) => acc + parseInt(payment.total_amount, 10),
          0
        )
      : 0;
  const { data: activeCompanyData } = useUserActiveCompanyQuery();
  const activeCompany = activeCompanyData?.data;
  const { payment_information: { isPaid, current_payment_id } = {} } =
    activeCompany || {};
  const { data: paymentData, isLoading } =
    useGetSinglePaymentInfoQuery(current_payment_id);
  const userSinglePaymentInfo = paymentData?.data || {};

  const {
    currency,

    paidStatus,
    plan_name,
    service_plan,
    total_amount,
    transaction_id,

    validEndOn,
  } = userSinglePaymentInfo || {};
  const formatPaymentId = (id) => {
    if (!id) return "";
    const parts = id.match(/.{1,6}/g);
    return parts ? parts.join(" ") : id;
  };

  // State for pagination
  const [currentPageCompanies, setCurrentPageCompanies] = useState(1);
  const [companiesPerPage] = useState(5);
  const [currentPagePayments, setCurrentPagePayments] = useState(1);
  const [paymentsPerPage] = useState(5);

  // Get current companies and payments
  const indexOfLastCompany = currentPageCompanies * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = companyList.slice(
    indexOfFirstCompany,
    indexOfLastCompany
  );

  const indexOfLastPayment = currentPagePayments * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = payments.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  // Change page
  const paginateCompanies = (pageNumber) => setCurrentPageCompanies(pageNumber);
  const paginatePayments = (pageNumber) => setCurrentPagePayments(pageNumber);

  // Next and Previous handlers
  const nextPageCompanies = () => {
    if (
      currentPageCompanies < Math.ceil(companyList.length / companiesPerPage)
    ) {
      setCurrentPageCompanies(currentPageCompanies + 1);
    }
  };

  const prevPageCompanies = () => {
    if (currentPageCompanies > 1) {
      setCurrentPageCompanies(currentPageCompanies - 1);
    }
  };

  const nextPagePayments = () => {
    if (currentPagePayments < Math.ceil(payments.length / paymentsPerPage)) {
      setCurrentPagePayments(currentPagePayments + 1);
    }
  };

  const prevPagePayments = () => {
    if (currentPagePayments > 1) {
      setCurrentPagePayments(currentPagePayments - 1);
    }
  };

  return (
    <expenseComponent>
      <staticsUserDetails>
        <div className="d-md-flex m-3 gap-3  justify-content-center align-items-center">
          <div className=" w-100  card bg-gradient-danger my-md-0 my-2   card-img-holder text-white">
            <div className="card-body">
              <img src={circlePng} className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3">
                Total Added Company
                <i className="mdi mdi-chart-line mdi-24px float-end" />
              </h4>
              <h2 className="mb-3">{companyList?.length}</h2>
            </div>
          </div>
          <div className=" w-100 card bg-gradient-info my-md-0 my-2 card-img-holder text-white">
            <div className="card-body">
              <img src={circlePng} className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3">
                Payments Completed
                <i className="mdi mdi-chart-line mdi-24px float-end" />
              </h4>
              <h2 className="mb-3">{payments?.length}</h2>
            </div>
          </div>
          <div className=" w-100 card bg-gradient-success my-md-0 my-2  card-img-holder text-white">
            <div className="card-body">
              <img src={circlePng} className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3">
                Total Paid
                <i className="mdi mdi-chart-line mdi-24px float-end" />
              </h4>
              <h2 className="mb-3">{totalAmount}</h2>
            </div>
          </div>
        </div>
      </staticsUserDetails>
      <div className="user-details m-3 ">
        <div className=" d-md-flex gap-2 justify-content-center">
          <div className="card w-md-50 w-100 p-2 overflow-hidden mb-md-0 mb-3">
            <h6 className="m-1 mb-4">Active Package</h6>
            <div className="center d-flex justify-content-center align-items-center ">
              <div className="background">
                <div className="left"></div>
                <div className="right">
                  <div className="strip-bottom"></div>
                  <div className="strip-top"></div>
                </div>
                <div className="center d-flex justify-content-center align-items-center ">
                  <div className="cardDesign">
                    <div className="flip">
                      <div className="front">
                        <div className="strip-bottom"></div>
                        <div className="strip-top"></div>
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                          }}
                          className="logo"
                          src={logoSvg}
                          alt=""
                        />

                        <div className="investor">
                          {activeCompany?.company_name}
                        </div>
                        <div className="chip">
                          <div className="chip-line"></div>
                          <div className="chip-line"></div>
                          <div className="chip-line"></div>
                          <div className="chip-line"></div>
                          <div className="chip-main"></div>
                        </div>

                        <svg
                          className="wave"
                          viewBox="0 3.71 26.959 38.787"
                          width="26.959"
                          height="38.787"
                          fill="white"
                        >
                          <path d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z"></path>
                          <path d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z"></path>
                          <path d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z"></path>
                        </svg>
                        <div className="card-number">
                          <div className="section">
                            {formatPaymentId(transaction_id)}
                          </div>
                        </div>
                        {/* <div className="end">
                          <span className="end-text">Plan: </span>
                          <span className="end-date">{plan_name}</span>
                        </div> */}
                        <div className="d-flex justify-content-start align-items-center gap-2">
                          <div className="end">
                            <span className="end-text">Plan: </span>
                            <span className="end-date">{plan_name}</span>
                          </div>
                          <div className="end">
                            <span className="end-date">{totalAmount} </span>
                            <span className="end-date">{currency}</span>
                          </div>
                        </div>
                        <div className="end">
                          <span className="end-text">exp. end: </span>
                          <span className="end-date">
                            {new Date(validEndOn).toLocaleDateString()}
                          </span>
                        </div>
                        <div className="card-holder">
                          {user?.firstName} {user?.lastName}
                        </div>
                        <div className="master">
                          <div className="circle master-red"></div>
                          <div className="circle master-yellow"></div>
                        </div>
                      </div>
                      <div className="back">
                        <div className="strip-black"></div>
                        <div className="ccv">
                          <label>ccv</label>
                          <div>
                            {" "}
                            <div className="section">
                              {formatPaymentId(transaction_id)}
                            </div>
                          </div>
                        </div>
                        <div className="terms">
                          <p>Your current package info will see in this card</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center my-2 gap-2 compnay_info">
              <BiSolidBank className="companyIcon" />
              <Link to="/dashboards/company/profile">
                <button className="btn btn-primary">Company Information</button>
              </Link>{" "}
            </div>
          </div>

          <div className="card w-md-50 w-100 p-2">
            <h6 className="m-1 mb-3">Payments List</h6>
            <customTable>
              <div className="table">
                <div className="row header text-white">
                  <div className="cell">Transaction Id</div>
                  <div className="cell">Amount</div>
                  <div className="cell">Paid At</div>
                </div>
                {currentPayments?.length === 0 ? (
                  <div className=" text-center w-100 ">No payments yet</div>
                ) : (
                  currentPayments.map((payment, i) => (
                    <div key={i} className="row">
                      <div className="cell" data-title="Transaction Id">
                        {payment.transaction_id}
                      </div>
                      <div className="cell" data-title="Amount">
                        ${payment.total_amount}
                      </div>
                      <div className="cell" data-title="Paid At">
                        {new Date(payment.updatedAt).toLocaleDateString()}
                      </div>
                    </div>
                  ))
                )}
              </div>
              {currentPayments?.length === 0 ? null : (
                <ul className="pagination">
                  <li className="page-item">
                    <button onClick={prevPagePayments} className="page-link">
                      Previous
                    </button>
                  </li>
                  {[
                    ...Array(
                      Math.ceil(payments.length / paymentsPerPage)
                    ).keys(),
                  ].map((number) => (
                    <li
                      key={number + 1}
                      className={`page-item ${
                        currentPagePayments === number + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        onClick={() => paginatePayments(number + 1)}
                        className="page-link"
                      >
                        {number + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button onClick={nextPagePayments} className="page-link">
                      Next
                    </button>
                  </li>
                </ul>
              )}
            </customTable>
          </div>
        </div>
      </div>
    </expenseComponent>
  );
}
