import { Outlet, useLocation } from "react-router-dom";
import logoSvg from "../../src/assets/logo.svg";
const Root = () => {
  // console.groupCollapsed("all")
  const location = useLocation();

  const isPrivacyPolicy = location.pathname === "/termsAndConditions";
  return (
    <div>
      {!isPrivacyPolicy && (
        <img
          className="w-25  h-25 z-3 position-fixed logoSvg"
          src={logoSvg}
          alt=""
        />
      )}

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Root;
