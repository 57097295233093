// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// import { getTokenFromCookies } from "../../hooks/getTokenFromCookies";
// import { tagTypesArray } from "../tagTypes";

// export const baseApi = createApi({
//   reducerPath: "baseApi",
//   baseQuery: fetchBaseQuery({
//     baseUrl: process.env.REACT_APP_AXIOS_BASE_URL,
//     prepareHeaders: (headers) => {
//       const token = getTokenFromCookies();
//       if (token) {
//         headers.set("authorization", `Bearer ${token}`);
//       }
//       return headers;
//     },
//   }),
//   endpoints: () => ({}),

//   tagTypes: ["invalidCache", "user"],
// });

// export default baseApi;

// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const REACT_APP_AXIOS_BASE_URL = process.env.REACT_APP_AXIOS_BASE_URL;

// export const baseApi = createApi({
//   reducerPath: "baseApi",
//   baseQuery: fetchBaseQuery({
//     baseUrl: REACT_APP_AXIOS_BASE_URL,
//     credentials: "include",
//   }),
//   endpoints: () => ({}),
//   tagTypes: ["invalidCache", "user"],
// });

// export default baseApi;

// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const REACT_APP_AXIOS_BASE_URL = process.env.REACT_APP_AXIOS_BASE_URL;

// const baseQueryWithAuth = async (args, api, extraOptions, token) => {
//   // const token = getTokenFromCookies();

//   if (token) {
//     args.headers = {
//       ...args.headers,
//       Authorization: `Bearer ${token}`,
//     };
//   }

//   const rawBaseQuery = fetchBaseQuery({
//     baseUrl: REACT_APP_AXIOS_BASE_URL,
//     credentials: "include",
//   });

//   return rawBaseQuery(args, api, extraOptions);
// };

// export const baseApi = createApi({
//   reducerPath: "baseApi",
//   baseQuery: baseQueryWithAuth,
//   endpoints: (builder) => ({}),
//   tagTypes: ["invalidCache", "user", "company"],
// });

// export default baseApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AXIOS_BASE_URL,
  credentials: "include",
  prepareHeaders: async (headers, { getState }) => {
    const token = await getState().auth.token;
    if (token) {
      // console.log("token exists", token);
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    console.log("sending refresh token");

    // Send refresh token to get new access token
    const refreshResult = await baseQuery(
      "/users/refreshToken",
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      // Retry original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = "Your login has expired.";
      }
      return refreshResult;
    }
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["user", "company", "invalidCache", "files", "bank", "payment"],
  endpoints: (builder) => ({}),
});
export default baseApi;
