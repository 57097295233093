import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  AllFiles: null,
};

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setFiles: (state, action) => {
      const { files } = action.payload;
      state.AllFiles = files;
    },
  },
});

export const { setFiles } = fileSlice.actions;

export default fileSlice.reducer;
