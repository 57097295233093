import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { Box } from "@mui/material";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { useCurrentUser } from "../../../../redux/features/auth/authSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function AdminNotificationsList({ notifications }) {
  const userSelector = useSelector((state) => state.auth);
  const user = userSelector.user;
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const isToday = (date) =>
    new Date(date).toDateString() === today.toDateString();
  const isYesterday = (date) =>
    new Date(date).toDateString() === yesterday.toDateString();

  const [todayNotifications, setTodayNotifications] = useState([]);
  const [yesterdayNotifications, setYesterdayNotifications] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]);

  useEffect(() => {
    // Sort notifications by updatedAt date and time in descending order
    const sortedNotifications = [...notifications].sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    const todayNotifs = [];
    const yesterdayNotifs = [];
    const previousNotifs = [];

    sortedNotifications.forEach((notification) => {
      const date = new Date(notification.createdAt);
      if (isToday(date)) {
        todayNotifs.push(notification);
      } else if (isYesterday(date)) {
        yesterdayNotifs.push(notification);
      } else {
        previousNotifs.push(notification);
      }
    });

    setTodayNotifications(todayNotifs);
    setYesterdayNotifications(yesterdayNotifs);
    setPreviousNotifications(previousNotifs);
  }, [notifications]);

  return (
    <Box sx={{ maxHeight: "80vh", maxWidth: "45vh" }}>
      <Paper square sx={{ height: "100%" }}>
        <Typography
          variant="h6"
          sx={{
            p: 2,
            fontSize: {
              md: 15,
              sm: 12,
            },
            borderBottom: 1,
            borderColor: "divider",
            inlineHeight: 0,
          }}
        >
          Notifications
        </Typography>
        <List>
          {todayNotifications.length > 0 && (
            <>
              <ListSubheader
                sx={{ bgcolor: "background.paper", lineHeight: 2, px: 1 }}
              >
                Today
              </ListSubheader>
              {todayNotifications.map(
                ({ _id, message, type, createdAt, read, userId }) => (
                  <div className="w-100 notification-hover" key={_id}>
                    <div className="d-flex  align-items-center px-2 py-1">
                      <IoNotificationsCircleSharp className="bell-icon" />
                      <div>
                        {type === "payment" ? (
                          <Link
                            className="text-decoration-none"
                            to="/admin/dashboards/paymentRecords"
                          >
                            <div className="notification-heading">
                              {message}
                            </div>
                          </Link>
                        ) : (
                          <Link
                            className="text-decoration-none"
                            to={`/admin/dashboards/userDetails/${userId}`}
                          >
                            <div className="notification-heading">
                              {message}
                            </div>
                          </Link>
                        )}

                        <div className="local-date-notification">
                          at: {new Date(createdAt).toLocaleDateString()},{" "}
                          {new Date(createdAt).toLocaleTimeString()}
                        </div>
                      </div>

                      {read.find((item) => item === user._id) === undefined && (
                        <div className="notification-unread">new</div>
                      )}
                    </div>
                  </div>
                )
              )}
            </>
          )}

          {yesterdayNotifications.length > 0 && (
            <>
              <ListSubheader
                sx={{ bgcolor: "background.paper", lineHeight: 2, px: 1 }}
              >
                Yesterday
              </ListSubheader>
              {yesterdayNotifications.map(
                ({ _id, message, type, createdAt, read, userId }) => (
                  <div className="w-100 notification-hover" key={_id}>
                    <div className="d-flex  align-items-center px-2 py-1">
                      <IoNotificationsCircleSharp className="bell-icon" />
                      <div>
                        {type === "payment" ? (
                          <Link
                            className="text-decoration-none"
                            to="/admin/dashboards/paymentRecords"
                          >
                            <div className="notification-heading">
                              {message}
                            </div>
                          </Link>
                        ) : (
                          <Link
                            className="text-decoration-none"
                            to={`/admin/dashboards/userDetails/${userId}`}
                          >
                            <div className="notification-heading">
                              {message}
                            </div>
                          </Link>
                        )}
                        <div className="local-date-notification">
                          at: {new Date(createdAt).toLocaleDateString()},{" "}
                          {new Date(createdAt).toLocaleTimeString()}
                        </div>
                      </div>
                      {read.find((item) => item === user._id) === undefined && (
                        <div className="notification-unread">new</div>
                      )}
                    </div>
                  </div>
                )
              )}
            </>
          )}

          {previousNotifications.length > 0 && (
            <>
              <ListSubheader
                sx={{ bgcolor: "background.paper", lineHeight: 2, px: 1 }}
              >
                Previous
              </ListSubheader>
              {previousNotifications.map(
                ({ _id, message, type, createdAt, read, userId }) => (
                  <div className="w-100 notification-hover" key={_id}>
                    <div className="d-flex  align-items-center px-2 py-1">
                      <IoNotificationsCircleSharp className="bell-icon" />
                      <div>
                        {type === "payment" ? (
                          <Link
                            className="text-decoration-none"
                            to="/admin/dashboards/paymentRecords"
                          >
                            <div className="notification-heading">
                              {message}
                            </div>
                          </Link>
                        ) : (
                          <Link
                            className="text-decoration-none"
                            to={`/admin/dashboards/userDetails/${userId}`}
                          >
                            <div className="notification-heading">
                              {message}
                            </div>
                          </Link>
                        )}
                        <div className="local-date-notification">
                          at: {new Date(createdAt).toLocaleDateString()},{" "}
                          {new Date(createdAt).toLocaleTimeString()}
                        </div>
                      </div>
                      {read.find((item) => item === user._id) === undefined && (
                        <div className="notification-unread">new</div>
                      )}
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </List>
      </Paper>
    </Box>
  );
}
