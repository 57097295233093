import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import "../login/loginsignup.css";
import { useIntroContext } from "../../hooks/useIntroContext";
import whoWillbeDirectorsSvg from "../../assets/introflow/svg/7.svg";
import vertical4 from "../../assets/introflow/Number Vertical/4.png";
import { useSelector } from "react-redux";
import { FaTrash } from "react-icons/fa";

function Whowillbedirectors() {
  const user = useSelector((state) => state?.auth?.user);
  const navigate = useNavigate();
  const location = useLocation();
  const fromReview = location.state?.fromReview || false;
  const { updateCompanyData, companyData, defaultDirector } = useIntroContext();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [newDirector, setNewDirector] = useState({
    full_name: "",
    directors_email: "",
    directors_phone_number: "",
    directors_country_code: null,
    role_of_directors: "",
    directors_country_name: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [directorsList, setDirectorsList] = useState([]);
  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+880", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" },
  ];

  useEffect(() => {
    if (companyData && companyData.directors) {
      setDirectorsList(companyData.directors);
    }
  }, [companyData]);

  const handleInputChange = (name, value) => {
    if (name === "full_name" && value.length > 50) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        full_nameError: "Full name cannot exceed 50 characters",
      }));
    } else if (name === "role_of_directors" && value.length > 40) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        roleError: "Role of Director cannot exceed 40 characters",
      }));
    } else {
      setNewDirector((prevDirector) => ({
        ...prevDirector,
        [name]: value,
      }));

      if (name === "full_name") {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          full_nameError: "",
        }));
      }

      if (name === "role_of_directors") {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          roleError: "",
        }));
      }

      if (name === "directors_email") {
        const emailExists = directorsList.some(
          (director) => director.directors_email === value
        );
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          emailError:
            validateEmail(value) ||
            (emailExists ? "Every director must have a unique email" : ""),
        }));
      }

      if (name === "directors_phone_number") {
        const onlyNumbers = /^[0-9]*$/;
        if (!name) {
          setFormErrors((prev) => ({
            ...prev,
            [name]: false,
          }));
          return;
        }
        if (value.length > 10) {
          setNewDirector((prevDirector) => ({
            ...prevDirector,
            directors_phone_number: value.slice(0, 10),
          }));
        } else if (value.length < 10 && value.length > 0) {
          setNewDirector((prevDirector) => ({
            ...prevDirector,
            directors_phone_number: value.slice(0, 10),
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            phoneError: "Phone number must be at least 10 digits",
          }));
        } else if (!onlyNumbers.test(value)) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            phoneError: "Phone number can only contain digits",
          }));
        } else {
          setFormErrors((prevErrors) => ({ ...prevErrors, phoneError: "" }));
        }
      }
    }
  };

  const handlePhoneChange = (selectedOption) => {
    const countryCodeValue = selectedOption ? selectedOption.value : null;
    setNewDirector((prevDirector) => ({
      ...prevDirector,
      directors_country_code: countryCodeValue,
    }));
  };

  const validateEmail = (directors_email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!directors_email) {
      return "Email address is required";
    } else if (!emailPattern.test(directors_email)) {
      return "Invalid email address";
    }
    return "";
  };

  const validateForm = (form) => {
    if (!form.full_name.trim()) {
      return "Full name is required";
    } else if (formErrors.full_nameError) {
      return formErrors.full_nameError;
    } else if (!form.directors_email.trim()) {
      return "Email address is required";
    } else if (formErrors.emailError) {
      return formErrors.emailError;
    } else if (!form.directors_phone_number.trim()) {
      return "Phone number is required";
    } else if (!form.role_of_directors.trim()) {
      return "Role of Director is required";
    } else if (formErrors.roleError) {
      return formErrors.roleError;
    } else if (!form.directors_country_name.trim()) {
      return "Country is required";
    }
    return null;
  };

  const handleAddDirector = () => {
    const error = validateForm(newDirector);
    if (error) {
      setFormErrors({ formError: error });
    } else {
      const updatedDirectors = [...directorsList, newDirector];
      updateCompanyData({
        directors: updatedDirectors,
      });
      setDirectorsList(updatedDirectors);
      setNewDirector({
        full_name: "",
        directors_email: "",
        directors_phone_number: "",
        directors_country_code: null,
        role_of_directors: "",
        directors_country_name: "",
      });
      setIsFormVisible(false);
      setFormErrors({});
    }
  };

  const handleDeleteDirector = (directorEmail) => {
    const updatedDirectors = directorsList.filter(
      (director) => director.directors_email !== directorEmail
    );
    updateCompanyData({
      directors: updatedDirectors,
    });
    setDirectorsList(updatedDirectors);
  };

  const handleSubmit = async () => {
    const errors = directorsList.map(validateForm).filter(Boolean);

    if (errors.length === 0) {
      if (companyData.directors.length > 1) {
        if (fromReview === true) {
          navigate("/signup/shareholders", { state: { fromReview: true } });
        } else {
          navigate("/signup/shareholders");
        }
      } else {
        const singleShareholder = companyData.directors.map(
          (shareholder, index) => {
            if (index === 0) {
              return {
                ...shareholder,
                shareholder_type: "individual",
                shareholder_name: shareholder?.full_name,
                shareholder_email: shareholder?.directors_email,
                shareholder_country_code: shareholder?.directors_country_code,
                shareholder_phone_number: shareholder?.directors_phone_number,
                share: 1000,
              };
            }
            return shareholder;
          }
        );

        updateCompanyData({
          shareholders: singleShareholder,
        });
        navigate("/signup/reviewSubmission");
      }
    } else {
      setFormErrors({ formErrors: errors });
    }
  };

  const addNewForm = () => {
    setIsFormVisible(true);
  };

  const deleteForm = () => {
    setIsFormVisible(false);
    setNewDirector({
      full_name: "",
      directors_email: "",
      directors_phone_number: "",
      directors_country_code: null,
      role_of_directors: "",
      directors_country_name: "",
    });
  };
  return (
    <div className="intro-flow-bg">
      <div className="row">
        <div className="col-md-5">
          <div className="d-flex bg-left justify-content-center align-items-center sticky-top">
            <div className="custom-intro-hight">
              <img
                className="position-absolute verticalNumber"
                src={vertical4}
                alt=""
              />
              <img
                src={whoWillbeDirectorsSvg}
                width={400}
                className="mt-5 custom-intro-hight intro-flow-img-custom-size"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top">
            <div className="px-3 pb-3 mb-3 w-100 intro-margin-custom m-5">
              <h3>Who will be the directors?</h3>
              <p className="step4">
                These individuals will be responsible for the management of the
                company
              </p>
              <p>
                <strong>Requirements</strong>
                <ul>
                  <li>A US Company requires at least 1 director</li>
                  <li>A director must be above 18 years old</li>
                  <li>Must have a valid passport</li>
                  <li>
                    Should not be declared bankrupt or face criminal charges
                  </li>
                </ul>
              </p>
              <div>
                <h4 className="text-center">All Directors</h4>
              </div>
              {directorsList.map((director, index) => (
                <div key={index} className="w-100 card mb-2 mt-1 mb-2">
                  <ul className="list-group list-group-flush ">
                    <li className="list-group-item d-flex justify-content-between align-items-center px-4  bg-secondary bg-opacity-25 position-relative">
                      <div className="custom-font-weight-director ">
                        No: 0{index + 1}
                      </div>{" "}
                      {director.directors_email !==
                      defaultDirector.directors_email ? (
                        <FaTrash
                          className="delete-director "
                          type="button"
                          onClick={() =>
                            handleDeleteDirector(director.directors_email)
                          }
                        ></FaTrash>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-5">
                      <div className="custom-font-weight-director">Name: </div>{" "}
                      <div
                        className="overflow-direct-wrapper"
                        style={{ textAlign: "end" }}
                      >
                        {director.full_name}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-5">
                      <div className="custom-font-weight-director">Email:</div>{" "}
                      <div>{director.directors_email}</div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-5">
                      <div className="custom-font-weight-director">Phone:</div>{" "}
                      <div>
                        {director.directors_country_code}
                        {director.directors_phone_number}
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-5">
                      <div className="custom-font-weight-director">Role:</div>{" "}
                      <div className="overflow-direct-wrapper">
                        {director.role_of_directors}
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
              {!isFormVisible && (
                <div className="text-xs mb-2 text-center">
                  You can add more directors
                </div>
              )}
              {isFormVisible && (
                <div className="mt-4">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="full_name">
                        Full Name as per IC/Passport
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        placeholder="Full name as per IC"
                        className="form-control rounded-0"
                        value={newDirector.full_name}
                        onChange={(e) =>
                          handleInputChange("full_name", e.target.value)
                        }
                      />
                      {formErrors.full_nameError && (
                        <div className="text-danger">
                          {formErrors.full_nameError}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email">Email address</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email address"
                        className="form-control rounded-0"
                        value={newDirector.directors_email}
                        onChange={(e) =>
                          handleInputChange("directors_email", e.target.value)
                        }
                      />
                      {formErrors.emailError && (
                        <div className="text-danger">
                          {formErrors.emailError}
                        </div>
                      )}
                      <small>
                        Please ensure this is the actual email of the
                        individual! This will be the email address used Company
                        matters
                      </small>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone">
                        <strong>Phone</strong>
                      </label>
                      <div className="d-flex align-items-center gap-1">
                        <div className="phone-box-selector phone_box rounded-2 gap-1">
                          <Select
                            value={countryCodes.find(
                              (option) =>
                                option.value ===
                                newDirector.directors_country_code
                            )}
                            onChange={handlePhoneChange}
                            options={countryCodes}
                            className="mr-2"
                            isSearchable={false} // Disable text input
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "130px",
                              }),
                            }}
                          />
                        </div>
                        <input
                          type="number"
                          placeholder="Enter Your Phone Number"
                          value={newDirector.directors_phone_number}
                          onChange={(e) =>
                            handleInputChange(
                              "directors_phone_number",
                              e.target.value
                            )
                          }
                          className="form-control rounded-0"
                        />
                      </div>
                      {formErrors.phoneError && (
                        <div className="text-danger">
                          {formErrors.phoneError}
                        </div>
                      )}
                      <small>
                        Please ensure this is the actual phone number of the
                        individual!
                      </small>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="role_of_directors">
                        Role of Director
                      </label>
                      <input
                        type="text"
                        name="role_of_directors"
                        placeholder="Role of Director"
                        className="form-control rounded-0 "
                        value={newDirector.role_of_directors}
                        onChange={(e) =>
                          handleInputChange("role_of_directors", e.target.value)
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="directors_country_name">
                        Which country does this Director live?
                      </label>
                      <select
                        name="directors_country_name"
                        className="form-control rounded-0"
                        value={newDirector.directors_country_name}
                        onChange={(e) =>
                          handleInputChange(
                            "directors_country_name",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Country</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="USA">USA</option>
                      </select>
                    </div>
                    {formErrors.formError && (
                      <div className="text-danger">{formErrors.formError}</div>
                    )}
                    <button
                      type="button"
                      disabled={
                        formErrors.full_nameError ||
                        formErrors.phoneError ||
                        formErrors.emailError ||
                        !newDirector.full_name.trim() ||
                        !newDirector.directors_email.trim() ||
                        !newDirector.directors_phone_number.trim() ||
                        !newDirector.role_of_directors.trim() ||
                        !newDirector.directors_country_name.trim()
                      }
                      className="btn btn-secondary rounded-0 text-decoration-none w-100"
                      onClick={handleAddDirector}
                    >
                      <strong>Save Director</strong>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger rounded-0 text-decoration-none w-100 mt-2"
                      onClick={deleteForm}
                    >
                      <strong>Delete Form</strong>
                    </button>
                  </form>
                </div>
              )}
              {!isFormVisible && (
                <button
                  className="btn btn-secondary rounded-0 text-decoration-none w-100"
                  onClick={addNewForm}
                >
                  <strong>Add Another Director</strong>
                </button>
              )}
              {fromReview ? (
                <>
                  {!isFormVisible && (
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary rounded-0 text-decoration-none w-100 mt-2"
                    >
                      <strong>Update</strong>
                    </button>
                  )}
                </>
              ) : (
                <>
                  {!isFormVisible && (
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary rounded-0 text-decoration-none w-100 mt-2"
                    >
                      <strong>Next Step →</strong>
                    </button>
                  )}
                </>
              )}

              {/* <button
                className="btn btn-primary rounded-0 text-decoration-none w-100 mt-2"
                onClick={handleSubmit}
              >
                <strong>Next step →</strong>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whowillbedirectors;
