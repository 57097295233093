import React from "react";
import "../login/loginsignup.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIntroContext } from "../../hooks/useIntroContext";
import sharePaidsSvg from "../../assets/introflow/svg/4.svg";
import vertical2 from "../../assets/introflow/Number Vertical/2.png";
function SharesAndPaids() {
  const location = useLocation();
  const fromReview = location.state?.fromReview || false;
  const navigate = useNavigate();
  const { updateCompanyData } = useIntroContext();
  function handleGetStarted() {
    const data = {
      number_of_share: 1000,
    };
    updateCompanyData(data);
    if (fromReview === true) {
      navigate("/signup/reviewSubmission");
    } else {
      navigate("/signup/businesscontactdetails");
    }
  }
  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  className="position-absolute verticalNumber"
                  src={vertical2}
                  alt=""
                />
                <img
                  src={sharePaidsSvg}
                  width={400}
                  className=" mt-5 custom-intro-hight intro-flow-img-custom-size"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h3>Shares & paid-up capital</h3>
                <p>
                  We've already set our recommended defaults for the paid-up
                  capital and number of shares. Some banks require a minimum
                  paid-up capital of $200, hence the recommended default (which
                  is easily divideable equally among most shareholders). You may
                  increase the paid-up capital and number of shares after
                  opening of a bank account
                </p>
                <form>
                  <div className="mb-3">
                    <label htmlFor="firstName">Number of shares</label>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Your preferred name"
                        className="form-control rounded-0 form-control bg-gray-100"
                        value="$1000"
                        disabled
                      />
                    </div>
                  </div>
                </form>
                <p>
                  If this default does not work for you, discuss it with your
                  account manager after submission of this form.
                </p>
                {fromReview ? (
                  <button
                    onClick={handleGetStarted}
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Update Info</strong>
                  </button>
                ) : (
                  <button
                    onClick={handleGetStarted}
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Understood, Next step →</strong>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SharesAndPaids;
