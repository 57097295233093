import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import { useGetAllUserQuery } from "../../../../redux/features/admin/adminApi";
import { useEffect, useState } from "react";
const aggregateMonthlyRegistrations = (registrations) => {
  const monthlyData = {};

  registrations?.forEach((registration) => {
    const date = new Date(registration.createdAt);
    const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

    if (!monthlyData[yearMonth]) {
      monthlyData[yearMonth] = 0;
    }
    monthlyData[yearMonth]++;
  });

  return monthlyData;
};

export default function UserChart() {
  const { data: userData } = useGetAllUserQuery();
  const allUsers = userData?.data;
  const monthlyRegistrations = aggregateMonthlyRegistrations(allUsers);
  const [registrationLabels, setRegistrationLabels] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);

  useEffect(() => {
    const labels = Object.keys(monthlyRegistrations).sort();
    const data = labels.map((label) => monthlyRegistrations[label]);

    setRegistrationLabels(labels);
    setRegistrationData(data);
  }, [allUsers]);

  return (
    <div className="card z-index-2">
      <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-2 pe-1">
          <div className="chart w-100 h-100">
            <LineChart
              className="w-100 h-100"
              width={550}
              height={300}
              series={[
                {
                  data: registrationData,
                  label: "New Registrations",
                  area: true,
                  showMark: false,
                },
              ]}
              xAxis={[{ scaleType: "point", data: registrationLabels }]}
              sx={{
                [`& .${lineElementClasses.root}`]: {
                  display: "none",
                },
              }}
            />
          </div>
          <div className="card-body"></div>
        </div>
      </div>
      <div
        style={{ height: "100%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <i className="material-icons text-sm my-auto me-1">Schedule</i>
        <p className="mb-0 text-sm">
          Last update: {new Date().toLocaleDateString()}
        </p>
      </div>
    </div>
  );
}
