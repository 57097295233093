import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import "../login/loginsignup.css";
import { useIntroContext } from "../../hooks/useIntroContext";
import businessContactDetailsSvg from "../../assets/introflow/svg/5.svg";
import vertical3 from "../../assets/introflow/Number Vertical/3.png";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

function BusinessContactDetails() {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  const { updateCompanyData, companyData } = useIntroContext();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressLineError, setAddressLineError] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessTypeError, setBusinessTypeError] = useState("");
  const [noAddress, setNoAddress] = useState(false);

  const location = useLocation();
  const fromReview = location.state?.fromReview || false;

  useEffect(() => {
    if (companyData) {
      setEmail(user.email);
      setPhone(user.phone);
      setCountryCode({
        value: user.countryCode || "", // Set default country code if empty
        label: user.countryCode || "select", // Set default country code if empty
      });
      setAddressLineOne(companyData.address_line_one || "");
      setNoAddress(companyData.address_line_one === "not have address"); // Adjust here
      setBusinessType(companyData.business_type || "");
    }
  }, [companyData]);

  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+880", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" },
    // Add more country options as needed
  ];

  const handleBusinessTypeChange = (e) => {
    setBusinessType(e.target.value);
    validateBusinessType(e.target.value);
  };

  const handlePhoneChange = (selectedOption) => {
    setCountryCode(selectedOption);
    setPhone("");
    setPhoneError("");
    validatePhone(""); // Reset phone error
  };

  const handleNoAddressCheck = (e) => {
    const checked = e.target.checked;
    setNoAddress(checked);
    setAddressLineOne(checked ? "not have address" : "");
    setAddressLineError("");
  };

  const handlePhoneInputChange = (e) => {
    const { value } = e.target;
    const onlyNumbers = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (onlyNumbers.length <= 10) {
      // Check if the length is 10 or less
      setPhone(onlyNumbers);
      validatePhone(onlyNumbers); // Validate phone number as user types
    }
  };

  const handleEmailChange = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const validateEmail = (emailValue) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(emailValue);

    if (!emailValue) {
      setEmailError("Email address is required");
    } else if (!isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = (phoneValue) => {
    if (!phoneValue) {
      setPhoneError("Phone number is required");
    } else if (phoneValue.length < 10) {
      setPhoneError("Phone number must be at least 10 digits");
    } else if (phoneValue.length > 10) {
      setPhoneError("Phone number must be less than 10 digits");
    } else {
      setPhoneError("");
    }
  };

  const validateAddressLine = () => {
    if (!addressLineOne && !noAddress) {
      setAddressLineError("Address lines are required");
    } else {
      setAddressLineError("");
    }
  };

  const validateBusinessType = (type) => {
    if (!type) {
      setBusinessTypeError("Business type is required");
    } else {
      setBusinessTypeError("");
    }
  };

  const handleAddressLineChange = (value, line) => {
    if (line === 1) {
      if (value.length > 100) {
        setAddressLineError("Max Address line length is 100 characters");
      } else {
        setAddressLineOne(value);
        setAddressLineError("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateEmail(email);
    validatePhone(phone); // Validate phone before submission
    validateAddressLine();
    validateBusinessType(businessType);

    if (email && phone && (addressLineOne || noAddress) && businessType) {
      const data = {
        company_email: email,
        company_country_code: countryCode.value,
        company_phone_number: phone,
        address_line_one: noAddress ? "not have address" : addressLineOne,
        business_type: businessType,
      };
      updateCompanyData(data);
      if (fromReview === true) {
        navigate("/signup/reviewSubmission");
      } else {
        navigate("/signup/whowillbethedirectors");
      }
    } else {
      console.log("Form submission failed. Please check for errors.");
    }
  };

  const isNextDisabled =
    emailError || phoneError || addressLineError || businessTypeError;

  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  className="position-absolute verticalNumber"
                  src={vertical3}
                  alt=""
                />
                <img
                  src={businessContactDetailsSvg}
                  width={400}
                  className=" mt-5 custom-intro-hight intro-flow-img-custom-size "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h3>Business contact details</h3>
                <p>
                  These contact details will be registered will be publicly
                  available as general contact information for your company.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email">Company email address</label>
                    <br />
                    <input
                      type="email"
                      placeholder="Your company email address"
                      value={email}
                      onChange={handleEmailChange}
                      className="form-control rounded-0"
                    />
                    {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone">
                      <strong>Phone</strong>
                    </label>
                    <div className="d-flex align-items-center gap-1  ">
                      <div className=" phone-box-selector phone_box rounded-2 gap-1">
                        <Select
                          value={countryCode}
                          onChange={handlePhoneChange}
                          options={countryCodes}
                          isSearchable={false} // Disable text input
                          className=" "
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "130px",
                            }),
                          }}
                        />
                      </div>
                      <input
                        type="tel"
                        placeholder="Enter Your Phone Number"
                        value={phone}
                        onChange={handlePhoneInputChange}
                        className="form-control rounded-2"
                      />
                    </div>
                    {phoneError && (
                      <div className="text-danger">{phoneError}</div>
                    )}
                  </div>
                  <h3>Business (office) address</h3>
                  <p className="mb-3">
                    <strong>No office yet?</strong>
                    <br />
                    No worries, you may enter your own residential address as a
                    temporary office address.
                  </p>
                  <div className="mb-3">
                    <label htmlFor="addressLine1">Business Address </label>
                    <input
                      type="text"
                      placeholder="32 Some Street"
                      className="form-control rounded-0"
                      value={noAddress ? "not have address" : addressLineOne}
                      onChange={(e) =>
                        handleAddressLineChange(e.target.value, 1)
                      }
                      disabled={noAddress}
                    />
                    <div className="mt-2">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={noAddress} />}
                          onChange={handleNoAddressCheck}
                          label="Check it if you don't have an address, We can help you to rent a business address"
                        />
                      </FormGroup>
                    </div>
                    {addressLineError && (
                      <div className="text-danger">{addressLineError}</div>
                    )}
                  </div>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{ fontWeight: "semibol", color: "black" }}
                    >
                      Select Business Type
                    </FormLabel>
                    <RadioGroup
                      defaultValue={
                        companyData.business_type
                          ? companyData.business_type
                          : ""
                      }
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="llc"
                        control={<Radio />}
                        label="LLC"
                        onChange={handleBusinessTypeChange}
                      />
                      <FormControlLabel
                        value="corporation"
                        control={<Radio />}
                        label="Corporation"
                        onChange={handleBusinessTypeChange}
                      />
                    </RadioGroup>
                  </FormControl>
                  {businessTypeError && (
                    <div className="text-danger">{businessTypeError}</div>
                  )}
                  <button
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                    type="submit"
                    disabled={isNextDisabled}
                  >
                    <strong>
                      {fromReview ? "Update Info" : "Next step →"}
                    </strong>
                  </button>
                  <button className="btn rounded-0 text-decoration-none getstarted1">
                    <Link to="#">
                      <strong>← Go back</strong>
                    </Link>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessContactDetails;
