// import React, { useState, useRef, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import resetPasswordSvg from "../../assets/introflow/svg/13.svg";
// import toast from "react-hot-toast";
// import {
//   useGetUserByEmailQuery,
//   useResendOtpMutation,
//   useVerificationEmailMutation,
// } from "../../redux/features/auth/authApi";
// import { useDispatch } from "react-redux";
// import { updateIsVerified } from "../../redux/features/auth/authSlice";

// function OTPVerification() {
//   const { email } = useParams();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [otp, setOtp] = useState(["", "", "", ""]);
//   const inputRefs = useRef([]);
//   const [successMessage, setSuccessMessage] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [resendOtp] = useResendOtpMutation();
//   const [verificationEmail] = useVerificationEmailMutation();
//   const { data: userData, refetch } = useGetUserByEmailQuery(email, {
//     refetchOnMountOrArgChange: false,
//     refetchOnReconnect: false,
//   });

//   const resendTime = userData?.data?.otpData.resendIn;
//   console.log(resendTime);

//   const [remainingTime, setRemainingTime] = useState(0);
//   const [isResendDisabled, setIsResendDisabled] = useState(true);

//   useEffect(() => {
//     if (resendTime) {
//       const resendDate = new Date(resendTime);
//       const now = new Date();
//       const diffInSeconds = Math.floor((resendDate - now) / 1000);
//       setRemainingTime(diffInSeconds);

//       if (diffInSeconds > 0) {
//         setIsResendDisabled(true);
//         const timer = setInterval(() => {
//           setRemainingTime((prevTime) => {
//             if (prevTime <= 1) {
//               clearInterval(timer);
//               setIsResendDisabled(false);
//               return 0;
//             }
//             return prevTime - 1;
//           });
//         }, 1000);
//         return () => clearInterval(timer);
//       } else {
//         setIsResendDisabled(false);
//       }
//     } else {
//       setIsResendDisabled(false);
//     }
//   }, [resendTime]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const verifyData = {
//       email,
//       otp: otp.join(""),
//     };
//     if (otp.join("").length < 4) {
//       toast.error("Please enter a valid OTP");
//       return;
//     }
//     try {
//       const { statusCode, data, message } = await verificationEmail(
//         verifyData
//       ).unwrap();
//       if (statusCode === 200) {
//         dispatch(updateIsVerified({ isVerified: data.isVerified }));
//         setSuccessMessage("OTP verified successfully");
//         toast.success(message, {
//           duration: 2500,
//           style: { background: "#050F26" },
//           className: "text-white",
//         });
//         navigate("/signup/intro");
//       } else {
//         toast.error("Failed to verify OTP");
//         setErrorMessage(message || "OTP verification failed");
//       }
//     } catch (error) {
//       console.error("Failed to verify OTP:", error);
//       toast.error("Failed to verify OTP");
//     }
//   };

//   const handleResendOtp = async () => {
//     setErrorMessage(""); // Clear the error message
//     setOtp(["", "", "", ""]); // Clear the OTP input fields
//     try {
//       const { statusCode, message } = await resendOtp({ email }).unwrap();
//       if (statusCode === 200) {
//         toast.success(message, {
//           duration: 2500,
//           style: { background: "#050F26" },
//           className: "text-white",
//         });
//         refetch(); // refetch user data to get the new resend time
//       } else {
//         toast.error("Failed to resend OTP");
//       }
//     } catch (error) {
//       console.error("Failed to resend OTP:", error);
//       toast.error("Failed to resend OTP");
//     }
//   };

//   const handleOtpChange = (event, index) => {
//     const newOtp = [...otp];
//     const { value } = event.target;

//     // Update OTP array
//     newOtp[index] = value;
//     setOtp(newOtp);
//     setErrorMessage("");
//     setSuccessMessage(""); // Clear success message if present

//     // Move focus to the next input if not empty
//     if (value !== "" && index < inputRefs.current.length - 1) {
//       inputRefs.current[index + 1].focus();
//     }
//   };

//   const handleKeyDown = (event, index) => {
//     if (event.key === "Backspace" && otp[index] === "" && index > 0) {
//       inputRefs.current[index - 1].focus();
//     }
//   };

//   return (
//     <div className="">
//       <div className="bg-intro-custom">
//         <div className="row ">
//           <div className="col-md-5 ">
//             <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
//               <div className="custom-intro-hight">
//                 <img
//                   src={resetPasswordSvg}
//                   width={400}
//                   className=" mt-5 custom-intro-hight "
//                   alt=""
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="col-md-7">
//             <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
//               <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
//                 <h2 className="forgot_text">Verify OTP</h2>
//                 <p>
//                   Please enter the 4-digit OTP and check your email inbox or
//                   spam folder to find the OTP sent to you. Your email address :{" "}
//                   <b>{email}</b>
//                 </p>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label htmlFor="otp">
//                       <strong>OTP</strong>
//                     </label>
//                     <div className="input-group otp-input-group">
//                       {otp.map((digit, index) => (
//                         <input
//                           key={index}
//                           type="text"
//                           maxLength="1"
//                           value={digit}
//                           onChange={(event) => handleOtpChange(event, index)}
//                           onKeyDown={(event) => handleKeyDown(event, index)}
//                           ref={(el) => (inputRefs.current[index] = el)}
//                           className="form-control otp-input"
//                           style={{
//                             width: "50px",
//                             textAlign: "center",
//                             marginRight: "10px",
//                           }}
//                         />
//                       ))}
//                     </div>
//                   </div>
//                   <button type="submit" className={`btn btn-primary w-100`}>
//                     Verify OTP
//                   </button>
//                   {successMessage && (
//                     <div className="text-success">{successMessage}</div>
//                   )}
//                 </form>
//                 <div className="text-center">
//                   <p className="mt-3 d-flex justify-content-center align-items-center gap-2">
//                     Didn't receive the OTP?
//                     <button
//                       className="btn btn-primary"
//                       onClick={handleResendOtp}
//                       disabled={isResendDisabled}
//                     >
//                       {isResendDisabled
//                         ? `Resend OTP in ${remainingTime}s`
//                         : "Resend OTP"}
//                     </button>
//                   </p>
//                 </div>
//                 {errorMessage && (
//                   <div className="text-danger mt-2">{errorMessage}</div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OTPVerification;
import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import resetPasswordSvg from "../../assets/introflow/svg/13.svg";
import toast from "react-hot-toast";
import {
  useGetUserByEmailQuery,
  useResendOtpMutation,
  useVerificationEmailMutation,
} from "../../redux/features/auth/authApi";
import { useDispatch } from "react-redux";
import { updateIsVerified } from "../../redux/features/auth/authSlice";

function OTPVerification() {
  const { email } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resendOtp] = useResendOtpMutation();
  const [verificationEmail] = useVerificationEmailMutation();
  const { data: userData, refetch } = useGetUserByEmailQuery(email, {
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: false,
  });

  const resendTime = userData?.data?.otpData.resendIn;

  const [remainingTime, setRemainingTime] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [checkingMessage, setCheckingMessage] = useState("");

  useEffect(() => {
    if (resendTime) {
      const resendDate = new Date(resendTime);
      const now = new Date();
      const diffInSeconds = Math.floor((resendDate - now) / 1000);
      setRemainingTime(diffInSeconds);

      if (diffInSeconds > 0) {
        setIsResendDisabled(true);
        const timer = setInterval(() => {
          setRemainingTime((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(timer);
              setIsResendDisabled(false);
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
        return () => clearInterval(timer);
      } else {
        setIsResendDisabled(false);
      }
    } else {
      setIsResendDisabled(false);
    }
  }, [resendTime]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const verifyData = {
      email,
      otp: otp.join(""),
    };
    if (otp.join("").length < 4) {
      toast.error("Please enter a valid OTP");
      return;
    }

    setIsButtonDisabled(true);
    setCheckingMessage("Checking OTP...");

    try {
      const { statusCode, data, message } = await verificationEmail(
        verifyData
      ).unwrap();
      if (statusCode === 200) {
        dispatch(updateIsVerified({ isVerified: data.isVerified }));
        setSuccessMessage("OTP verified successfully");
        toast.success(message, {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        navigate("/signup/intro");
      } else {
        toast.error("Failed to verify OTP");
        setErrorMessage(message || "OTP verification failed");
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      toast.error("Failed to verify OTP");
    } finally {
      setCheckingMessage("");
      setTimeout(() => setIsButtonDisabled(false), 2000);
    }
  };

  const handleResendOtp = async () => {
    setErrorMessage(""); // Clear the error message
    setOtp(["", "", "", ""]); // Clear the OTP input fields
    try {
      const { statusCode, message } = await resendOtp({ email }).unwrap();
      if (statusCode === 200) {
        toast.success(message, {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        refetch(); // refetch user data to get the new resend time
      } else {
        toast.error("Failed to resend OTP");
      }
    } catch (error) {
      console.error("Failed to resend OTP:", error);
      toast.error("Failed to resend OTP");
    }
  };

  const handleOtpChange = (event, index) => {
    const newOtp = [...otp];
    const { value } = event.target;

    // Update OTP array
    newOtp[index] = value;
    setOtp(newOtp);
    setErrorMessage("");
    setSuccessMessage(""); // Clear success message if present

    // Move focus to the next input if not empty
    if (value !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div>
      <div className="bg-intro-custom">
        <div className="row">
          <div className="col-md-5">
            <div className="d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  src={resetPasswordSvg}
                  width={400}
                  className="mt-5 custom-intro-hight"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top">
              <div className="px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h2 className="forgot_text">Verify OTP</h2>
                <p>
                  Please enter the 4-digit OTP and check your email inbox or
                  spam folder to find the OTP sent to you. Your email address:{" "}
                  <b>{email}</b>
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="otp">
                      <strong>OTP</strong>
                    </label>
                    <div className="input-group otp-input-group">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="tel"
                          maxLength="1"
                          value={digit}
                          onChange={(event) => handleOtpChange(event, index)}
                          onKeyDown={(event) => handleKeyDown(event, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          className="form-control otp-input"
                          style={{
                            width: "50px",
                            textAlign: "center",
                            marginRight: "10px",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary w-100`}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? "Checking OTP" : "Verify OTP"}
                  </button>

                  {checkingMessage && (
                    <div className="text-info mt-2">{checkingMessage}</div>
                  )}
                </form>
                <div className="text-center">
                  <p className="mt-3 d-flex justify-content-center align-items-center gap-2">
                    Didn't receive the OTP?
                    <button
                      className="btn btn-primary"
                      onClick={handleResendOtp}
                      disabled={isResendDisabled}
                    >
                      {isResendDisabled
                        ? `Resend OTP in ${remainingTime}s`
                        : "Resend OTP"}
                    </button>
                  </p>
                </div>
                {errorMessage && (
                  <div className="text-danger mt-2">{errorMessage}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPVerification;
