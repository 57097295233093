import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../login/loginsignup.css";
import { useIntroContext } from "../../hooks/useIntroContext";
import aboutBusiness from "../../assets/introflow/svg/3.svg";
import vertical2 from "../../assets/introflow/Number Vertical/2.png";

const AboutBusiness = () => {
  const { updateCompanyData, companyData } = useIntroContext();
  const [businessDescription, setBusinessDescription] = useState("");
  const [msicCode, setMsicCode] = useState("");
  console.log(msicCode);
  const [businessDescriptionError, setBusinessDescriptionError] = useState("");
  const [msicCodeError, setMsicCodeError] = useState("");
  const location = useLocation();
  const fromReview = location.state?.fromReview || false;
  const navigate = useNavigate();
  useEffect(() => {
    if (companyData) {
      setBusinessDescription(companyData.about || "");
      setMsicCode(companyData.NSICS_codes || "");
    }
  }, [companyData]);

  const handleGetStarted = (e) => {
    e.preventDefault(); // Prevent default form submission
    // Validate form fields
    if (validateForm()) {
      const data = {
        about: businessDescription,
        NSICS_codes: msicCode,
      };
      updateCompanyData(data);
      if (fromReview === true) {
        navigate("/signup/reviewSubmission");
      } else {
        navigate("/signup/sharesandpaids");
      }
    } else {
      console.log("Form validation failed!");
      // Handle validation errors
    }
  };

  const validateForm = () => {
    setMsicCodeError("");
    setBusinessDescriptionError("");
    let isValid = true;

    // Validate Business Description
    if (businessDescription.trim() === "") {
      setBusinessDescriptionError("Business description is required");
      isValid = false;
    } else if (businessDescription.length > 250) {
      setBusinessDescriptionError(
        "Business description cannot exceed 250 characters"
      );
      isValid = false;
    }

    // Validate MSIC Code
    if (msicCode === "") {
      setMsicCodeError("Please select NSICS code");
      isValid = false;
    }

    return isValid;
  };

  const handleBusinessDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 250) {
      setBusinessDescription(value);
      setBusinessDescriptionError("");
    } else {
      setBusinessDescriptionError(
        "Business description cannot exceed 250 characters"
      );
    }
  };

  const handleMsicCodeChange = (e) => {
    setMsicCode(e.target.value);
    // Reset error when user makes a selection
    setMsicCodeError("");
  };

  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  className="position-absolute verticalNumber"
                  src={vertical2}
                  alt=""
                />
                <img
                  src={aboutBusiness}
                  width={400}
                  className=" mt-5 custom-intro-hight intro-flow-img-custom-size "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7 ">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h3>About your business</h3>
                <p>
                  Tell us what your company will be doing and select the correct
                  nature of business (NSICS Codes)
                </p>
                <form>
                  <div className="mb-3">
                    <label htmlFor="firstName">
                      What will your company do?
                    </label>
                    <textarea
                      name="comments"
                      id="comments"
                      className="form-control rounded-0"
                      placeholder="Please concisely explain what your business will be doing in 2 to 3 sentences Maximum..."
                      value={businessDescription}
                      onChange={handleBusinessDescriptionChange}
                    ></textarea>
                    {businessDescriptionError && (
                      <div className="text-danger">
                        {businessDescriptionError}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="pet-select">Select your NAICS codes</label>
                    <br />
                    <select
                      name="pets"
                      id="pet-select"
                      value={msicCode}
                      onChange={handleMsicCodeChange}
                    >
                      <option value="">Select NAICS codes</option>
                      <option value="551100">551100 (Management of Companies and Enterprises)</option>
                      <option value="551111">551111 (Offices of Bank Holding Companies)</option>
                      <option value="551112">551112 (Offices of Other Holding Companies)</option>
                      <option value="551114">551114 (Corporate, Subsidiary, and Regional Managing Offices)</option>
                    </select>
                    {msicCodeError && (
                      <div className="text-danger">{msicCodeError}</div>
                    )}
                  </div>
                </form>
                {fromReview ? (
                  <button
                    onClick={handleGetStarted}
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Update Info</strong>
                  </button>
                ) : (
                  <button
                    onClick={handleGetStarted}
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Next Step →</strong>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBusiness;
