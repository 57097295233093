import React, { useEffect, useRef, useState } from "react";
import { IoGrid } from "react-icons/io5";
import { FaPaste, FaThList } from "react-icons/fa";
import { FaFolderPlus } from "react-icons/fa6";
import {
  useCopyFilesMutation,
  useCreateFolderMutation,
  useCutFilesMutation,
  useDeleteFilesMutation,
  useUploadFilesToCompanyMutation,
  useUserDocumentsQuery,
} from "../../../../redux/features/files/filesApi";
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  alpha,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CutIcon from "@mui/icons-material/ContentCut";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { MdSort } from "react-icons/md";
import toast from "react-hot-toast";
import { ScaleLoader } from "react-spinners";
import Pagination from "../../../../ui/Pagination";
import useWindowWidth from "../../../../ui/useWindowWidth";

const UserDocuments = ({ user, company_information, companyLoading }) => {
  const windowWidth = useWindowWidth();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const activeCompanyId = company_information?._id;
  const [uploadFilesToCompany, { isLoading: isUploading }] =
    useUploadFilesToCompanyMutation();
  const [deleteFiles, { isLoading: isDeleting }] = useDeleteFilesMutation();
  const [copyFiles, { isLoading: isCopying }] = useCopyFilesMutation();
  const [cutFiles, { isLoading: isCutting }] = useCutFilesMutation();
  const [createFolder, { isLoading: isCreatingFolder }] =
    useCreateFolderMutation();
  const [currentFolder, setCurrentFolder] = useState(null);
  const [activeTab, setActiveTab] = useState("yourLetters");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [folderHistory, setFolderHistory] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [selectedItems, setSelectedItems] = useState([]);
  console.log(selectedItems, "selectedItems");

  const [operation, setOperation] = useState(null);
  const [newFolder, setNewFolder] = useState(null);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Sorting state
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const [toggleCreateFolder, setToggleCreateFolder] = useState(false);

  const [files, setFiles] = useState(null);
  const folders = {
    yourLetters: `UserData/${user?.email}/documents/companyData/${activeCompanyId}/mainDocuments/yourLetters/`,
    statutoryFiles: `UserData/${user?.email}/documents/companyData/${activeCompanyId}/mainDocuments/statutoryFiles/`,
    boardResolutions: `UserData/${user?.email}/documents/companyData/${activeCompanyId}/mainDocuments/boardResolutions/`,
    providedData: `UserData/${user?.email}/documents/companyData/${activeCompanyId}/providedDocuments/`,
  };
  const inputRef = useRef(null);
  const {
    data,
    error,
    isLoading: documentsLoading,
    refetch,
  } = useUserDocumentsQuery(currentFolder, { skip: !currentFolder });

  useEffect(() => {
    if (!companyLoading && activeCompanyId) {
      setCurrentFolder(folders.yourLetters);
    }
  }, [companyLoading, activeCompanyId, user?.email]);

  useEffect(() => {
    if (currentFolder) {
      refetch();
      setSelectedItems([]);
    }
  }, [currentFolder, refetch]);

  const handleTabClick = (tab) => {
    if (!loading) {
      setLoading(true);
      setActiveTab(tab);
      setCurrentFolder(folders[tab]);
      setFolderHistory([]);
      setSelectedItems([]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  const formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) {
      return (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      return (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      return bytes + " bytes";
    } else if (bytes === 1) {
      return bytes + " byte";
    } else {
      return "0 bytes";
    }
  };

  const handleFolderClick = (folderName) => {
    if (!loading) {
      setLoading(true);
      setFolderHistory([...folderHistory, currentFolder]);
      const newFolder = `${currentFolder}${folderName}`;
      setCurrentFolder(newFolder);
      setSelectedItems([]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleBackClick = () => {
    if (!loading && folderHistory.length > 0) {
      setLoading(true);
      const newFolderHistory = [...folderHistory];
      const previousFolder = newFolderHistory.pop();
      setFolderHistory(newFolderHistory);
      setCurrentFolder(previousFolder);
      setSelectedItems([]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSelectedItems([]);
  };
  const selectContents = (folder, setSelectedItems) => {
    if (!folder || !folder.key) {
      console.error("Invalid folder object provided");
      return;
    }

    console.log("Selecting contents of folder:", folder.key);

    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some((i) => i.key === folder.key);
      const newSelection = isSelected
        ? prevSelected.filter((i) => !i.key.startsWith(folder.key)) // Deselect folder and contents
        : [
            ...prevSelected,
            { ...folder, type: "folder" }, // Select folder
            ...folder.contents.flatMap((content) => ({
              // Flatten nested content (optional)
              ...content,
              type: content.isFolder ? "folder" : "file",
            })),
          ];

      return newSelection;
    });
  };
  const handleSelectItem = (item, type, folder) => {
    if (!item || !item.key) {
      console.log("Item or item key is undefined");
      return; // Exit early if item or item key is undefined
    }

    if (type === "folder") {
      console.log("Folder selected:", item.key); // Log the folder being selected

      // Toggle selection for the folder itself
      setSelectedItems((prevSelected) =>
        prevSelected.some((i) => i.key === item.key)
          ? prevSelected.filter((i) => i.key !== item.key)
          : [...prevSelected, { ...item, type }]
      );

      // Select contents of the folder

      selectContents(folder, setSelectedItems);
    } else {
      // Handle selection for files
      console.log("File selected:", item.key); // Log the file being selected

      setSelectedItems((prevSelected) =>
        prevSelected.some((i) => i.key === item.key)
          ? prevSelected.filter((i) => i.key !== item.key)
          : [...prevSelected, { ...item, type }]
      );
    }
  };

  const handleCopy = () => {
    setOperation({ type: "copy", items: selectedItems });
    setSelectedItems([]);
    console.log("Copying:", selectedItems);
  };

  const handleCut = () => {
    setOperation({ type: "cut", items: selectedItems });
    setSelectedItems([]);
    console.log("Cutting:", selectedItems);
  };
  const handlePaste = async () => {
    if (!operation) return;
    const destinationFolder = currentFolder;
    try {
      let result;
      if (operation.type === "copy") {
        result = await copyFiles({
          files: operation.items,
          destinationFolder,
        }).unwrap();
      } else if (operation.type === "cut") {
        result = await cutFiles({
          files: operation.items,
          destinationFolder,
        }).unwrap();
      }
      if (result?.success === true) {
        toast.success(`Files ${operation.type}ed successfully`, {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        refetch();
      } else {
        toast.error(`Failed to ${operation.type} files`);
      }
    } catch (error) {
      toast.error(`Error ${operation.type}ing files`);
      console.error(`Error ${operation.type}ing files:`, error);
    } finally {
      setOperation(null);
    }
  };

  // const handleCopyToSpace = async () => {
  //   const destinationFolder = currentFolder; // Provide the destination folder here
  //   try {
  //     const result = await copyFiles({
  //       files: selectedForCopyCut,
  //       destinationFolder,
  //     }).unwrap();
  //     if (result?.success === true) {
  //       toast.success("Files copied successfully", {
  //         duration: 2500,
  //         style: { background: "#050F26" },
  //         className: "text-white",
  //       });
  //       refetch();
  //     } else {
  //       toast.error("Failed to copy files");
  //     }
  //   } catch (error) {
  //     toast.error("Error copying files");
  //     console.error("Error copying files:", error);
  //   }
  // };

  const handleDelete = async () => {
    const files = selectedItems;
    console.log(files);
    try {
      const result = await deleteFiles(files).unwrap();
      if (result?.success === true) {
        toast.success("File deleted successfully", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        if (inputRef.current) {
          inputRef.current.value = "";
        }
        setSelectedItems([]);
        refetch();
      } else {
        toast.error("Failed to delete files");
      }
    } catch (error) {
      toast.error("Error deleting files");
      console.error("Error deleting files:", error);
    }
    console.log(selectedItems);
  };

  const handleNewFolder = async () => {
    const existingFolderName = data?.data.folders?.map((folder) => folder.name);
    console.log(existingFolderName);
    if (existingFolderName?.includes(newFolder + "/")) {
      return toast.error(
        `Folder "${newFolder}" already exists. Skipping Create Folder.`
      );
    }
    if (newFolder === null || newFolder === "") {
      return toast.error("Folder name cannot be empty");
    }
    const cleanData = {
      newFolder,
      folderPath: currentFolder,
    };
    const result = await createFolder(cleanData).unwrap();
    console.log(result);
    if (result?.success === true) {
      toast.success("Folder created successfully", {
        duration: 2500,
        style: { background: "#050F26" },
        className: "text-white",
      });
      setNewFolder(null);
      handleToggleCreateFolder();
    } else {
      toast.error("Failed to create folder");
    }
  };

  if (companyLoading || !activeCompanyId || !currentFolder) {
    return <div>Loading...</div>;
  }

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setFiles(filesArray);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();

    if (files?.length === 0) {
      return toast.error("No files to upload");
    }
    const existingFileNames = data?.data.files?.map((file) => file.filename); // Get existing file names from fetched data
    const formData = new FormData();
    files?.forEach((file) => {
      // Check for existing file names before adding to formData
      if (!existingFileNames.includes(file.name)) {
        formData.append("files", file);
      } else {
        toast.error(`File "${file.name}" already exists. Skipping upload.`);
      }
    });

    if (formData.has("files")) {
      // Only proceed if there are files to upload (after filtering)
      const folderName = currentFolder;

      try {
        const result = await uploadFilesToCompany({
          formData,
          folderName,
        }).unwrap();
        if (result?.success === true) {
          toast.success("Files uploaded successfully", {
            duration: 2500,
            style: { background: "#050F26" },
            className: "text-white",
          });
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          setFiles([]);
          refetch();
        } else {
          toast.error("Failed to upload files");
        }
      } catch (error) {
        toast.error("Error uploading files");
        console.error("Error uploading files:", error);
      }
    }
  };

  const handleToggleCreateFolder = () => {
    setToggleCreateFolder(!toggleCreateFolder);
  };
  const handleFolderNameChange = (event) => {
    setNewFolder(event.target.value);
  };
  // Sorting and pagination logic
  const sortAndPaginate = (items) => {
    const sortedItems = [...items].sort((a, b) => {
      if (sortField === "name") {
        return sortOrder === "asc"
          ? (a.name || "").localeCompare(b.name || "")
          : (b.name || "").localeCompare(a.name || "");
      } else if (sortField === "size") {
        return sortOrder === "asc"
          ? (a.size || 0) - (b.size || 0)
          : (b.size || 0) - (a.size || 0);
      } else if (sortField === "date") {
        return sortOrder === "asc"
          ? new Date(a.lastModified || 0) - new Date(b.lastModified || 0)
          : new Date(b.lastModified || 0) - new Date(a.lastModified || 0);
      }
      return 0;
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = sortedItems.slice(
      startIndex,
      startIndex + itemsPerPage
    );
    return paginatedItems;
  };

  const handleSortChange = (event, field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
    handleClose(event); // Make sure to pass the event here
  };

  const filteredFolders = sortAndPaginate(
    data?.data.folders?.filter(
      (folder) =>
        folder.name &&
        folder.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []
  );

  const filteredFiles = sortAndPaginate(
    data?.data.files?.filter(
      (file) =>
        file.filename &&
        file.filename.toLowerCase().includes(searchQuery.toLowerCase()) &&
        file.size > 0
    ) || []
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalItems =
    (data?.data.folders?.length || 0) + (data?.data.files?.length || 0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  if (companyLoading || !activeCompanyId || !currentFolder) {
    return (
      <div className="d-flex justify-content-center align-items-end h-100 w-100 ">
        <ScaleLoader className="position-absolute top-50" color="#4c36d6" />
      </div>
    );
  }
  return (
    <div className="card my-3 p-4 file-table_docu">
      <div className="">
        <div className="mt-3">
          <h5 className="m-0 document-heading">Documents</h5>
          <p className="m-0 document-heading">
            Here you can find all your company documents such as your
            incorporation certificate
          </p>
        </div>
      </div>
      <userDocumentsEdit className="">
        <div className="">
          <customDocumentTab>
            <div className="d-md-block d-flex justify-content-between">
              {Object.keys(folders).map((key) => (
                <button
                  key={key}
                  className={`tab custom-document-tab ${
                    activeTab === key ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(key)}
                  disabled={loading}
                >
                  {key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}
                </button>
              ))}
            </div>
          </customDocumentTab>

          <input
            type="text"
            placeholder="Search documents..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input "
          />
          <div className="d-flex justify-content-between border align-items-center mt-3 py-2">
            <nav className="breadcrumb-nav">
              <button
                onClick={handleBackClick}
                disabled={folderHistory.length === 0 || loading}
              >
                &larr; Back
              </button>
              {currentFolder !== folders[activeTab] &&
                currentFolder
                  .replace(folders[activeTab], "")
                  .split("/")
                  .filter(Boolean)
                  .map((crumb, index) => (
                    <span key={index}>
                      {crumb}
                      {index <
                        currentFolder
                          .replace(folders[activeTab], "")
                          .split("/")
                          .filter(Boolean).length -
                          1 && " / "}
                    </span>
                  ))}
            </nav>
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <div>
                <div>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <MdSort className="sort-menu-icon" />
                  </Button>
                  <Popper
                    className=" custom-dropdown-menu"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={(event) =>
                                  handleSortChange(event, "name")
                                }
                              >
                                Sort By Name
                              </MenuItem>
                              <MenuItem
                                onClick={(event) =>
                                  handleSortChange(event, "size")
                                }
                              >
                                Sort By Size
                              </MenuItem>
                              <MenuItem
                                onClick={(event) =>
                                  handleSortChange(event, "date")
                                }
                              >
                                Sort By Date
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>

              <button
                className="view-button d-flex align-items-center border-0 bg-transparent"
                onClick={() =>
                  setViewMode(viewMode === "list" ? "grid" : "list")
                }
              >
                {viewMode === "list" ? <IoGrid /> : <FaThList />}
              </button>
            </div>
          </div>
          <div className="d-md-flex justify-content-between align-content-center">
            <div>
              {selectedItems.length > 0 && (
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(selectedItems.length > 0 && {}),
                  }}
                >
                  <Tooltip title="Copy">
                    <IconButton onClick={handleCopy}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cut">
                    <IconButton onClick={handleCut}>
                      <CutIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={handleDelete}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Toolbar>
              )}
            </div>
            {operation && (
              <div className="d-flex justify-content-center align-items-center">
                {" "}
                <Tooltip
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                  }}
                  onClick={handlePaste}
                  disabled={!operation}
                  title="Paste"
                  placement="top"
                >
                  Paste <FaPaste className="past-button" />
                </Tooltip>
              </div>
            )}
            <div>
              <div>
                <div className="flies_upload_ad">
                  <Box
                    className="mt-md-0 mt-2"
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      className="file_upload-form_button"
                      variant="outlined"
                      type="file"
                      inputProps={{
                        multiple: true,
                      }}
                      inputRef={inputRef}
                      onChange={handleFileChange}
                    />
                    {files?.length > 0 && (
                      <button onClick={handleFileUpload} disabled={isUploading}>
                        {isUploading ? "Uploading..." : "Upload"}
                      </button>
                    )}
                  </Box>
                </div>
              </div>
            </div>

            <div className="d-flex file_upload-form_button gap-2 justify-content-center align-items-center  my-md-0 my-2">
              {toggleCreateFolder && (
                <>
                  <TextField
                    id="outlined-basic"
                    onChange={handleFolderNameChange}
                    variant="outlined"
                    size="small"
                  />
                  <button
                    className="btn btn-outline-success"
                    onClick={handleNewFolder}
                    value="check"
                  >
                    <CheckIcon />
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={handleToggleCreateFolder}
                    value="check"
                  >
                    Cancel
                  </button>
                </>
              )}
              {!toggleCreateFolder && (
                <button
                  onClick={handleToggleCreateFolder}
                  className=" btn btn-outline-primary file_upload-form_button d-flex  gap-2 justify-content-center align-items-center"
                >
                  Create Folder
                  <FaFolderPlus className="folder-plus " />
                </button>
              )}
            </div>
          </div>

          <div className="position-relative ">
            {documentsLoading || loading ? (
              <div className="p-4">
                {" "}
                <div className="d-flex justify-content-center align-items-end h-100 w-100 ">
                  <ScaleLoader
                    className="position-absolute top-50"
                    color="#4c36d6"
                  />
                </div>
              </div>
            ) : (
              <>
                {viewMode === "list" ? (
                  <>
                    {windowWidth >= 768 ? (
                      <table className="file-table file-table_docu w-100">
                        <thead>
                          <tr>
                            <th className="table-padding-left">Select</th>
                            <th>Serial</th>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Type</th>
                            <th>Last Modified</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredFolders?.map((folder, index) => (
                            <tr
                              key={folder.key}
                              className={loading ? "disabled" : ""}
                            >
                              <td className="table-padding-left">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedItems.some(
                                        (item) => item.key === folder.key
                                      )}
                                      onChange={() =>
                                        handleSelectItem(folder, "folder")
                                      }
                                    />
                                  }
                                  label=""
                                />
                              </td>
                              <td>{index + 1}</td>
                              <td
                                onClick={() => handleFolderClick(folder.name)}
                              >
                                <i className="folders_styling2"></i>
                                <div className="filename">
                                  {folder.name
                                    .replace(currentFolder, "")
                                    .replace(/\//g, "")}
                                </div>
                              </td>
                              <td>—</td>
                              <td>Folder</td>
                              <td>—</td>
                            </tr>
                          ))}
                          {filteredFiles?.map((file, index) => (
                            <tr key={file.key}>
                              <td className="table-padding-left">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedItems.some(
                                        (item) => item.key === file.key
                                      )}
                                      onChange={() =>
                                        handleSelectItem(file, "file")
                                      }
                                    />
                                  }
                                  label=""
                                />
                              </td>
                              <td>
                                {index +
                                  1 +
                                  (filteredFolders
                                    ? filteredFolders.length
                                    : 0)}
                              </td>
                              <td>
                                <i className="fas fa-file text-primary files_style"></i>
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {file.filename.replace(/\//g, "")}
                                </a>
                              </td>
                              <td>{formatSizeUnits(file.size)}</td>
                              <td>File</td>
                              <td>
                                {new Date(
                                  file.lastModified
                                ).toLocaleDateString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <table className="file-table file-table_docu w-100">
                        <thead>
                          <tr>
                            <th className="table-padding-left-mobile">
                              Select
                            </th>

                            <th>Name</th>
                            {/* <th>Size</th>
                            <th>Type</th>
                            <th>Last Modified</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredFolders?.map((folder, index) => (
                            <tr
                              key={folder.key}
                              className={loading ? "disabled" : ""}
                            >
                              <td>
                                <FormControlLabel
                                  className="table-padding-left-mobile"
                                  control={
                                    <Checkbox
                                      checked={selectedItems.some(
                                        (item) => item.key === folder.key
                                      )}
                                      onChange={() =>
                                        handleSelectItem(folder, "folder")
                                      }
                                    />
                                  }
                                  label=""
                                />
                              </td>

                              <td
                                onClick={() => handleFolderClick(folder.name)}
                              >
                                <i className="folders_styling2"></i>
                                <div className="d-flex  align-items-center table-font-weight">
                                  {folder.name
                                    .replace(currentFolder, "")
                                    .replace(/\//g, "")}
                                </div>
                              </td>
                            </tr>
                          ))}
                          {filteredFiles?.map((file, index) => (
                            <tr key={file.key}>
                              <td>
                                <FormControlLabel
                                  className="table-padding-left-mobile"
                                  control={
                                    <Checkbox
                                      checked={selectedItems.some(
                                        (item) => item.key === file.key
                                      )}
                                      onChange={() =>
                                        handleSelectItem(file, "file")
                                      }
                                    />
                                  }
                                  label=""
                                />
                              </td>

                              <td className="d-flex gap-1 justify-content-lg-start align-items-center">
                                <i className="fas fa-file text-primary files_style"></i>{" "}
                                <div className="d-flex flex-column justify-content-center file-table-margin">
                                  <a
                                    className="table-font-weight"
                                    href={file.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {file.filename.replace(/\//g, "")}
                                  </a>
                                  <div className="d-flex gap-2">
                                    <div>
                                      {" "}
                                      Modified :
                                      {new Date(
                                        file.lastModified
                                      ).toLocaleDateString()}
                                    </div>
                                    <div>Size : {file.size}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                ) : (
                  <div className="grid-view row mt-4">
                    {filteredFolders?.map((folder, index) => (
                      <div
                        className="col-lg-3 col-md-4 col-6"
                        key={folder.name}
                      >
                        <div
                          className={`grid-item folder position-relative ${
                            loading ? "disabled" : ""
                          }`}
                        >
                          <FormControlLabel
                            className="position-absolute top-0 end-0"
                            control={
                              <Checkbox
                                checked={selectedItems.some(
                                  (item) => item.key === folder.key
                                )}
                                onChange={() =>
                                  handleSelectItem(folder, "folder")
                                }
                              />
                            }
                            label=""
                          />
                          <div onClick={() => handleFolderClick(folder.name)}>
                            <i className="folders_styling"></i>
                            <div className="folder-file-name-absolute ">
                              {folder.name
                                .replace(currentFolder, "")
                                .replace(/\//g, "")}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {filteredFiles?.map((file, index) => (
                      <div className="col-lg-3 col-md-4 col-6" key={file.key}>
                        <div className="grid-item file position-relative">
                          {" "}
                          <FormControlLabel
                            className="grid-file-check-position"
                            control={
                              <Checkbox
                                checked={selectedItems.some(
                                  (item) => item.key === file.key
                                )}
                                onChange={() => handleSelectItem(file, "file")}
                              />
                            }
                            label=""
                          />
                          <div>
                            <i className="fas fa-file text-primary"></i>
                            <a
                              className="file-name-text-size-md"
                              href={file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.filename.replace(/\//g, "")}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="docu_pagi">
            {error && <p>Error fetching contents: {error?.message}</p>}

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </userDocumentsEdit>
    </div>
  );
};

export default UserDocuments;
