import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet, Navigate } from "react-router-dom";
import "../login/loginsignup.css";

export default function Company() {
  const location = useLocation();
  const navigate = useNavigate();

  // Default to 'profile' if no subpath is provided
  useEffect(() => {
    if (location.pathname === "/dashboards/company") {
      navigate("/dashboards/company/profile");
    }
  }, [location, navigate]);

  const [activeEdit, setActiveEdit] = useState(() => {
    // Initialize activeEdit based on current path
    const path = location.pathname.split("/").pop();
    return ["profile", "directors", "shareholders"].includes(path)
      ? path
      : "profile";
  });

  const menuItem = [
    {
      name: "Profile",
      path: "profile",
      icon: "",
    },
    {
      name: "Directors",
      path: "directors",
      icon: "",
    },
    {
      name: "Shareholders",
      path: "shareholders",
      icon: "",
    },
  ];

  const handleNavigation = (path) => {
    setActiveEdit(path);
    navigate(`/dashboards/company/${path}`);
  };

  const renderMenu = () => (
    <div className="">
      <nav className="d-flex gap-2 align-items-center company_nav">
        <h4 className="fw-semibold">Company</h4>
        <div className="d-flex justify-content-center align-items-center">
          {menuItem.map((item, index) => (
            <div
              key={index}
              onClick={() => handleNavigation(item.path)}
              className={` m-1 ${
                activeEdit === item.path
                  ? "active_company_menu "
                  : "non-active_company_menu cursor-pointer "
              }`}
            >
              {item.name}
            </div>
          ))}
        </div>
      </nav>
    </div>
  );

  return (
    <div className="d-flex flex-column align-content-center p-4 ">
      <header className="">
        <div className="">{renderMenu()}</div>
      </header>

      <div>
        <div className="">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
