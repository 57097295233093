import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useGetAllPaymentInfoQuery } from "../../../../redux/features/payment/paymentApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController
);
const aggregateWeeklyPayments = (payments) => {
  const weeklyData = {};

  payments?.forEach((payment) => {
    const date = new Date(payment.createdAt);
    const startOfWeek = getStartOfWeek(date);
    const endOfWeek = getEndOfWeek(date);
    const weekLabel = `${startOfWeek.toLocaleDateString()} - ${endOfWeek.toLocaleDateString()}`;

    if (!weeklyData[weekLabel]) {
      weeklyData[weekLabel] = 0;
    }
    weeklyData[weekLabel] += payment.total_amount;
  });

  return weeklyData;
};
const getStartOfWeek = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  return new Date(date.setDate(diff));
};

// Helper function to get the end of the week
const getEndOfWeek = (date) => {
  const startOfWeek = getStartOfWeek(new Date(date));
  return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
};
export default function PaymentChart() {
  const { data: userPayments } = useGetAllPaymentInfoQuery();
  const payments = userPayments?.data || [];

  const weeklyPayments = aggregateWeeklyPayments(payments);
  const chartLabels = Object.keys(weeklyPayments).sort();
  const chartData = chartLabels.map((label) => weeklyPayments[label]);

  const [currentPage, setCurrentPage] = useState(1);
  const paymentsPerPage = 8;

  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentLabels = chartLabels.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );
  const currentData = chartData.slice(indexOfFirstPayment, indexOfLastPayment);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = new ChartJS(chartRef.current, {
        type: "bar",
        data: {
          labels: currentLabels,
          datasets: [
            {
              label: "Weekly Payments",
              data: currentData,
              backgroundColor: "#4c36d6",
              borderColor: "#4c36d6",
              tension: 0.4,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Weekly Payments",
            },
          },
        },
      });

      return () => {
        chartInstance.destroy();
      };
    }
  }, [currentLabels, currentData]);

  const chartRef = useRef(null);
  return (
    <div className="card z-index-2  ">
      <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
        <div className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
          <div className="chart">
            <canvas
              ref={chartRef}
              id="chart-bars"
              className="chart-canvas "
              height={170}
              width={304}
              style={{
                display: "block",
                boxSizing: "border-box",
                height: 170,
                width: 304,
              }}
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="pagination ">
          {[
            ...Array(Math.ceil(chartLabels.length / paymentsPerPage)).keys(),
          ].map((number) => (
            <button
              key={number}
              onClick={() => paginate(number + 1)}
              className={`btn ${
                currentPage === number + 1 ? "btn-primary" : "btn-secondary"
              }`}
            >
              {number + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
