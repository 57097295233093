// Import dependencies
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import forgotPasswordSvg from "../../assets/introflow/svg/13.svg";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios"; // Import axios
import useAxiosSecure from "../../hooks/AxiosSecure";

function ForgotPassword() {
  const { axiosSecure } = useAxiosSecure();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailFilled, setIsEmailFilled] = useState(false);

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setSuccessMessage("");
    setIsEmailFilled(value.trim() !== "");
    setErrorMessage("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const checkEmailResponse = await axiosSecure.post(
        "/users/check-email-for-password",
        { email }
      );

      if (checkEmailResponse.status !== 200) {
        toast.error("User not registered", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        return setErrorMessage("User not registered");
      }

      const resetPasswordResponse = await axiosSecure.post(
        "/users/forgot-password",
        { email }
      );

      if (resetPasswordResponse.status === 200) {
        toast.success("Password reset email has been sent", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        setSuccessMessage(
          "Password reset email has been sent. You will get an email only if you have an account with us."
        );
        navigate("/");
      } else {
        toast.error("Failed to send reset password mail");
        console.error(
          "Error resetting password:",
          resetPasswordResponse.status
        );
      }
    } catch (error) {
      toast.error("Failed to send reset password mail");
      console.error("Error resetting password:", error);
    }

    setEmail("");
    setIsEmailFilled(false);
  };

  return (
    <div className="">
      <div className="bg-intro-custom">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  src={forgotPasswordSvg}
                  width={400}
                  className=" mt-5 custom-intro-hight "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h2 className="forgot_text">Forgot password? 😵</h2>
                <p>
                  No worries, it happens to the best of us! Simply request a
                  password reset link by entering your email address below.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      value={email}
                      onChange={handleEmailChange}
                      className="form-control rounded-0"
                    />
                    {errorMessage && (
                      <div className="text-danger">{errorMessage}</div>
                    )}
                  </div>

                  <button
                    type="submit"
                    className={` forgot_button  w-100 btn btn-primary  ${
                      !isEmailFilled ? "disabled" : ""
                    }`}
                    disabled={!isEmailFilled}
                  >
                    Send me reset password instructions
                  </button>
                  {successMessage && (
                    <div className="text-success">{successMessage}</div>
                  )}
                  <div className="text-center">
                    <span className=" mt-3">
                      Suddenly remembered? <Link to="/">Sign In</Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default ForgotPassword;
