import * as React from "react";
import UserProfileHook from "../../../../hooks/UserProfileHook";
import { Link } from "react-router-dom";
import { useVerifyCompanyMutation } from "../../../../redux/api/companyApi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";
import SingleCompanyHook from "../../../../hooks/SingleCompanyHook";
export default function VerifyCompanyTable({
  companies,
  currentPage,
  totalPages,
  handleFirstPage,
  handleLastPage,
  handlePreviousPage,
  handleNextPage,
  handlePageClick,
}) {
  const companiesPerPage = 5;

  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompany = companies.slice(
    indexOfFirstCompany,
    indexOfLastCompany
  );

  const [verifyCompany] = useVerifyCompanyMutation();

  const renderPageNumbers = (
    currentPage,
    totalPages,
    handleFirstPage,
    handleLastPage,
    handlePageClick
  ) => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`btn ${currentPage === i ? "btn-primary" : "btn-link"}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button className="btn btn-link" onClick={handleFirstPage}>
              1
            </button>
            {startPage > 2 && <span>...</span>}
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <button className="btn btn-link" onClick={handleLastPage}>
              {totalPages}
            </button>
          </>
        )}
      </>
    );
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = async (_id) => {
    console.log(_id);
    const cleanData = {
      company_id: _id,
    };
    try {
      const res = await verifyCompany(cleanData).unwrap();
      if (res.success === true) {
        toast.success("Company approved successfully");
        setOpen(false);
      }
    } catch (error) {
      toast.error("Failed to approve company");
      console.error("Failed to approve company:", error);
    }
  };

  return (
    <div className="col-12 grid-margin">
      <div className="card my-2">
        <div className="card-body">
          <h4 className="card-title text-center">Pending Approval</h4>
          {companies.length === 0 ? (
            <p className="text-center">
              Pending approval company will appear here
            </p>
          ) : (
            <>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Owner</th>
                      <th>Email</th>
                      <th>Shareholders</th>
                      <th>Directors</th>
                      <th>Registered At</th>
                      <th>Details</th>
                      <th>Approval</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCompany.map((item, index) => (
                      <tr key={item._id}>
                        <td>{indexOfFirstCompany + index + 1}</td>
                        <td className="overflow-table-wrapper">
                        
                          <SingleCompanyHook _id={item._id} />
                        </td>

                        <td className="overflow-table-wrapper">
                          <UserProfileHook _id={item.owner} />
                        </td>
                        <td>{item.company_email}</td>
                        <td>{item.shareholders.length}</td>
                        <td>{item.directors.length}</td>
                        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                        <td>
                          <Link
                            to={`/admin/dashboards/adminCompanyDetails/${item._id}`}
                          >
                            Details
                          </Link>
                        </td>
                        <td>
                          <React.Fragment>
                            <Button
                              variant="contained"
                              onClick={handleClickOpen}
                            >
                              Approve
                            </Button>
                            <Dialog
                              fullScreen={fullScreen}
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <DialogTitle id="responsive-dialog-title">
                                {
                                  "Are you sure you want to approve this company?"
                                }
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  After approving this company, it will be
                                  verified and will be visible to the company
                                  owner at their dashboard.
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button autoFocus onClick={handleClose}>
                                  Disagree
                                </Button>
                                <Button
                                  onClick={() => handleConfirmation(item._id)}
                                  autoFocus
                                >
                                  Agree
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </React.Fragment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <button
                  className="btn btn-link"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>
                {renderPageNumbers(
                  currentPage,
                  totalPages,
                  handleFirstPage,
                  handleLastPage,
                  handlePageClick
                )}
                <button
                  className="btn btn-link"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  &gt;
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
