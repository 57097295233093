import React from "react";
import "./userManual.css";
import logoSvg from "../../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
export default function TermsAndCondition() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="terms-container bg-white">
      <header className="terms-header">
        <div className="terms-title">
          <div className="position-relative ">
            <Link to="/signup">
              <img className="logoSvg-user-manual  " src={logoSvg} alt="" />
            </Link>
          </div>
          <div>
            <h2>Terms and Conditions</h2>
            <Link className="text-decoration-none" onClick={handleBack}>
              Back to preview page{" "}
            </Link>
          </div>
        </div>
      </header>
      <div className="mx-5 px-5 mt-4">
        <h5 className="mb-4">
          Welcome to Incorplink! By using our services, you agree to comply with
          and be bound by the following terms and conditions. Please review them
          carefully.
        </h5>
        <hr className="mb-4" />
        <section className="terms-section">
          <h3>1. Introduction</h3>
          <p>
            Welcome to Incorplink! By using our services, you agree to comply
            with and be bound by the following terms and conditions. Please
            review them carefully.
          </p>
        </section>
        <section className="terms-section">
          <h3>2. Services</h3>
          <p>
            Incorplink specializes in providing services for creating companies
            in the US market from Bangladesh. Our services include providing all
            necessary legal documents, company bank management, and additional
            facilities to support your business needs.
          </p>
        </section>
        <section className="terms-section">
          <h3>3. Eligibility</h3>
          <p>
            By using our services, you represent and warrant that you are at
            least 18 years old and are legally capable of entering into binding
            contracts.
          </p>
        </section>
        <section className="terms-section">
          <h3>4. User Responsibilities</h3>
          <ul>
            <li>
              You agree to provide accurate and current information about
              yourself as requested.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account and password.
            </li>
            <li>
              You agree to notify us immediately of any unauthorized use of your
              account.
            </li>
          </ul>
        </section>
        <section className="terms-section">
          <h3>5. Payment Terms</h3>
          <ul>
            <li>
              All services provided by Incorplink are subject to fees as
              outlined in our pricing schedule.
            </li>
            <li>
              Payments are due upon receipt of invoice unless otherwise stated.
            </li>
            <li>All payments are nonrefundable.</li>
          </ul>
        </section>
        <section className="terms-section">
          <h3>6. Intellectual Property</h3>
          <p>
            All content, including but not limited to text, graphics, logos, and
            software, is the property of Incorplink or its content suppliers and
            is protected by copyright laws. You may not reproduce, distribute,
            or create derivative works from any content without express written
            permission from Incorplink.
          </p>
        </section>
        <section className="terms-section">
          <h3>7. Confidentiality</h3>
          <p>
            Both parties agree to maintain the confidentiality of all
            information and materials provided during the course of the
            services.
          </p>
        </section>
        <section className="terms-section">
          <h3>8. Limitation of Liability</h3>
          <p>
            Incorplink is not liable for any indirect, incidental, or
            consequential damages arising out of or in connection with the use
            of our services. Our total liability to you for any claim arising
            out of or relating to these terms or our services is limited to the
            amount paid by you for the services in question.
          </p>
        </section>
        <section className="terms-section">
          <h3>9. Termination</h3>
          <p>
            Incorplink reserves the right to terminate or suspend your account
            and access to our services at our sole discretion, without notice,
            for conduct that we believe violates these terms or is harmful to
            other users of our services.
          </p>
        </section>
        <section className="terms-section">
          <h3>10. Governing Law</h3>
          <p>
            These terms and policies are governed by and construed in accordance
            with the laws of Bangladesh, without regard to its conflict of law
            principles.
          </p>
        </section>
        <section className="terms-section">
          <h3>11. Changes to Terms</h3>
          <p>
            Incorplink reserves the right to modify these terms and policies at
            any time. We will notify you of any changes by posting the new terms
            on our website. Your continued use of our services after any such
            changes constitutes your acceptance of the new terms.
          </p>
        </section>
        <section className="terms-section">
          <h3>12. Contact Information</h3>
          <p>
            If you have any questions or concerns about these terms and
            policies, please contact us at:
          </p>
          <p>Incorplink</p>
          <p>Email: support@incorplink.com</p>
          <p>Phone: +880 1988 121220</p>
        </section>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <button onClick={handleBack} className="link_404">
          Back To Previous Page
        </button>
      </div>
    </div>
  );
}
