import React, { useEffect, useState } from "react";
import { IoGrid } from "react-icons/io5";
import { FaThList } from "react-icons/fa";
import { useGetInvoicesMutation } from "../../redux/features/files/filesApi";
import { useSelector } from "react-redux";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import "./dashboard.css";
import useWindowWidth from "../../ui/useWindowWidth";
import Pagination from "../../ui/Pagination"; // Adjust the path as necessary
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { MdSort } from "react-icons/md";
import { ScaleLoader } from "react-spinners";

const Invoices = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [getInvoices, { isLoading, isError, error }] = useGetInvoicesMutation();
  const [invoices, setInvoices] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const { data: companyInfo, isLoading: companyLoading } =
    useUserActiveCompanyQuery();
  const activeCompanyId = companyInfo?.data?._id;
  const [currentFolder, setCurrentFolder] = useState(null);
  const [activeTab, setActiveTab] = useState("invoices");
  const [searchQuery, setSearchQuery] = useState("");
  const [viewMode, setViewMode] = useState("list");
  const [loading, setLoading] = useState(false);
  const [folderHistory, setFolderHistory] = useState([]);
  const windowWidth = useWindowWidth();
  const baseFolder = `UserData/${user?.email}/documents/companyData/${activeCompanyId}/invoices/`;
  useEffect(() => {
    if (!companyLoading && activeCompanyId) {
      setCurrentFolder(baseFolder);
    }
  }, [companyLoading, activeCompanyId, user?.email]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Sorting state
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    if (currentFolder) {
      const fetchInvoices = async () => {
        const { data } = await getInvoices(currentFolder);
        setInvoices(data?.data);
      };
      fetchInvoices();
    }
  }, [currentFolder, getInvoices]);

  const handleTabClick = (tab) => {
    if (!loading) {
      setLoading(true);
      setActiveTab(tab);
      if (activeCompanyId) {
        const baseFolder = `UserData/${user?.email}/documents/companyData/${activeCompanyId}/invoices/`;
        setCurrentFolder(baseFolder);
        setFolderHistory([]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleFolderClick = (folderName) => {
    if (!loading) {
      setLoading(true);
      setFolderHistory([...folderHistory, currentFolder]);
      const newFolder = `${currentFolder}${folderName}/`;
      setCurrentFolder(newFolder);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleBackClick = () => {
    if (!loading && folderHistory.length > 0) {
      setLoading(true);
      const newFolderHistory = [...folderHistory];
      const previousFolder = newFolderHistory.pop();
      setFolderHistory(newFolderHistory);
      setCurrentFolder(previousFolder);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const sortAndPaginate = (items) => {
    const sortedItems = [...items].sort((a, b) => {
      if (sortField === "name") {
        return sortOrder === "asc"
          ? (a.name || "").localeCompare(b.name || "")
          : (b.name || "").localeCompare(a.name || "");
      } else if (sortField === "size") {
        return sortOrder === "asc"
          ? (a.size || 0) - (b.size || 0)
          : (b.size || 0) - (a.size || 0);
      } else if (sortField === "date") {
        return sortOrder === "asc"
          ? new Date(a.lastModified || 0) - new Date(b.lastModified || 0)
          : new Date(b.lastModified || 0) - new Date(a.lastModified || 0);
      }
      return 0;
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = sortedItems.slice(
      startIndex,
      startIndex + itemsPerPage
    );
    return paginatedItems;
  };
  const handleSortChange = (event, field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
    handleClose(event); // Make sure to pass the event here
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredFolders = sortAndPaginate(
    invoices?.folders?.filter(
      (folder) =>
        folder.name &&
        folder.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []
  );

  const filteredFiles = sortAndPaginate(
    invoices?.files?.filter(
      (file) =>
        file.filename &&
        file.filename.toLowerCase().includes(searchQuery.toLowerCase()) &&
        file.size > 0
    ) || []
  );

  const totalItems =
    (invoices?.folders?.length || 0) + (invoices?.files?.length || 0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  if (companyLoading || !activeCompanyId || !currentFolder) {
    return (
      <div className="d-flex justify-content-center align-items-end h-100 w-100 ">
        <ScaleLoader className="position-absolute top-50" color="#4c36d6" />
      </div>
    );
  }

  return (
    <invoiceComponent className="card container my-2">
      <div className="container">
        <div className="mt-3">
          <h5 className="m-0 document-heading">Invoices</h5>
          <p className="m-0 document-heading">
            Here you can find all your company invoices.
          </p>
        </div>
      </div>
      <div className="container">
        <input
          type="text"
          placeholder="Search invoices..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
        <div className="d-flex justify-content-between border align-items-center mt-3 py-2">
          <nav className="breadcrumb-nav">
            <button
              onClick={handleBackClick}
              disabled={folderHistory.length === 0 || loading}
            >
              &larr; Back
            </button>
            {currentFolder !== baseFolder &&
              currentFolder
                .replace(baseFolder, "")
                .split("/")
                .filter(Boolean)
                .map((crumb, index) => (
                  <span key={index}>
                    {crumb}
                    {index <
                      currentFolder
                        .replace(baseFolder, "")
                        .split("/")
                        .filter(Boolean).length -
                        1 && " / "}
                  </span>
                ))}
          </nav>
          <button
            className="view-button d-flex align-items-center border-0 bg-transparent"
            onClick={() => setViewMode(viewMode === "list" ? "grid" : "list")}
          >
            {viewMode === "list" ? <IoGrid /> : <FaThList />}
          </button>
        </div>

        {viewMode === "list" ? (
          <>
            {windowWidth >= 768 ? (
              <table className="file-table file-table_docu w-100">
                <thead>
                  <tr>
                    <th className="table-padding-left">Serial</th>
                    <th>Name</th>
                    <th>Size</th>
                    <th>Type</th>
                    <th>Last Modified</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFolders?.map((folder, index) => (
                    <tr
                      key={folder.name}
                      onClick={() => handleFolderClick(folder.name)}
                      className={loading ? "disabled" : ""}
                    >
                      <td className="table-padding-left">{index + 1}</td>
                      <td>
                        <i className="folders_styling2"></i>{" "}
                        <div className="filename">
                          {folder.name
                            .replace(currentFolder, "")
                            .replace(/\//g, "")}
                        </div>
                      </td>
                      <td>—</td>
                      <td>Folder</td>
                      <td>
                        {new Date(folder.lastModified).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                  {filteredFiles?.map((file, index) => (
                    <tr key={file.key}>
                      <td>
                        {index +
                          1 +
                          (filteredFolders ? filteredFolders.length : 0)}
                      </td>
                      <td>
                        <i className="fas fa-file text-primary folderFile"></i>{" "}
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.filename.replace(/\//g, "")}
                        </a>
                      </td>
                      <td>{file.size}</td>
                      <td>File</td>
                      <td>
                        {new Date(file.lastModified).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="file-table file-table_docu w-100">
                <thead>
                  <tr>
                    <th>Serial</th>
                    <th>Details</th>
                    {/* <th>Size</th>
                          <th>Type</th>
                          <th>Modified</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredFolders?.map((folder, index) => (
                    <tr
                      key={folder.name}
                      onClick={() => handleFolderClick(folder.name)}
                      className={loading ? "disabled" : ""}
                    >
                      <td>{index + 1}</td>
                      <td className="d-flex py-3 gap-1 justify-content-lg-start align-align-items-center">
                        <i className="folders_styling2"></i>{" "}
                        <div className="d-flex  align-items-center table-font-weight">
                          {folder.name
                            .replace(currentFolder, "")
                            .replace(/\//g, "")}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {filteredFiles?.map((file, index) => (
                    <tr key={file.key}>
                      <td>
                        {index +
                          1 +
                          (filteredFolders ? filteredFolders.length : 0)}
                      </td>
                      <td className="d-flex gap-1 justify-content-lg-start align-align-items-center">
                        <i className="fas fa-file text-primary files_style"></i>{" "}
                        <div className="d-flex flex-column justify-content-center file-table-margin">
                          <a
                            className="table-font-weight"
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.filename.replace(/\//g, "")}
                          </a>
                          <div className="d-flex gap-2">
                            <div>
                              {" "}
                              Modified :
                              {new Date(file.lastModified).toLocaleDateString()}
                            </div>
                            <div>Size : {file.size}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        ) : (
          <div className="grid-view row mt-4">
            {filteredFolders?.map((folder, index) => (
              <div className="col-lg-3 col-md-4 col-6" key={folder.name}>
                <div
                  className={`grid-item folder ${loading ? "disabled" : ""}`}
                  onClick={() => handleFolderClick(folder.name)}
                >
                  <i className="folders_styling"></i>
                  <span>
                    {folder.name.replace(currentFolder, "").replace(/\//g, "")}
                  </span>
                </div>
              </div>
            ))}
            {filteredFiles?.map((file, index) => (
              <div className="col-lg-3 col-md-4 col-6" key={file.key}>
                <div className="grid-item file">
                  <i className="fas fa-file text-primary files-styling"></i>
                  <span>
                    <a
                      className="file-name-text-size "
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.filename.replace(/\//g, "")}
                    </a>
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}

        {(isLoading || loading) && <p>Loading...</p>}
        {isError && <p>Error fetching invoices: {error?.message}</p>}
      </div>
      <div className="docu_pagi">
        {/* {(documentsLoading || loading) && <p>Loading...</p>} */}
        {error && <p>Error fetching contents: {error?.message}</p>}

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </invoiceComponent>
  );
};

export default Invoices;
