import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import avatarPng from "../../../assets/dashboards/avatar.png";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useGetCurrentUserQuery,
  useUpdatePasswordMutation,
} from "../../../redux/features/auth/authApi";

const AdminUpdatePassword = () => {
  const { data: userData, refetch } = useGetCurrentUserQuery();
  const user = userData?.data;
  const avatar = userData?.data?.avatar;
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [activeButton, setActiveButton] = useState("password");
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const validatePassword = (password) => {
    const uppercasePattern = /[A-Z]/;
    const specialCharacterPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const numericPattern = /\d/;

    let isValidPassword = true;
    let errorMessage = "";

    if (password.length < 8) {
      isValidPassword = false;
      errorMessage = "Password must be at least 8 characters long";
    } else if (!uppercasePattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one uppercase letter";
    } else if (!specialCharacterPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one special character";
    } else if (!numericPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one numeric digit";
    }

    setErrors((prev) => ({
      ...prev,
      password: isValidPassword ? null : errorMessage,
    }));
  };

  const validateConfirmPassword = (confirmPassword) => {
    const isValidConfirmPassword = confirmPassword === password;
    setErrors((prev) => ({
      ...prev,
      confirmPassword: isValidConfirmPassword ? null : "Passwords do not match",
    }));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e.target.value);
  };

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = {
      oldPassword: currentPassword,
      newPassword: password,
    };
    try {
      const result = await updatePassword(credentials).unwrap();
      console.log(result);
      if (result?.success === true) {
        toast.success("Password Reset Successful", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
      } else {
        toast.error("Failed to change password");
      }
    } catch (err) {
      toast.error("Failed to change password");
      console.error("Error updating password:", err);
    }
  };

  const isUpdateButtonDisabled =
    !currentPassword ||
    !password ||
    !confirmPassword ||
    Object.values(errors).some((error) => error !== null);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <expenseComponent>
      <div className="">
        <div className="profile-header">
          <div className="banner position-relative"></div>
          <div className="content d-flex align-items-center">
            <div className="profile-info">
              {avatar ? (
                <img
                  src={`${avatar}?${new Date().getTime()}`} // Add timestamp to force reload
                  alt="Profile"
                  className="profile-img position-absolute"
                />
              ) : (
                <img
                  src={avatarPng}
                  alt="Profile"
                  className="profile-img position-absolute"
                />
              )}
            </div>
            <div className=" d-flex flex-column gap-3   ">
              <h6 className="name m-0">
                {user?.firstName} {user?.lastName}
              </h6>
              <div className="location m-0">
                Joined: {new Date(user?.createdAt).toLocaleDateString()}
              </div>
              <div className="role-detector2 text-uppercase">
                Role: {user?.role}
              </div>
            </div>
          </div>
        </div>
        <div className="card my-4 p-4 container">
          <div className="row Id-row">
            <div className="col-md-3">
              <div className="profile_button mb-md-0 mb-3 d-md-block d-flex gap-1">
                <Link
                  className={`profilebtn ${
                    activeButton === "profile" ? "active" : ""
                  }`}
                  to="/admin/dashboards/adminProfileSetting"
                  onClick={() => handleButtonClick("profile")}
                >
                  Profile
                </Link>{" "}
                <br />
                <Link
                  className={`profilebtn ${
                    activeButton === "password" ? "active" : ""
                  }`}
                  to="/admin/dashboards/adminUpdatePassword"
                  onClick={() => handleButtonClick("password")}
                >
                  Password
                </Link>
              </div>
            </div>
            <div className="col-md-9 d-md-flex d-flex flex-md-row flex-column-reverse">
              <div className="col-md-8">
                <div className="edit_profile">
                  <div className="update_password">
                    <h4>Update Password</h4>
                    <form onSubmit={handleSubmit}>
                      <label htmlFor="currentPassword">Current Password:</label>
                      <div className="input-group">
                        <input
                          type={isCurrentPasswordVisible ? "text" : "password"}
                          className="form-control"
                          value={currentPassword}
                          onChange={handleCurrentPasswordChange}
                          required
                        />{" "}
                        <button
                          type="button"
                          className="btn btn-outline-secondary eye_btn"
                          onClick={toggleCurrentPasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={isCurrentPasswordVisible ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                      <label htmlFor="password">New Password:</label>
                      <div className="input-group">
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          className="form-control"
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary eye_btn"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={isPasswordVisible ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                      <label htmlFor="confirmPassword">
                        Confirm New Password:
                      </label>
                      <div className="input-group">
                        <input
                          type={isConfirmPasswordVisible ? "text" : "password"}
                          className="form-control"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary eye_btn"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                      {errors.confirmPassword && (
                        <span className="text-danger">
                          {" "}
                          {errors.confirmPassword}
                        </span>
                      )}
                      {errors.password && (
                        <span className="text-danger"> {errors.password}</span>
                      )}
                      <div className="text-muted" style={{ fontSize: "12px" }}>
                        (Minimum 8 characters with one uppercase, one number,
                        and one special character. Example: P@ss1234)
                      </div>
                      <button
                        disabled={isUpdateButtonDisabled}
                        className={`btn  my-2  py-1  px-4 btn_up update-profile-button btn-primary${
                          isUpdateButtonDisabled ? " disabled" : ""
                        }`}
                        type="submit"
                      >
                        Update Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-4 justify-content-center gap-2">
                <div className=" d-flex justify-content-center align-items-center ">
                  <iframe
                    title="lottie"
                    src="https://lottie.host/embed/1f8b6006-1c0d-4437-a4d9-0fdd711b537c/6JnettE4OX.json"
                    style={{ border: "none", width: "100%", height: "100%" }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </expenseComponent>
  );
};

export default AdminUpdatePassword;
