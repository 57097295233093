import baseApi from "./baseApi";

const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    isPaid: builder.query({
      query: (company_id) => `/company/is-paid/${company_id}`,
      providesTags: ["company", "user"],
    }),
    getAllCompany: builder.query({
      query: () => `/company/get-all-company`,
      providesTags: ["company", "user"],
    }),
    getAllCompanyWithFilter: builder.query({
      query: () => `/company/get-all-company-with-filter`,
      providesTags: ["company", "user"],
    }),
    getSingleCompany: builder.query({
      query: (company_id) => `/company/get-single-company/${company_id}`,
      providesTags: ["company", "user"],
    }),
    registerCompany: builder.mutation({
      query: (cleanData) => ({
        url: "/company/register-company",
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["user", "company"],
    }),

    editBasicCompanyInformation: builder.mutation({
      query: (cleanData) => ({
        url: "/company/edit-basic-company-information",
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["bank", "company"],
    }),
    patchActiveBank: builder.mutation({
      query: (cleanData) => ({
        url: "/company/active-bank-info",
        method: "PATCH",
        body: cleanData,
      }),
      invalidatesTags: ["bank", "company"],
    }),
    updateBankStatus: builder.mutation({
      query: (cleanData) => ({
        url: "/company/changes-bank-status",
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["bank", "company"],
    }),
    VerifyCompany: builder.mutation({
      query: (cleanData) => ({
        url: "/company/verify-company",
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["company"],
    }),

    addNewDirector: builder.mutation({
      query: (cleanData) => ({
        url: "/company/add-new-director",
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["company"],
    }),

    editDirector: builder.mutation({
      query: (cleanData) => ({
        url: "/company/edit-existing-director",
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["company"],
    }),
    replaceShareholderAndDirectors: builder.mutation({
      query: (cleanData) => ({
        url: "/company/replace-shareholder-directors",
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["company"],
    }),
  }),
});

export const {
  useRegisterCompanyMutation,
  useIsPaidQuery,
  useGetAllCompanyQuery,
  useGetAllCompanyWithFilterQuery,
  useGetSingleCompanyQuery,
  useVerifyCompanyMutation,
  useEditBasicCompanyInformationMutation,
  useDeleteSingleDirectorMutation,

  useEditDirectorMutation,
  useReplaceShareholderAndDirectorsMutation,
  usePatchActiveBankMutation,
  useUpdateBankStatusMutation,
} = companyApi;
