import { Link } from "react-router-dom";
import "../login/loginsignup.css";
import {
  useGetSingleCompanyQuery,
  useIsPaidQuery,
} from "../../redux/api/companyApi";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApi";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import { useGetBankDetailsQuery } from "../../redux/features/banks/bankApi";
import Notification from "../../ui/Notification";
import ProvideData from "./ProvideData";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { MdVerified } from "react-icons/md";
import BankStatusDescription from "./User/UserUi/BankStatusDescription";

const Dashboards = () => {
  const { data: userData } = useGetCurrentUserQuery();
  const user = userData?.data;
  const activeCompany = user?.activeCompany;
  const { data: companyInfo } = useUserActiveCompanyQuery();
  const companyInformation = companyInfo?.data;

  const {
    _id,
    company_name,
    explanation_of_name,
    about,

    number_of_share,
    company_email,
    company_phone_number,
    company_country_code,
    address_line_one,

    directors,
    shareholders,
    createdAt,
    paidUpCapital,
    paidHistory,
    selected_bank,
    bank_information,
    payment_information,
    isVerified,
  } = companyInformation || {};

  const { data: paidStatus } = useIsPaidQuery(activeCompany);
  const isPaid = paidStatus?.data;

  const { data: companySecondFetchData } =
    useGetSingleCompanyQuery(activeCompany);
  const isFilesProvided = companySecondFetchData?.data?.isFilesProvided;

  const { data: bankData } = useGetBankDetailsQuery(
    bank_information?.selected_bank
  );
  const bank_data = bankData?.data;

  return (
    <div className="">
      <div className="px-4">
        <div
          style={{ margin: "30px 0px 20px 0px" }}
          className="index-name-and-contact d-flex justify-content-between align-items-center p-2"
        >
          <div className="">
            <h1 className="user_dash_name">
              Welcome Back, {user?.firstName + " " + user?.lastName} 🤝
            </h1>
            <div>This is your company's dashboard</div>
          </div>
          <div className="d-flex  justify-content-center align-items-center">
            <div>
              <Notification />
            </div>
            <div className="btn-1_dash">
              <Link to="">What's new</Link>
            </div>
            <div className="btn-1_dash btn-2_dash">
              <Link to="https://wa.me/message/P2LNKYIQJZHHA1">
                {" "}
                Join Whatsapp Community
              </Link>
            </div>
          </div>
        </div>
        

        <div>
          <div className="d-flex  index-flex-direction">
            <div className="col-md-8 pe-2">
              {isPaid === true ? (
                <div>
                  <div className="welcome_dashbaord">
                    <h5 className="dashboardleft_h">
                    Welcome to the Dashboard 👋 
                    </h5>
                    <p>
                      We're currently redesigning our platform. We're working
                      hard to provide a better experience and more features.{" "}
                      <Link to="https://wa.me/message/P2LNKYIQJZHHA1">
                        Please let us know your suggestions on Whatsapp.
                      </Link>
                    </p>
                  </div>

                  {isFilesProvided === false ? (
                    <div className="mt-5">
                      <ProvideData />
                    </div>
                  ) : (
                    <div className="card mt-3">
                      <div className="card">
                        <div className="m-3">
                          <div>
                            <h3 className="text-black m-0 p-0 d-flex align-items-center">
                              <div
                                style={{ overflowWrap: "anywhere" }}
                                className="d-flex gap-1 align-items-center justify-content-center"
                              >
                                <div >{company_name} </div>
                                {payment_information?.paidStatus === true && (
                                  <div className="d-flex gap-1 align-items-center justify-content-center">
                                    <Tooltip
                                      className="d-flex align-items-center"
                                      title="Company is verified"
                                      placement="top"
                                    >
                                      {isVerified && (
                                        <>
                                          <div className="d-flex align-items-center">
                                            <Tooltip
                                              title="Company is verified by admin ✅"
                                              placement="top"
                                            >
                                              <div className="d-flex align-items-center">
                                                <MdVerified className=" text-primary" />
                                              </div>
                                            </Tooltip>
                                          </div>
                                          <div className="activeCompany-small-text2">
                                            Verified &
                                          </div>
                                        </>
                                      )}
                                    </Tooltip>
                                  </div>
                                )}
                              </div>

                              <span className="activeCompany-small-text">
                                Active
                              </span>
                            </h3>
                            <p className="m-0 p-0 data_p_index">{_id} 🟢</p>
                          </div>
                          <table className="table-responsive table table-striped  c_director_table in_t">
                            <tbody className="bg-primary table_index c_profile_table c_director_table in_t ">
                              <tr className="table-mobile-responsive-row">
                                <th scope="row company_table_head_data2 ">
                                  Official Name
                                </th>
                                <td className="overflow-direct-wrapper company_table_body_data2">
                                  {company_name}
                                </td>
                              </tr>
                              <tr className="table-mobile-responsive-row">
                                <th scope="row company_table_head_data2 ">
                                  Registration Number
                                </th>
                                <td className="company_table_body_data2">
                                  {_id}
                                </td>
                              </tr>
                              <tr className="table-mobile-responsive-row">
                                <th scope="row company_table_head_data2 ">
                                  Incorporation Date
                                </th>
                                <td className="company_table_body_data2">
                                  {" "}
                                  {new Date(createdAt).toLocaleDateString()}
                                </td>
                              </tr>
                              <tr className="table-mobile-responsive-row ">
                                <th scope="row company_table_head_data2 ">
                                  Company Secretary
                                </th>
                                <td className="company_table_body_data2">
                                  {directors?.map((director) => (
                                    <div
                                      style={{
                                        borderBottom: "1px",
                                        paddingBottom: "6px",
                                      }}
                                      key={director}
                                      className="overflow-direct-wrapper"
                                    >
                                      {director.full_name} <br />
                                      <div
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "13px",
                                          textTransform: "uppercaseF",
                                        }}
                                      >
                                        Role: {director.role_of_directors}
                                      </div>
                                    </div>
                                  ))}
                                </td>
                              </tr>

                              <tr className="table-mobile-responsive-row">
                                <th scope="row company_table_head_data2 ">
                                  Total Share
                                </th>
                                <td className="company_table_body_data2">
                                  ${number_of_share}
                                </td>
                              </tr>
                              <tr className="table-mobile-responsive-row">
                                <th scope="row company_table_head_data2 ">
                                  Business Address
                                </th>
                                <td className="overflow-direct-wrapper company_table_body_data2">
                                  {address_line_one}
                                </td>
                              </tr>

                              {bank_information?.selected_bank ? (
                                <tr className="table-mobile-responsive-row">
                                  <th scope="row company_table_head_data2 ">
                                    Selected Bank
                                  </th>
                                  <td>
                                    <div className="d-flex gap-2 align-items-center">
                                      {bank_data?.bank_name} {""}
                                      {bank_information.bank_status ===
                                        "pending" && (
                                        <span className="bank-status-button-pending d-flex justify-content-center align-items-center gap-2">
                                          Pending{" "}
                                          <Tooltip title="Bank account pending for approval.">
                                            <InfoIcon
                                              sx={{
                                                width: "16px",
                                                height: "16px",
                                                color: "#050f26",
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      )}
                                      {bank_information.bank_status ===
                                        "processing" && (
                                        <span className="bank-status-button-processing d-flex justify-content-center align-items-center gap-2">
                                          Processing{" "}
                                          <Tooltip title="Bank account processing">
                                            <InfoIcon
                                              sx={{
                                                width: "16px",
                                                height: "16px",
                                                color: "#050f26",
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      )}
                                      {bank_information.bank_status ===
                                        "active" && (
                                        <span className="activeCompany-small-text d-flex justify-content-center align-items-center gap-2">
                                          Active{" "}
                                          <Tooltip title="Bank account active">
                                            <InfoIcon
                                              sx={{
                                                width: "16px",
                                                height: "16px",
                                                color: "#050f26",
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-md-none d-block">
                          <BankStatusDescription />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="bg-company-list p-4 border border-1 shadow-sm">
                    <h4>Complete the onboarding form</h4>
                    <p>
                      You have not yet completed all details for the
                      incorporation of your company.
                    </p>
                    <Link to="/signup/selectPlan" className="">
                      <button className="btn btn-primary">
                        Continue your incorporation request →
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4 mb-md-0 mb-3 ps-2 index-flex-right-sight">
              {isFilesProvided === false || isPaid === false ? null : (
                <div className="document_billing w-100 d-flex gap-1">
                  <div className="w-50 btn-1_dash ">
                    <Link to="/dashboards/documents"> Documents</Link>
                  </div>
                  <div className="btn-1_dash w-50">
                    <Link to="/dashboards/company/profile">Company</Link>
                  </div>
                </div>
              )}
              <div
                className={`lottie_index-dashboards  ${
                  isPaid === false || isFilesProvided === false ? "mt-0" : ""
                } `}
              >
                <iframe src="https://lottie.host/embed/507915dd-a83b-4950-b6e7-de4677483832/7CYxaH20U9.json"></iframe>
              </div>
              {isPaid === true && isFilesProvided === true && (
                <div className="d-md-block d-none">
                  <BankStatusDescription />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboards;
