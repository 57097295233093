import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useEditDirectorMutation } from "../../../../redux/api/companyApi";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateDirector } from "../../../../redux/features/company/nonPersistCompany";

export default function UpdateDirector({
  director,
  company_id,
  toggleFormVisibility,
}) {
  const dispatch = useDispatch();
  const [editDirector, { isLoading }] = useEditDirectorMutation();
  const [form, setForm] = useState({
    fullName: director.full_name || "",
    email: director.directors_email || "",
    phone: director.directors_phone_number || "",
    countryCode: director.directors_country_code
      ? {
          value: director.directors_country_code,
          label: director.directors_country_code,
        }
      : null,
    role: director.role_of_directors || "",
    country: director.directors_country_name || "",
    emailError: "",
  });

  const [initialForm, setInitialForm] = useState({ ...form });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+880", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" },
  ];

  useEffect(() => {
    checkFormValidity();
  }, [form]);

  const handleInputChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
      emailError:
        name === "email" ? validateEmail(value) : prevState.emailError,
    }));
  };

  const handlePhoneChange = (selectedOption) => {
    handleInputChange("countryCode", selectedOption);
  };

  const handleSubmit = async () => {
    if (!isFormValid) {
      return;
    }

    const cleanData = {
      full_name: form.fullName,
      directors_email: form.email,
      directors_country_code: form.countryCode ? form.countryCode.value : "",
      directors_phone_number: form.phone,
      role_of_directors: form.role,
      directors_country_name: form.country,
    };

    dispatch(updateDirector(cleanData));
    toggleFormVisibility(director.directors_email);
    // const { data, statusCode } = await editDirector(cleanData).unwrap();
    // if (statusCode === 200) {
    //   toast.success(data, {
    //     duration: 2500,
    //     style: { background: "#050F26" },
    //     className: "text-white",
    //   });
    //   toggleFormVisibility(director._id);
    // } else {
    //   toast.error("Failed to delete director");
    // }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email address is required";
    } else if (!emailPattern.test(email)) {
      return "Invalid email address";
    }
    return "";
  };

  const checkFormValidity = () => {
    const isValid =
      form.fullName.trim() &&
      form.country.trim() &&
      form.email.trim() &&
      !form.emailError &&
      form.phone.trim() &&
      form.role.trim();
    setIsFormValid(isValid);
    checkFormChanges();
  };

  const checkFormChanges = () => {
    const isChanged =
      form.fullName !== initialForm.fullName ||
      form.email !== initialForm.email ||
      form.phone !== initialForm.phone ||
      (form.countryCode && form.countryCode.value) !==
        initialForm.countryCode.value ||
      form.role !== initialForm.role ||
      form.country !== initialForm.country;

    setIsFormChanged(isChanged);
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100 step_first_top">
      <div className="p-3 mb-5 w-100 m-3 singleDirectorCard">
        <form>
          <div className="mb-3">
            <label htmlFor="fullName">Full name as per IC/Passport</label>
            <input
              type="text"
              name="fullName"
              placeholder="Full name as per IC"
              className="form-control rounded-0"
              value={form.fullName}
              onChange={(e) => handleInputChange("fullName", e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email">Email address</label>
            <br />
            <input
              type="email"
              name="email"
              placeholder="Email address"
              className="form-control rounded-0"
              value={form.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
            {form.emailError && (
              <div className="text-danger">{form.emailError}</div>
            )}
            <small>
              Please ensure this is the actual email of the individual! <br />
              This will be the email address used for SSM & Company matters
            </small>
          </div>

          <div className="mb-3">
            <label htmlFor="phone">
              <strong>Phone</strong>
            </label>
            <div className="d-flex align-items-center gap-1">
              <div className="phone-box-selector phone_box rounded-2 gap-1">
                <Select
                  value={form.countryCode}
                  onChange={handlePhoneChange}
                  options={countryCodes}
                  className="mr-2"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "130px",
                    }),
                  }}
                />
              </div>
              <input
                type="number"
                placeholder={
                  form.countryCode
                    ? `${form.countryCode.placeholder}`
                    : "Enter Your Phone Number"
                }
                value={form.phone}
                onChange={(e) => handleInputChange("phone", e.target.value)}
                className="form-control rounded-0"
              />
            </div>
            <small></small>
          </div>
          <small>
            Please ensure this is the actual phone number of the individual!
          </small>

          <div className="mb-3">
            <label htmlFor="role">Role of Director</label>
            <div className="d-flex align-items-center">
              <input
                type="text"
                name="role"
                placeholder="Role of Director"
                className="form-control rounded-0"
                value={form.role}
                onChange={(e) => handleInputChange("role", e.target.value)}
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="country">
              Which country does this Director live?
            </label>{" "}
            <br />
            <select
              name="country"
              id="country"
              className="countryselect py-2"
              value={form.country}
              onChange={(e) => handleInputChange("country", e.target.value)}
            >
              <option value="">Select Country</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="USA">USA</option>
            </select>
          </div>
        </form>
        <button
          className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
          onClick={handleSubmit}
          disabled={!isFormValid || !isFormChanged}
        >
          <strong>Update Director Info</strong>
        </button>
      </div>
    </div>
  );
}
