import socket from "../../socket/socket";
import { setUser } from "../features/auth/authSlice";
import { setFiles } from "../features/files/fileSlice";

let isListenersSetUp = false;

const socketMiddleware = (store) => {
  if (!isListenersSetUp) {
    socket.on("connect", () => {
      console.log("Socket connected");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    // Example socket event listener
    socket.on("user:update", (user) => {
      store.dispatch(setUser({ user, token: store.getState().auth.token }));
    });

    socket.on("user_documents_fetched", (data) => {
      console.log(data);
      store.dispatch(setFiles({ files: data }));
    });

    isListenersSetUp = true;
  }

  return (next) => (action) => {
    // Handle specific actions if needed
    return next(action);
  };
};

export default socketMiddleware;
