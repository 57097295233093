// Banks.js
import React from "react";
import { Link } from "react-router-dom";
import { useGetAllBankQuery } from "../../redux/features/banks/bankApi";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import BankInformationBankPage from "./User/UserUi/BankInformationBankPage";

const Banks = () => {
  const { data: bankData, isLoading } = useGetAllBankQuery();
  const banks = bankData?.data;
  const { data: singleCompany } = useUserActiveCompanyQuery();
  const companyInformation = singleCompany?.data;

  const selectedBankId = companyInformation?.bank_information?.selected_bank;
  const bankStatus = companyInformation?.bank_information?.bank_status;
  const shortDescription = (description, limit) => {
    if (description.length > limit) {
      return description.substring(0, limit) + "...";
    }
    return description;
  };

  return (
    <div className="invoices">
      <div className="">
        <div className="p-4">
          <div className="row Id-row">
            <div className="text-md-start text-center">
              <h3>Banks</h3>
            </div>
            <BankInformationBankPage bankStatus={bankStatus} />
            <p>Choose the right bank for your company</p>
            {banks?.map((bank) => (
              <div key={bank._id} className="col-md-6 py-2 ">
                <Link
                  to={`/dashboards/banks/${bank._id}`}
                  className="bank_sec d-flex align-items-center text-decoration-none p-3"
                >
                  <div className="">
                    <div className="bank_logo">
                      <img src={bank.image_src} className="bank_img" alt="" />
                    </div>
                    <div className="bank_text">
                      <div className="d-flex justify-content-between ">
                        <h5>{bank.bank_name}</h5>
                        <div>
                          {bank._id === selectedBankId ? (
                            <>
                              {bankStatus === "pending" && (
                                <span className="pendingBank-small-text">
                                  Pending
                                </span>
                              )}
                              {bankStatus === "processing" && (
                                <span className="processingBank-small-text">
                                  Processing
                                </span>
                              )}
                              {bankStatus === "active" && (
                                <span className="activeBank-small-text">
                                  Active
                                </span>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <p>{shortDescription(bank.description, 200)}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banks;
