import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ShareHolderModal from "../../../signUp/components/shareHolderModal";
import { useReplaceShareholderAndDirectorsMutation } from "../../../../redux/api/companyApi";
import toast from "react-hot-toast";
import useWindowWidth from "../../../../ui/useWindowWidth";

export default function AddNewShareHolderEdit({ shareholders, company_id }) {
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Saving Data");
  const [replaceShareholderAndDirectors, { isLoading }] =
    useReplaceShareholderAndDirectorsMutation();
  const [singleShareholder, setSingleShareholder] = useState(
    shareholders.map((director) => ({
      shareholder_type: "individual",
      shareholder_name: director?.full_name,

      shareholder_email: director?.directors_email,
      shareholder_country_code: director?.directors_country_code,
      shareholder_phone_number: director?.directors_phone_number,

      share: "",
    }))
  );
  const [shareCount, setShareCount] = useState(1000);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isShareFilled, setIsShareFilled] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    let totalShares = 0;
    singleShareholder.forEach((shareholder) => {
      if (shareholder.share) {
        totalShares += parseInt(shareholder.share);
      }
    });

    const newShareCount = 1000 - totalShares;

    if (newShareCount < 0) {
      setShareCount(0);
      setErrorMessage("Share count exceeded. Please adjust the shares.");
    } else {
      setShareCount(newShareCount);
      setErrorMessage("");
    }

    const allSharesFilled = singleShareholder.every(
      (shareholder) => shareholder.share && shareholder.share.trim() !== ""
    );
    setIsShareFilled(allSharesFilled);
  }, [singleShareholder]);

  const handleSubmit = async () => {
    setLoading(true);
    setButtonText("Saving");
    if (!isShareFilled) {
      setErrorMessage("Please fill all share fields.");
      return;
    }

    const cleanData = {
      company_id: company_id,
      directors: shareholders,
      shareholders: singleShareholder,
    };
    console.log(cleanData);
    setTimeout(async () => {
      try {
        const { data, statusCode } = await replaceShareholderAndDirectors(
          cleanData
        ).unwrap();
        if (statusCode === 200) {
          toast.success("Shareholders updated successfully.", {
            duration: 2500,
            style: { background: "#050F26" },
            className: "text-white",
          });
        }
      } catch (error) {
        toast.error("An error occurred while saving the data.", {
          duration: 2500,
          style: { background: "#E74C3C" },
          className: "text-white",
        });
      } finally {
        setLoading(false);
        setButtonText("Save");
      }
    }, 1000);
  };

  const handleShare = (event, email) => {
    const addedShare = event.target.value;
    const index = singleShareholder.findIndex(
      (item) => item.shareholder_email === email
    );
    if (index !== -1) {
      const updatedShareholder = {
        ...singleShareholder[index],
        share: addedShare,
      };
      const updatedShareholders = [...singleShareholder];
      updatedShareholders[index] = updatedShareholder;
      setSingleShareholder(updatedShareholders);
    }
  };

  return (
    <div>
      <ShareHolderModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        singleShareholder={singleShareholder}
        setSingleShareholder={setSingleShareholder}
      />
      <div>
        {windowWidth >= 768 ? (
          <table className="table singleDirector table-responsive">
            <thead>
              <tr>
                <th style={{ width: "60%" }} scope="col">
                  Details
                </th>
                <th scope="col">Shares</th>
              </tr>
            </thead>
            <tbody>
              {singleShareholder?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div>
                      <strong>Name:</strong> {item.shareholder_name}
                    </div>
                    <div>
                      <strong>Country:</strong> {item.shareholder_country_code}
                    </div>

                    <div>
                      <strong>Email:</strong> {item.shareholder_email}
                    </div>
                    <div>
                      <strong>Phone:</strong> {item.shareholder_country_code}
                      {item.shareholder_phone_number}
                    </div>
                  </td>
                  <td className="">
                    <input
                      type="number"
                      placeholder="Add share"
                      name="share"
                      value={item.share || ""}
                      onChange={(event) =>
                        handleShare(event, item.shareholder_email)
                      }
                      className="form-control rounded-0"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="table singleDirector table-responsive">
            <thead>
              <tr className="d-flex align-content-center w-100 justify-content-between">
                <th scope="col">Details</th>
                <th scope="col">Shares</th>
              </tr>
            </thead>
            <tbody>
              {singleShareholder?.map((item, index) => (
                <tr
                  key={index}
                  className="singleDirectorCard d-flex align-content-center w-md-100 justify-content-between"
                >
                  <td>
                    <div>
                      <strong>Name:</strong> {item.shareholder_name}
                    </div>
                    <div>
                      <strong>Country:</strong> {item.shareholder_country_name}
                    </div>
                    <div>
                      <strong>Role:</strong> {item.role_of_directors}
                    </div>
                    <div>
                      <strong>Appointed:</strong>{" "}
                      {new Date(item.createdAt).toLocaleDateString()}
                    </div>
                    <div>
                      <strong>Email:</strong> {item.shareholder_email}
                    </div>
                    <div>
                      <strong>Phone:</strong> {item.shareholder_country_code}
                      {item.shareholder_phone_number}
                    </div>
                  </td>
                  <td className="">
                    <input
                      type="number"
                      placeholder="Add share"
                      name="share"
                      value={item.share || ""}
                      onChange={(event) =>
                        handleShare(event, item.shareholder_email)
                      }
                      className="form-control rounded-0"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      <button
        onClick={handleSubmit}
        disabled={
          !isShareFilled ||
          singleShareholder.length === 0 ||
          shareCount !== 0 ||
          errorMessage !== "" ||
          loading
        }
        className={`btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1 `}
      >
        {!loading ? <strong>Save</strong> : <strong>Saving</strong>}
      </button>
    </div>
  );
}
