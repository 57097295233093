import React from "react";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import circlePng from "../../assets/dashboards/circle.png";

export default function CompanyShareholders() {
  const { data: companyInfo } = useUserActiveCompanyQuery();
  const company_information = companyInfo?.data;
  const { _id, company_name, shareholders, number_of_share } =
    company_information || {};

  return (
    <div className="">
      <div className="mt-3">
        <div className="mb-3">
          <staticsUserDetails>
            <div className="d-md-flex gap-3 justify-content-center align-items-center">
              <div className=" w-100   card bg-gradient-danger card-img-holder text-white">
                <div className="card-body">
                  <img src={circlePng} className="card-img-absolute" alt="" />
                  <h4 className="font-weight-normal mb-3">
                    Shareholders
                    <i className="mdi mdi-chart-line mdi-24px float-end" />
                  </h4>
                  <h2 className="mb-3">{shareholders?.length}</h2>
                </div>
              </div>

              <div className=" w-100 card bg-gradient-success card-img-holder text-white">
                <div className="card-body">
                  <img src={circlePng} className="card-img-absolute" alt="" />
                  <h4 className="font-weight-normal mb-3">
                    Total shares
                    <i className="mdi mdi-chart-line mdi-24px float-end" />
                  </h4>
                  <h2 className="mb-3">$1000</h2>
                </div>
              </div>
            </div>
          </staticsUserDetails>
        </div>
        <div className="card  p-4 my-2">
          <h4 className="text-black  m-0 p-0">Shareholders</h4>

          <p className="profile_c_p">These are the current shareholders</p>

          <div className="table-responsive mt-2">
            <table className="table table-striped ">
              <thead className="c_profile_table c_director_table">
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>ID</th>
                  <th>Shares</th>
                </tr>
              </thead>
              <tbody className="c_profile_table c_director_table">
                {shareholders?.map((shareholder) => (
                  <tr key={shareholder.id}>
                    <td>{shareholder.shareholder_name}</td>
                    <td>{shareholder.shareholder_email}</td>
                    <td>{shareholder._id}</td>
                    <td>${shareholder.share}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="card p-3 my-2 ">
          <h5>Share transactions</h5>
          <h6>The history of all share transactions</h6>
          <div className="table-responsive">
            <table className="table table-striped ">
              <thead className="c_profile_table c_director_table">
                <tr>
                  <th>No. </th>
                  <th className="table-heading-min-width">Transacted on</th>
                  <th>Type</th>
                  <th className="table-heading-min-width">Share Type</th>
                  <th className="table-heading-min-width">of Shares</th>
                  <th>To</th>
                </tr>
              </thead>
              <tbody>
                {shareholders?.map((shareholder, i) => (
                  <tr key={shareholder.id}>
                    <td>A0{i + 1}</td>
                    <td>
                      {new Date(shareholder.createdAt).toLocaleDateString()}
                    </td>
                    <td>{shareholder.shareholder_type}</td>
                    <td>{shareholder.shareholder_type}</td>
                    <td>{shareholder.share}</td>
                    <td>{shareholder.shareholder_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
}
