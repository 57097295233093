import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  company_information: null,
  allDirectors: [],
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      const { company } = action.payload;
      state.company_information = company;
    },
    removeCompany: (state) => {
      state.company_information = null;
    },
    setDirectors: (state, action) => {
      state.allDirectors = [...state.allDirectors, action.payload];
    },
  },
});

export const { setCompany, removeCompany, setDirectors } = companySlice.actions;

export default companySlice.reducer;
