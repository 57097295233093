import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import resetPasswordSvg from "../../assets/introflow/svg/13.svg";
import toast from "react-hot-toast";
import useAxiosSecure from "../../hooks/AxiosSecure";
function ResetPassword() {
  const { axiosSecure } = useAxiosSecure();
  const Navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const { accessToken } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosSecure.post("/users/reset-password", {
        password,
        accessToken,
      });

      if (response.status === 200) {
        setSuccessMessage("Password changed successfully");
        toast.success("Password Reset Successfull", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        Navigate("/");
      } else {
        toast.error("Failed to change password");
        const errorMessage = await response.message;
        setErrorMessage(errorMessage || "Password change failed");
      }
    } catch (error) {
      console.error("Failed to change password:", error);
      toast.error("Failed to change password");
      // Handle other error cases...
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validatePassword(event.target.value);
    setErrorMessage("");
  };

  const validatePassword = (password) => {
    const uppercasePattern = /[A-Z]/;
    const specialCharacterPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const numericPattern = /\d/;

    let isValidPassword = true;
    let errorMessage = "";

    if (password.length < 8) {
      isValidPassword = false;
      errorMessage = "Password must be at least 8 characters long";
    } else if (!uppercasePattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one uppercase letter";
    } else if (!specialCharacterPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one special character";
    } else if (!numericPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one numeric digit";
    }

    setErrors((prev) => ({
      ...prev,
      password: isValidPassword ? null : errorMessage,
    }));
  };

  const validateConfirmPassword = (confirmPassword) => {
    const isValidConfirmPassword = confirmPassword === password;
    setErrors((prev) => ({
      ...prev,
      confirmPassword: isValidConfirmPassword ? null : "Passwords do not match",
    }));
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    validateConfirmPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const isSignUpButtonDisabled =
    !password ||
    !confirmPassword ||
    Object.values(errors).some((error) => error !== null);

  return (
    <div className="">
      <div className="bg-intro-custom">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  src={resetPasswordSvg}
                  width={400}
                  className=" mt-5 custom-intro-hight "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h2 className="forgot_text">Reset password</h2>
                <p>
                  No worries, it happens to the best of us! Set a password and
                  confirm new for re login in Incorplink
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="password">
                      <strong>Password</strong>
                    </label>
                    <div className="input-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter Your Password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="form-control rounded-0"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {errors.password && (
                      <span className="text-danger"> {errors.password}</span>
                    )}
                    <div className="text-muted" style={{ fontSize: "12px" }}>
                      (Minimum 8 characters with one uppercase, one number, and
                      one special character. Example: P@ss1234)
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword">
                      <strong>Confirm Password</strong>
                    </label>
                    <div className="input-group">
                      <input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        placeholder="Confirm Your Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="form-control rounded-0"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {errors.confirmPassword && (
                      <span className="text-danger">
                        {" "}
                        {errors.confirmPassword}
                      </span>
                    )}
                  </div>
                  {errorMessage && (
                    <div className="text-danger">{errorMessage}</div>
                  )}
                  <button
                    disabled={isSignUpButtonDisabled}
                    type="submit"
                    className={`forgot_button w-100 ${
                      !isSignUpButtonDisabled ? "disabled" : ""
                    }`}
                  >
                    Reset Now
                  </button>
                  {successMessage && (
                    <div className="text-success">{successMessage}</div>
                  )}
                  <div className="text-center">
                    <n className=" mt-3">
                      Suddenly remembered? <Link to="/">Sign In</Link>
                    </n>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
