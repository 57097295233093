// import { io } from "socket.io-client";

// // const socket = io("http://localhost:5000", {
// const socket = io("https://qa.incorplink.com/incorplink-server", {
//   // const socket = io("https://qa.incorplink.com", {
//   withCredentials: true,

//   extraHeaders: async (headers, { getState }) => {
//     const token = await getState().auth.token;
//     if (token) {
//       // console.log("token exists", token);
//       headers.set("authorization", `Bearer ${token}`);
//     }
//     return headers;
//   },
// });

// export default socket;

// socket.js
import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const socket = io(SOCKET_URL, {
  autoConnect: false,
  withCredentials: true,
});

export default socket;
