import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import { Fab } from "@mui/material";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import UpdateDirector from "./updateDirector";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import EditOffSharpIcon from "@mui/icons-material/EditOffSharp";
import { useDispatch } from "react-redux";
import { deleteDirector } from "../../../../redux/features/company/nonPersistCompany";

export default function EditSingleDirector({ directors, company_id }) {
  const dispatch = useDispatch();
  const [anchorEls, setAnchorEls] = useState({});
  const [visibleForms, setVisibleForms] = useState({});

  const handleClick = (event, _id) => {
    setAnchorEls((prev) => ({ ...prev, [_id]: event.currentTarget }));
  };

  const handleClose = (_id) => {
    setAnchorEls((prev) => ({ ...prev, [_id]: null }));
  };

  const handleDeleteCompanyDirector = async (directors_email) => {
    dispatch(deleteDirector(directors_email));
    handleClose(directors_email);
  };

  const toggleFormVisibility = (directors_email) => {
    handleClose(directors_email);
    setTimeout(() => {
      setVisibleForms((prevVisible) => ({
        ...prevVisible,
        [directors_email]: !prevVisible[directors_email],
      }));
    }, 400); // Delay of 500 ms
  };

  return (
    <div>
      {directors?.length < 1 ? (
        <div>
          <h4 className="text-center">No directors yet</h4>
          <>Must have at least one director</>
        </div>
      ) : (
        <div className="singleDirector">
          {directors?.map((director) => {
            const { directors_email } = director;
            const open = Boolean(anchorEls[directors_email]);
            const isFormVisible = visibleForms[directors_email];

            return (
              <div
                className="d-flex edit-single-director-direction gap-3 justify-content-between align-items-center singleDirectorCard p-1 p-md-3"
                key={director.directors_email}
              >
                {isFormVisible ? (
                  <div>
                    <UpdateDirector
                      director={director}
                      company_id={company_id}
                      toggleFormVisibility={toggleFormVisibility}
                    />
                  </div>
                ) : (
                  <div className="singleDirectorPadding ">
                    <div>
                      <strong>Name:</strong> {director.full_name}
                    </div>
                    <div>
                      <div>
                        <strong>Email:</strong> {director.directors_email}
                      </div>
                    </div>
                    <div>
                      <strong>Phone:</strong> {director.directors_country_code}
                      {director.directors_phone_number}
                    </div>
                    <div>
                      <strong>Country:</strong>{" "}
                      {director.directors_country_name}
                    </div>
                    <div>
                      <strong>Appointed:</strong>{" "}
                      {new Date(director.createdAt).toLocaleDateString()}
                    </div>{" "}
                    <div>
                      <strong>Role:</strong> {director.role_of_directors}
                    </div>
                  </div>
                )}

                <Button
                  className=""
                  id={`demo-positioned-button-${directors_email}`}
                  aria-controls={
                    open ? `demo-positioned-menu-${directors_email}` : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(e) => handleClick(e, directors_email)}
                >
                  <Fab color="secondary" size="small" aria-label="edit">
                    <EditIcon />
                  </Fab>
                </Button>
                <Menu
                  id={`demo-positioned-menu-${directors_email}`}
                  aria-labelledby={`demo-positioned-button-${directors_email}`}
                  anchorEl={anchorEls[directors_email]}
                  open={open}
                  onClose={() => handleClose(director.directors_email)}
                >
                  <MenuItem
                    className="d-flex gap-1 align-items-center justify-content-between"
                    onClick={() =>
                      toggleFormVisibility(director.directors_email)
                    }
                  >
                    {visibleForms[directors_email] ? (
                      <>
                        Cancel{" "}
                        <EditOffSharpIcon size="small" sx={{ color: "red" }} />
                      </>
                    ) : (
                      <>
                        Edit{" "}
                        <EditSharpIcon size="small" sx={{ color: "green" }} />
                      </>
                    )}
                  </MenuItem>
                  <MenuItem
                    className="d-flex gap-1 align-items-center text-danger"
                    onClick={() =>
                      handleDeleteCompanyDirector(director.directors_email)
                    }
                  >
                    Delete <DisabledByDefaultIcon sx={{ color: "red" }} />
                  </MenuItem>
                </Menu>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
