import React, { useState, useEffect } from "react";
import Select from "react-select";

function IndividualShareholder({ setSingleShareholder, singleShareholder, isOpen, setIsOpen }) {
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fullNameError, setFullNameError] = useState(""); // Define fullNameError state
  const [formValid, setFormValid] = useState(false); // State to track overall form validity

  const [forms, setForms] = useState({
    fullName: "",
    email: "",
    phone: "",
    countryCode: null,
  });
  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+88", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" },
  ];

  useEffect(() => {
    // Check if all fields are filled up and no errors
    if (
      forms.fullName &&
      forms.email &&
      forms.phone &&
      !phoneError &&
      !emailError &&
      !fullNameError
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [
    forms.fullName,
    forms.email,
    forms.phone,
    phoneError,
    emailError,
    fullNameError,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = () => {
    const data = {
      shareholder_type: "individual",
      shareholder_name: forms.fullName,
      shareholder_email: forms.email,
      shareholder_country_code: forms.countryCode?.value,
      shareholder_phone_number: forms.phone,
      
    };
    setSingleShareholder((prevShareholders) => [...prevShareholders, data]);
  };


  // const handleSubmit = () => {
  //   // Check if the email already exists in the list of shareholders
  //   const emailExists = singleShareholder.some(
  //     (shareholder) => shareholder.shareholder_email === forms.email
  //   );
  
  //   if (emailExists) {
  //     // If the email already exists, set an error message
  //     setEmailError("Email already added as a shareholder.");
  //     return;
  //   }
  
  //   // If the email is unique, proceed to add the shareholder
  //   const data = {
  //     shareholder_type: "individual",
  //     shareholder_name: forms.fullName,
  //     shareholder_email: forms.email,
  //     shareholder_country_code: forms.countryCode?.value,
  //     shareholder_phone_number: forms.phone,
  //   };
  
  //   setSingleShareholder((prevShareholders) => [...prevShareholders, data]);
  //   // Reset form fields and errors
  //   setForms({
  //     fullName: "",
  //     email: "",
  //     phone: "",
  //     countryCode: null,
  //   });
  //   setEmailError("");
  // };


  const handlePhoneChange = (selectedOption) => {
    setForms((prevForms) => ({
      ...prevForms,
      countryCode: selectedOption,
      phone: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      // Validate email format
      const emailError = validateEmail(value);
      
      // Check if the email already exists in singleShareholder
      const emailExists = singleShareholder.some(
        (shareholder) => shareholder.shareholder_email === value
      );
      
      // If email already exists, set error message
      if (emailExists) {
        setEmailError("Email already added as a shareholder.");
      } else {
        setEmailError(emailError); // Set the email error message
      }
    }
    
    setForms((prevForms) => ({
      ...prevForms,
      [name]: value,
    }));
  };

  const validatePhone = () => {
    if (!forms.phone) {
      setPhoneError("Phone number is required");
    } else {
      setPhoneError("");
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email address is required";
    } else if (!emailPattern.test(email)) {
      return "Invalid email address";
    }
    return "";
  };


  const validateFullName = () => {
    if (!forms.fullName) {
      setFullNameError("Full name is required");
    } else {
      setFullNameError("");
    }
  };

  return (
    <div className="modal-index ">
      <form>
        <div className="mb-3 ">
          <label htmlFor="fullName">Full name as per IC/Passport</label>
          <input
            type="text"
            name="fullName"
            placeholder="Full name as per IC"
            className="form-control rounded-0"
            value={forms.fullName}
            onChange={handleChange}
            onBlur={validateFullName}
          />
          {fullNameError && <div className="text-danger">{fullNameError}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="email">Email address</label>
          <br />
          <input
            type="email"
            name="email"
            placeholder="Email address"
            className="form-control rounded-0"
            value={forms.email}
            onChange={handleChange} 
          
          />
          {emailError && <div className="text-danger">{emailError}</div>}
        </div>
        <p>This will be the email address used for SSM & Company matters</p>
        <div className="mb-3">
          <label htmlFor="phone">Phone</label>
          <div className="d-flex align-items-center gap-1">
            <div className=" phone-box-selector phone_box rounded-2">
              <Select
                value={forms.countryCode}
                onChange={handlePhoneChange}
                options={countryCodes}
                className="mr-2"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "130px",
                  }),
                }}
              />
            </div>
            <input
              type="number"
              placeholder={
                forms.countryCode
                  ? `${forms.countryCode.placeholder}`
                  : "Enter Your Phone Number"
              }
              value={forms.phone}
              onChange={handleChange}
              onBlur={validatePhone}
              name="phone"
              className="form-control rounded-0"
            />
          </div>
          {phoneError && <div className="text-danger">{phoneError}</div>}
        </div>
      </form>
      <button
        onClick={() => {
          handleSubmit();
          closeModal();
        }}
        className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
        disabled={!formValid} // Disable the button if formValid is false
      >
        <strong>Add shareholder</strong>
      </button>
    </div>
  );
}

export default IndividualShareholder;
