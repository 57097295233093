import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";

function createData(name, description, status) {
  return { name, description, status };
}

const BankInformationBankPage = ({ bankStatus }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const rows = [
    createData(
      "Pending",
      "After selection, the bank account needs to be approved by admin. In this phase, you will see pending status. You can change selection bank before approval. After approved you cannot select another bank.",
      "pending"
    ),
    createData(
      "Processing",
      "After approval by admin, your bank account will be processed. In this phase, bank documents and all the documents will be prepared. In this phase, you will see processing status. And you cannot change the selected bank.",
      "processing"
    ),
    createData(
      "Active",
      "After completing all the processes of opening a bank account by admin and approval, your bank account will be active. In this phase, you will see active status. Then you can use your bank account for your business.",
      "active"
    ),
  ];

  return (
    <div className="mb-3">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                align="center"
                colSpan={isMobile ? 1 : 3}
              >
                Bank Status Information
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isMobile ? (
              rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className="d-flex justify-content-center align-items-center mb-2">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          padding: "4px 13px",
                          fontWeight: "600",
                          fontSize: "12px",
                          color:
                            row.status === "active" ||
                            row.status === "processing"
                              ? "white"
                              : "#050f26",
                          backgroundColor:
                            row.status === "pending"
                              ? "rgb(198, 207, 16)"
                              : row.status === "processing"
                              ? "rgb(28, 49, 214)"
                              : "rgb(13, 184, 90)",
                          borderRadius: "5px",
                        }}
                      >
                        {row.name}
                        <Tooltip title={`Bank account ${row.status}`}>
                          <InfoIcon sx={{ width: "16px", height: "16px" }} />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center">
                      {row.description}
                    </div>
                    {bankStatus === row.status && (
                      <>
                        <div className="d-flex justify-content-center align-items-center mt-2 current-active-status">
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <div>Current Status</div>
                            <CheckCircleIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "white",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                {rows.map((row, index) => (
                  <TableCell
                    key={index}
                    style={{
                      width: "33.33%",
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center mb-2">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          padding: "4px 13px",
                          fontWeight: "600",
                          fontSize: "12px",
                          color:
                            row.status === "active" ||
                            row.status === "processing"
                              ? "white"
                              : "#050f26",
                          backgroundColor:
                            row.status === "pending"
                              ? "rgb(198, 207, 16)"
                              : row.status === "processing"
                              ? "rgb(28, 49, 214)"
                              : "rgb(13, 184, 90)",
                          borderRadius: "5px",
                        }}
                      >
                        {row.name}
                        <Tooltip title={`Bank account ${row.status}`}>
                          <InfoIcon sx={{ width: "16px", height: "16px" }} />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-center">
                      {row.description}
                    </div>
                    {bankStatus === row.status && (
                      <>
                        <div className="d-flex justify-content-center align-items-center mt-2 current-active-status">
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <div>Current Status</div>
                            <CheckCircleIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "white",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BankInformationBankPage;
