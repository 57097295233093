import React from "react";
import { Link } from "react-router-dom";
import "../login/loginsignup.css";
import submittedsuccessfullSvg from "../../assets/introflow/svg/10.svg";

const SubmittedSuccessfull = () => {
  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  src={submittedsuccessfullSvg}
                  width={400}
                  className=" mt-5 custom-intro-hight "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100">
              <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <div className="getstarted_face">
                  <h3 className="get_started_text">
                    Submitted Successfully 🎉
                  </h3>
                  <p>
                    You're one step closer to have{" "}
                    <strong className="incorp">incorplink</strong> incorporated!
                  </p>
                  <p>
                    <strong>So, what's next?</strong>
                  </p>
                  <div className="payment">
                    <strong>💵 Payment for Incorporation</strong>
                    <p>
                      Before we can proceed with the incorporation process, we
                      require payment to be made.
                    </p>
                  </div>
                  <div className="payment">
                    <strong>🕵️‍♂️ Identity Verification & Signing</strong>
                    <p>
                      Emails & Text messages will be send to all directors &
                      shareholders to complete the identity verification process
                      and sign the final documents.
                    </p>
                  </div>
                  <Link to="/signup/selectplan">
                    <button className="btn btn-success border w-100 rounded-0 text-decoration-none getstarted1">
                      <strong>💳 Go to payment page</strong>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmittedSuccessfull;
