import { Link } from "react-router-dom";
import { useGetSingleUserQuery } from "../redux/features/admin/adminApi";

const UserProfileHook = ({ _id }) => {
  const { data: user } = useGetSingleUserQuery(_id);

  return (
    <>
      {!user?.data ? (
        "User Deleted"
      ) : (
        <Link
          className={"nav-link text-primary"}
          to={`/admin/dashboards/userDetails/${_id}`}
        >
          <span>
            {user?.data?.firstName} {user?.data?.lastName}
          </span>
        </Link>
      )}
    </>
  );
};

export default UserProfileHook;
