import React, { useState, useEffect, useRef } from "react";
import "../../components/login/loginsignup.css";
import "../../components/dashboardsfiles/dashboard.css";
import logoSvg from "../../../src/assets/logo.svg";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
  FaUniversity,
  FaTags,
  FaSchool,
  FaUserCircle,
  FaFolderOpen,
  FaFile,
} from "react-icons/fa";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import {
  useGetUserAddedCompanyQuery,
  usePostActiveCompanyIdMutation,
} from "../../redux/api/userApi";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";
import {
  useGetSingleCompanyQuery,
  useIsPaidQuery,
} from "../../redux/api/companyApi";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApi";

const UserDashboard = () => {
  const { data: userData } = useGetCurrentUserQuery();
  const user = userData?.data;
  const banStatus = user?.banStatus; // Assume this is the correct field for ban status
  const isDeleted = user?.isDeleted;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const sidebarRef = useRef(null);
  const accountMenuRef = useRef(null);

  const activeCompany = user?.activeCompany;

  const { data: paidStatus } = useIsPaidQuery(activeCompany);
  const isPaid = paidStatus?.data;

  const { data: companySecondFetchData } =
    useGetSingleCompanyQuery(activeCompany);

  const isFilesProvided = companySecondFetchData?.data?.isFilesProvided;

  useEffect(() => {
    !isOpen && setIsAccountMenuOpen(false);
  }, [isOpen]);
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountMenuRef.current &&
        !accountMenuRef.current.contains(event.target) &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setIsAccountMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleAccountMenuMain = () => {
    if (isAccountMenuOpen === true) {
      setIsAccountMenuOpen(false);
    }
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const closeAccountMenu = () => {
    setIsAccountMenuOpen(false);
  };

  const location = useLocation();
  const isActive = (path) => {
    if (path === "company") {
      return location.pathname.startsWith("/dashboards/company");
    }
    return location.pathname.startsWith(`/dashboards/${path}`);
  };

  const isSidebarPage =
    [
      "/dashboards/index",
      "/dashboards/company",
      "/dashboards/documents",
      "/dashboards/invoices",
      "/dashboards/expenses",
      "/dashboards/statements",
      "/dashboards/accounting",
      "/dashboards/legal",
      "/dashboards/billing",
      "/dashboards/banks",
      "/dashboards/perks",
      "/dashboards/profile",
      "/dashboards/updatepassword",
      "/dashboards/account",
      "/dashboards/yourcompanies",
      "/dashboards/register",
      "/dashboards/company/profile",
      "/dashboards/company/directors",
      "/dashboards/company/shareholders",
    ].includes(location.pathname) ||
    location.pathname.match(/^\/dashboards\/banks\/\w+$/) ||
    location.pathname.match(/^\/dashboards\/paymentDetails\/\w+$/) ||
    location.pathname.match(/^\/dashboards\/editCompanyInformation\/\w+$/);

  const menuItem = [
    {
      path: "index",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "company",
      name: "Company",
      icon: <FaSchool />,
      subPaths: ["profile", "directors", "shareholders"],
    },
    {
      path: "documents",
      name: "Documents",
      icon: <FaFolderOpen />,
    },
    // {
    //   path: "invoices",
    //   name: "Invoices",
    //   icon: <FaFile />,
    // },
    // {
    //   path: "expenses",
    //   name: "Expense",
    //   icon: <FaRegChartBar />,
    // },
    // {
    //   path: "statements",
    //   name: "Statement",
    //   icon: <FaCommentAlt />,
    // },
    // {
    //   path: "accounting",
    //   name: "Accountings",
    //   icon: <FaShoppingBag />,
    // },
    {
      path: "legal",
      name: "Legal",
      icon: <FaThList />,
    },
    {
      path: "banks",
      name: "Banks",
      icon: <FaUniversity />,
    },
  ];

  const [userAddedCompany, setUserAddedCompany] = useState([]);

  const { data } = useGetUserAddedCompanyQuery();

  useEffect(() => {
    setUserAddedCompany(data?.data || []);
  }, [data]);

  const [postActiveCompany] = usePostActiveCompanyIdMutation();

  const handleSelectCompany = async (_id) => {
    try {
      const response = await postActiveCompany(_id);
      console.log("Data Saved Successfully:", response.data);
    } catch (error) {
      console.error("Error Saving Data:", error);
    }
  };

  const renderAccountMenu = () => (
    <div ref={accountMenuRef}>
      <div className="company-popup-menu">
        {userAddedCompany?.map((company) => (
          <div
            // onClick={() => handleSelectCompany(company._id)}
            className="hover-company-popup"
            key={company._id}
          >
            <div className="d-flex gap-2 justify-content-between align-item-center my-2 py-1 mx-2 ">
              <div className="text-white position-relative">
                <p className="avatar-text">D</p>
                <div className="companyAvatarInPopup bg-primary"></div>
              </div>
              <div className="userAddedCompanyList text-decoration-none d-flex flex-column justify-content-center align-items-start">
                <p className="text-decoration-none m-0 p-0 company-title-popup">
                  {company.company_name}
                </p>
                <p className="text-decoration-none m-0 p-0">{company._id}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <NavLink
        to="/dashboards/yourcompanies"
        className="account_menu_item"
        onClick={closeAccountMenu}
      >
        Your Companies
      </NavLink> */}
      {/* <NavLink
        to="/signup/intro"
        className="account_menu_item"
        onClick={closeAccountMenu}
      >
        Register New Company
      </NavLink> */}
      <NavLink
        to="/dashboards/profile"
        className="account_menu_item d-md-block d-none"
        onClick={closeAccountMenu}
      >
        Profile Settings
      </NavLink>
      <NavLink
        to="/dashboards/billing"
        className="account_menu_item d-md-block d-none"
        onClick={closeAccountMenu}
      >
        Billing
      </NavLink>
      <div onClick={() => dispatch(logout())}>
        <div
          className="account_menu_item d-md-block d-none"
          onClick={closeAccountMenu}
        >
          Logout
        </div>
      </div>
      <NavLink
        to="/dashboards/profile"
        className="account_menu_item d-block d-md-none"
        onClick={handleCombinedClick}
      >
        Profile Settings
      </NavLink>

      <NavLink
        to="/dashboards/billing"
        className="account_menu_item d-block d-md-none"
        onClick={handleCombinedClick}
      >
        Billing
      </NavLink>
      <div onClick={() => dispatch(logout())}>
        <div
          className="account_menu_item d-block d-md-none"
          onClick={handleCombinedClick}
        >
          Logout
        </div>
      </div>
    </div>
  );
  const handleCombinedClick = () => {
    closeAccountMenu();
    toggleSidebar();
    closeAccountMenu();
  };
  // If the user is banned, return a message instead of the dashboard
  if (banStatus || isDeleted) {
    return (
      <div className="banned-message">
        <h2>Your account has been deleted or banned.</h2>
        <p>Please contact support for further assistance.</p>
      </div>
    );
  }

  return (
    <div ref={sidebarRef}>
      <customNavbar>
        <div style={{ background: "#050f26" }}>
          <div className="d-flex align-items-center px-2 justify-content-between custom-transition">
            <div className="bars custom-transition">
              <FaBars onClick={toggleSidebar} />
            </div>
            <img className="w-25 z-3 h-25 logo" src={logoSvg} alt="" />
          </div>
        </div>
        <div className="mobile-sidebar-container">
          <div className="account_menu2  ">
            {isAccountMenuOpen && renderAccountMenu()}
          </div>
          {isSidebarPage && (
            <div
              style={{ width: isOpen ? "170px" : "0px" }}
              className=" sidebar-mobile"
            >
              <div>
                {menuItem.map((item, index) => (
                  <NavLink
                    to={`/dashboards/${item.path}`}
                    key={index}
                    className={`link ${isActive(item.path) ? "active" : ""}`}
                    onClick={(closeAccountMenu, toggleSidebar)}
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text "
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))}
              </div>
              <div className="link" onClick={toggleAccountMenu}>
                <div className="icon">
                  <FaUserCircle />
                </div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Account
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      </customNavbar>
      <div className="containerSide d-flex ">
        <div className="custom-transition main-sidebar">
          <div
            style={{ left: isOpen ? "153px" : "48px" }}
            className="account_menu"
          >
            {isAccountMenuOpen && renderAccountMenu()}
          </div>
          {isSidebarPage && (
            <div
              style={{ width: isOpen ? "150px" : "48px" }}
              className=" sidebar"
            >
              <div className="top_section custom-transition">
                {/* <img
                  style={{ display: isOpen ? "block" : "none" }}
                  src="https://digitxgroup.com/wp-content/uploads/2024/04/2.png"
                  className="sidebar_logo"
                  alt=""
                /> */}

                <img
                  style={{
                    display: "block",
                    width: "125px",
                    top: "-17px",
                    display: isOpen ? "block" : "none",
                  }}
                  className="z-3  logo"
                  src={logoSvg}
                  alt=""
                />
                <div
                  style={{
                    marginLeft: isOpen ? "-15px" : "0px",
                    padding: "10px 10px",
                  }}
                  className="bars custom-transition"
                >
                  <FaBars onClick={toggleSidebar} />
                </div>
              </div>
              <div>
                {menuItem.map((item, index) => (
                  <NavLink
                    to={isPaid || item.path === "index" ? item.path : "#"}
                    key={index}
                    className={`link  ${
                      !isPaid || (!isFilesProvided && item.path !== "index")
                        ? "disabled-sidebar"
                        : ""
                    }`}
                    activeClassName="active"
                    onClick={
                      (isPaid && isFilesProvided === true) ||
                      item.path === "index"
                        ? closeAccountMenu
                        : (e) => e.preventDefault()
                    }
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text "
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))}
              </div>
              <div className="link cursor-pointer" onClick={toggleAccountMenu}>
                <div className="icon">
                  <FaUserCircle />
                </div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Account
                </div>
              </div>
            </div>
          )}
        </div>
        <main
          className="custom-transition main-outlet"
          style={{ marginLeft: isOpen ? "150px" : "40px" }}
          onClick={toggleAccountMenuMain}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default UserDashboard;
