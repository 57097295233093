import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../login/loginsignup.css";
import selectPlanSvg from "../../assets/introflow/svg/11.svg";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { forwardRef } from "react";
import { useIntroContext } from "../../hooks/useIntroContext";
import useAxiosSecure from "../../hooks/AxiosSecure";
import toast from "react-hot-toast";
import { useMakePaymentMutation } from "../../redux/features/payment/paymentApi";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";

let MyCustomButton = forwardRef(function (props, ref) {
  return (
    <button
      className="bg-success text-white rounded-5 border-0 px-4 py-2"
      ref={ref}
      {...props}
    />
  );
});

const Selectplan = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [plans, setPlans] = useState([]);
  const [activeTab, setActiveTab] = useState("onetime");
  const [activeCard, setActiveCard] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { axiosSecure } = useAxiosSecure();
  const { data: companyInfo } = useUserActiveCompanyQuery();
  const company_information = companyInfo?.data;
  const navigate = useNavigate();
  const [makePayment, {}] = useMakePaymentMutation();
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axiosSecure.get("/company/service-plan");
        setPlans(response.data.data);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    fetchPlans();
    // fetchLastCompany();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedPlan(null);
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setActiveCard(plan.title);
  };

  const handleSubmit = () => {};

  const handlePayment = async () => {
    if (!selectedPlan) {
      toast.error("Please select a plan to proceed!");
      return;
    }
    const allInformations = {
      company_information,
      selected_plan: selectedPlan,
    };
    console.log(allInformations);
    try {
      const result = await makePayment(allInformations).unwrap();
      console.log(result);

      if (result?.url) {
        window.location.href = result.url;
      } else {
        console.log("Payment URL not found in response.");
      }
    } catch (err) {
      console.error("Payment error:", err);
    }
  };
  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const isPayButtonDisable = !termsAccepted;
  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row">
          <div className="col-md-5">
            {/* Left side content */}
            <div className="d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  src={selectPlanSvg}
                  width={400}
                  className="mt-5 intro-flow-img-custom-size custom-intro-hight"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            {/* Right side content */}
            <div className="bg-white d-flex justify-content-center align-items-center h-100">
              <div className="px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                {/* Main content */}
                <h3 className="get_started_text">
                  Incorporation & Company Secretarial Services
                </h3>
                <p>
                  We have one simple price for incorporation, so you just need
                  to select the right secretarial package for your needs. All
                  prices are what you see is what you pay. No surprises.
                </p>
                <div className="one-time-free">
                  <h4>Incorporation of USA 🚀</h4>
                  <p>
                    Everything you need to get incorporated. Includes the 10000
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "18px",
                        padding: "0px 2px",
                      }}
                    >
                      ৳
                    </span>
                    SSM fee.
                  </p>
                  <div className="rm1500">
                    <h3>
                      1599
                      <span
                        style={{
                          fontWeight: "800",
                          fontSize: "28px",
                          padding: "0px 2px",
                        }}
                      >
                        ৳
                      </span>
                    </h3>
                    <p>One-time fee</p>
                  </div>
                </div>
                <div>
                  <TabGroup>
                    <TabList as="aside">
                      <Tab
                        className={`btn ${
                          activeTab === "onetime"
                            ? "btn-success"
                            : "btn-outline-success"
                        } rounded-2 border-0 px-4`}
                        onClick={() => handleTabChange("onetime")}
                        as={MyCustomButton}
                      >
                        Onetime
                      </Tab>
                      <Tab
                        className={`btn ${
                          activeTab === "emi"
                            ? "btn-success"
                            : "btn-outline-success"
                        } rounded-2 border-0 px-4`}
                        onClick={() => handleTabChange("emi")}
                        as={MyCustomButton}
                      >
                        EMI
                      </Tab>
                    </TabList>
                    <TabPanels as="section">
                      <TabPanel>
                        <div>
                          <h1 className="text-black">Pricing</h1>
                          <p className="fs-500">
                            Plans that work for everyones
                          </p>
                        </div>

                        <priceCard>
                          <newPriceCard>
                            <div
                              style={{ marginRight: "27px" }}
                              className="d-md-flex gap-4 mb-4 "
                            >
                              {plans
                                .filter((plan) => plan.category === "onetime")
                                .map((plan, index) => (
                                  <div
                                    className={`card  col-md-6 md-mt-0 mt-2 ${
                                      activeCard === plan.title
                                        ? "btn-donate text-white"
                                        : "bg-outline-success"
                                    }  `}
                                    key={index}
                                  >
                                    {/* Card content */}
                                    <div onClick={() => handlePlanSelect(plan)}>
                                      <div className="card-header  header-basic">
                                        <h2 className="py-2 text-center">
                                          {plan.title}
                                        </h2>
                                      </div>
                                      <div className="p-4">
                                        <h4>
                                          Price BDT: {plan.price}
                                          <span
                                            style={{
                                              fontWeight: "800",
                                              fontSize: "28px",
                                              padding: "0px 2px",
                                            }}
                                          >
                                            ৳
                                          </span>
                                        </h4>
                                        <ul className="list-unstyled">
                                          {plan.description.map(
                                            (desc, descIndex) => (
                                              <li key={descIndex}>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  style={{
                                                    height: "25px",
                                                    marginRight: "5px",
                                                  }}
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                  ></path>
                                                </svg>
                                                {desc}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </newPriceCard>
                        </priceCard>
                      </TabPanel>
                      <TabPanel>
                        <div>
                          <h1 className="text-black">Pricing</h1>
                          <p className="fs-500">Plans that work for everyone</p>
                        </div>
                        <priceCard>
                          <newPriceCard>
                            <div
                              style={{ marginRight: "27px" }}
                              className="d-md-flex gap-4 mb-4"
                            >
                              {plans
                                .filter((plan) => plan.category === "emi")
                                .map((plan, index) => (
                                  <div
                                    className={` card col-md-6 md-mt-0 mt-2  ${
                                      activeCard === plan.title
                                        ? "btn-donate text-white"
                                        : "bg-outline-success"
                                    }  `}
                                    key={index}
                                  >
                                    {/* Card content */}
                                    <div onClick={() => handlePlanSelect(plan)}>
                                      <div className="card-header  header-basic">
                                        <h2 className="py-2 text-center">
                                          {plan.title}
                                        </h2>
                                      </div>
                                      <div className="p-4">
                                        <h4>
                                          Monthly EMI:{" "}
                                          {parseFloat(plan.price / 12).toFixed(
                                            2
                                          )}
                                          <span
                                            style={{
                                              fontWeight: "800",
                                              fontSize: "28px",
                                              padding: "0px 2px",
                                            }}
                                          >
                                            ৳
                                          </span>
                                        </h4>
                                        <p style={{ fontSize: "20px" }}>
                                          Total BDT: {plan.price}
                                          <span
                                            style={{
                                              fontWeight: "700",
                                              fontSize: "18px",
                                              padding: "0px 2px",
                                            }}
                                          >
                                            ৳
                                          </span>
                                        </p>
                                        <ul className="list-unstyled">
                                          {plan.description.map(
                                            (desc, descIndex) => (
                                              <li key={descIndex}>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  style={{
                                                    height: "25px",
                                                    marginRight: "5px",
                                                  }}
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M5 13l4 4L19 7"
                                                  ></path>
                                                </svg>
                                                {desc}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </newPriceCard>
                        </priceCard>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </div>
                {/* Button for next step */}
                <div className="d-flex flex-column  align-items-center">
                  <div className="">
                    <img
                      style={{ width: "50vw", height: "auto" }}
                      src="https://securepay.sslcommerz.com/public/image/SSLCommerz-Pay-With-logo-All-Size-05.png"
                      alt="ssl"
                    />
                  </div>
                  {/* <div className="">
                    <img
                      onClick={handlePayment}
                      style={{ width: "50vw", height: "auto" }}
                      src="https://securepay.sslcommerz.com/public/image/SSLCommerz-Pay-With-logo-All-Size-01.png"
                      alt="ssl"
                    />
                  </div> */}
                  <div className="mb-3">
                    <input
                      type="checkbox"
                      id="terms"
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                    />
                    <label htmlFor="terms" style={{ marginLeft: "10px" }}>
                      I have read and agree to the website's{" "}
                      <Link
                        className="text-decoration-none "
                        to="/termsAndConditions"
                      >
                        terms and conditions, privacy policy, refund policy
                      </Link>
                    </label>
                  </div>
                  <button
                    onClick={handlePayment}
                    // className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                    className={`btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1${
                      isPayButtonDisable ? "disabled" : ""
                    }`}
                    disabled={isPayButtonDisable}
                  >
                    <strong>Pay Now</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selectplan;
