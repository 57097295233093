/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export const IntroContext = createContext();

export const IntroProvider = ({ children }) => {
  const user = useSelector((state) => state?.auth?.user);
  const [companyData, setCompanyData] = useLocalStorage("companyData", {
    directors: [],
    bank_information: {
      selected_bank: null,
    },
  });
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [shareholderData, setShareholderData] = useState({});

  useEffect(() => {
    if (user) {
      const defaultDirector = {
        full_name: `${user.firstName} ${user.lastName}`,
        directors_email: user.email,
        directors_country_code: user.countryCode,
        directors_phone_number: user.phone,
        role_of_directors: "owner",
        directors_country_name: "Bangladesh",
      };

      setCompanyData((prevData) => ({
        ...prevData,
        directors: [defaultDirector],
      }));
    }
  }, [user]);

  const updateCompanyData = (data) => {
    setCompanyData((prevData) => {
      const newData = { ...prevData };
      for (const [key, value] of Object.entries(data)) {
        newData[key] = value;
      }
      return newData;
    });
  };

  const updateShareholderData = (data) => {
    setShareholderData((prevData) => {
      const newData = { ...prevData };
      for (const [key, value] of Object.entries(data)) {
        newData[key] = value;
      }
      return newData;
    });
  };

  const updateSelectedPlan = (data) => {
    setSelectedPlanData((prevData) => {
      const newData = { ...prevData };
      for (const [key, value] of Object.entries(data)) {
        newData[key] = value;
      }
      return newData;
    });
  };

  useEffect(() => {}, [companyData]);

  return (
    <IntroContext.Provider
      value={{
        companyData,
        updateCompanyData,
        shareholderData,
        updateShareholderData,
        updateSelectedPlan,
        selectedPlanData,
        defaultDirector: companyData.directors[0], // Access the default director from companyData
      }}
    >
      {children}
    </IntroContext.Provider>
  );
};

export default IntroProvider;
