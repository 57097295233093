import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useCurrentToken,
  useCurrentUser,
} from "../redux/features/auth/authSlice";
import socket from "../socket/socket";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const role = useSelector((state) => state?.auth?.role);

  const userSelector = useSelector(useCurrentUser);
  const user = userSelector?.user;
  const tokenSelector = useSelector(useCurrentToken);
  const token = tokenSelector?.token;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  const authInfo = {
    setLoading,
    loading,
    user,
    token,
    role,
  };

  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
