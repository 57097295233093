import baseApi from "../../api/baseApi";

const bankApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBank: builder.query({
      query: () => `/bank/get-all-banks`,
      providesTags: ["bank"],
    }),
    getBankDetails: builder.query({
      query: (bankId) => ({
        url: `/bank/get-bank-details/${bankId}`,
        method: "GET",
      }),
      providesTags: ["bank"],
    }),
  }),
});

export const { useGetAllBankQuery, useGetBankDetailsQuery } = bankApi;
