import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, description) {
  return { name, description };
}

const rows = [
  createData(
    <div>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "start",
          alignItems: "center",
          gap: "3px",
          fontSize: "12px",
          backgroundColor: "rgb(198, 207, 16)",
          borderRadius: "5px",
          padding: "4px 13px",
          fontWeight: "600",
        }}
        className=" "
      >
        Pending
        <Tooltip title="Bank account pendin">
          <InfoIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#050f26",
            }}
          />
        </Tooltip>
      </div>
    </div>,
    "After selection bank account it need to be approved by admin. In this phase you will see pending status. "
  ),
  createData(
    <div>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "start",
          alignItems: "center",
          gap: "3px",
          fontSize: "12px",
          color: "white",
          backgroundColor: "rgb(28, 49, 214)",
          borderRadius: "5px",
          padding: "4px 13px",
          fontWeight: "600",
        }}
        className=" "
      >
        Processing
        <Tooltip title="Bank account processing">
          <InfoIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "white",
            }}
          />
        </Tooltip>
      </div>
    </div>,
    "After approved by admin. Your bank account will be processed. In this phase you will see processing status. "
  ),
  createData(
    <div>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "start",
          alignItems: "center",
          gap: "3px",
          fontSize: "12px",
          backgroundColor: "rgb(13, 184, 90)",
          borderRadius: "5px",
          padding: "4px 13px",
          fontWeight: "600",
        }}
        className=" "
      >
        Active
        <Tooltip title="Bank account active">
          <InfoIcon
            sx={{
              width: "16px",
              height: "16px",
              color: "#050f26",
            }}
          />
        </Tooltip>
      </div>
    </div>,
    "After completed all the process. Your bank account will be active. In this phase you will see active status. "
  ),
];

export default function BankStatusDescription() {
  return (
    <div className="mt-4">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                align="center"
                colSpan={2}
              >
                Bank Status Information
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="col-md-2 py-2" component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "13px",
                  }}
                  className="col-md-10 py-2"
                  align="left"
                >
                  {row.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
