/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"; // Import eye icons
import "./loginsignup.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import toast, { Toaster } from "react-hot-toast";
import { useLoginMutation } from "../../redux/features/auth/authApi";
import { useDispatch } from "react-redux";
import {
  logout,
  setUser,
  updateIsVerified,
} from "../../redux/features/auth/authSlice";
import { useGetAllCompanyQuery } from "../../redux/api/companyApi";
import {
  removeCompany,
  setCompany,
} from "../../redux/features/company/companySlice";
import { setDirectors } from "../../redux/features/company/nonPersistCompany";

function Login() {
  const dispatch = useDispatch();

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(logout());
      dispatch(removeCompany());
      localStorage.removeItem("companyData");
    }
  }, [location, dispatch]);

  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Log in");
  const { data: companyDataCollection } = useGetAllCompanyQuery();
  const allCompany = companyDataCollection?.data;

  const handleInput = (event) => {
    const { name, value } = event.target;

    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: null }));
    if (name === "email" && value.trim() !== "") {
      validateEmail(value);
    } else if (name === "password") {
      validatePassword(value);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(email);
    setErrors((prev) => ({
      ...prev,
      email: isValidEmail ? null : "Invalid email address",
    }));
  };

  const validatePassword = (password) => {
    const uppercasePattern = /[A-Z]/;
    const specialCharacterPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const numericPattern = /\d/;

    let isValidPassword = true;
    let errorMessage = "";

    if (password.length < 8) {
      isValidPassword = false;
      errorMessage = "Password must be at least 8 characters long";
    } else if (!uppercasePattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one uppercase letter";
    } else if (!specialCharacterPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one special character";
    } else if (!numericPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one numeric digit";
    }

    setIsValid(isValidPassword);
    setErrors((prev) => ({
      ...prev,
      password: isValidPassword ? null : errorMessage,
    }));
  };

  const [login, { data, error }] = useLoginMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setButtonText("Logging in...");
    setTimeout(async () => {
      try {
        const response = await login(values).unwrap();
        const { user, accessToken } = response.data;
        const isVerified = user.isVerified;
        console.log(isVerified);
        dispatch(
          setUser({
            user,
            token: accessToken,
            role: user.role,
          })
        );

        dispatch(updateIsVerified({ isVerified }));

        if (user.banStatus === false) {
          toast.success("Login Successful", {
            duration: 2500,
            style: { background: "#0B5ED7" },
            className: "text-white",
          });
        }

        if (!isVerified) {
          navigate(`/verification/${values.email}`);
          return;
        }

        const isOwner = allCompany.some(
          (company) => company.owner === user._id
        );
        if (isOwner) {
          const company = await allCompany.find(
            (company) => company.owner === user._id
          );

          dispatch(
            setCompany({
              company,
            })
          );
        }
        if (user.role === "admin") {
          return navigate("/admin/dashboards/index");
        }

        if (isOwner) {
          navigate("/dashboards/index");
        } else {
          navigate("/signup/intro");
        }

        console.log("User Login Successful");
      } catch (error) {
        toast.error("User credential incorrect", {
          duration: 2500,
          style: { background: "#0B5ED7" },
          className: "text-white",
        });
      } finally {
        setLoading(false);
        setButtonText("Log in");

        validateEmail(values.email);
        validatePassword(values.password);
      }
    }, 1000);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="">
      <div className="bg-intro-custom ">
        <div className="row">
          <div className="col-md-6  ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <Swiper
                autoplay={{
                  delay: 1600,
                  disableOnInteraction: false,
                }}
                speed={1500}
                loop={true}
                navigation={true}
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper slide_login mt-5  px-1"
              >
                <SwiperSlide>
                  <div className="login_text margin-slide">
                    <p>
                      <strong>Update</strong> July 07 2024
                    </p>
                    <h1 className="login_h1">
                      Open Your Business In the United States
                    </h1>
                    <p className="margin-slide-text">
                      We provide the professional services to start & run a
                      company Digitally in the United States from Bangladesh.
                    </p>
                  </div>
                  <img
                    src="https://digitxgroup.com/wp-content/uploads/2024/05/Group-12-1.png"
                    className="w-100 h-100 slide_img_login px-5"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <div className="login_text margin-slide">
                    <p>
                      <strong>Update</strong> July 07 2024
                    </p>
                    <h1 className="login_h1">
                      All-in-one Platform for Managing your Business
                    </h1>
                    <p className="margin-slide-text">
                      Start your company with our online incorporation service
                      and maintain accounts and operation with Inocorplink.
                    </p>
                  </div>
                  <img
                    src="https://digitxgroup.com/wp-content/uploads/2024/05/Group-12-1.png"
                    className="w-100 h-100 slide_img_login px-5"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="login_text margin-slide">
                    <p>
                      <strong>Update</strong> July 07 2024
                    </p>
                    <h1 className="login_h1">Get your Payment Instantly</h1>
                    <p className="margin-slide-text">
                      Process your client payment instantly and transfer in your
                      bank account.
                    </p>
                  </div>
                  <img
                    src="https://digitxgroup.com/wp-content/uploads/2024/05/Group-12-1.png"
                    className="w-100 h-100 slide_img_login px-5"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="bg-white d-flex justify-content-center align-items-center h-100">
              <div className=" px-3 pb-3 mb-5 w-100 log_tex intro-margin-custom">
                <iframe
                  src="https://lottie.host/embed/703fbb2b-3d90-4550-a7fd-939ae3b349a7/bKyOkG6jwD.json"
                  className="lottie_login"
                ></iframe>
                <h2 className="login">Welcome 👋</h2>
                <p>Let's get some business done!</p>
                <form action="" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      value={values.email}
                      onChange={handleInput}
                      className="form-control rounded-2 my-input "
                    />

                    {errors.email && (
                      <span className="text-danger"> {errors.email}</span>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password">
                      <strong>Password</strong>
                    </label>
                    <div className="input-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter Your Password"
                        name="password"
                        value={values.password}
                        onChange={handleInput}
                        className="form-control rounded-2"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary eye_btn"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={isPasswordVisible ? faEyeSlash : faEye}
                        />
                      </button>
                    </div>
                    {errors.password && (
                      <span className="text-danger"> {errors.password}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <Link
                      to="/forgot-password"
                      className="text-decoration-none"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary w-100 rounded-0 ${
                      isValid ? "" : "disabled"
                    }`}
                    disabled={!isValid || loading}
                  >
                    {!loading ? (
                      <strong>Log in</strong>
                    ) : (
                      <strong>Checking Credentials</strong>
                    )}
                  </button>
                  <p className="text-center mt-3">
                    New Here? <Link to="/signup">Create Account</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
