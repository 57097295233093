import React from "react";
import RunningEmiList from "./RunningEmiList";
import { useGetEmiPaymentsQuery } from "../../../redux/features/admin/adminApi";
import EmiShortDetails from "./AdminUi/EmiShortDetails";
import circlePng from "../../../assets/dashboards/circle.png";
import CompletedEmiList from "./AdminUi/CompletedEmiList.js";
export default function EmiRecords() {
  const { data: allEmiPayments } = useGetEmiPaymentsQuery();
  const emiPayments = allEmiPayments?.data || [];
  const totalAmount = emiPayments?.reduce((acc, payment) => {
    const amount = parseFloat(payment.total_amount);
    return !isNaN(amount) ? acc + amount : acc;
  }, 0);
  const pendingEmi = emiPayments?.filter(
    (payment) => payment.status === "pending"
  );
  const completedEmi = emiPayments?.filter(
    (payment) => payment.status === "completed"
  );
  return (
    <div className=" ">
      <h4 className="text-center mb-3 my-2">Emi Records</h4>
      <div className="d-md-flex gap-3  ">
        <div className="payment-width-fixing-75">
          <adminIndexTag>
            <div className="row">
              <div className="col-md-3 mb-3 text-center">
                <div className="card bg-gradient-danger text-white">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Total Emi Amount
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h4 className="mb-2">
                      <span className="tk-sign">৳</span> {totalAmount}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-3 text-center">
                <div className="card bg-gradient-info text-white">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Total Transaction
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h4 className="mb-2">{emiPayments?.length}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-3 text-center">
                <div className="card bg-gradient-success text-white">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Completed
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h4 className="mb-2">{completedEmi?.length}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-3 text-center">
                <div className="card bg-gradient-pending text-white">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Pending
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h4 className="mb-2">{pendingEmi?.length}</h4>
                  </div>
                </div>
              </div>
            </div>
          </adminIndexTag>
          <RunningEmiList emiPayments={emiPayments} />
          <CompletedEmiList emiPayments={emiPayments} />
        </div>
        <div className="payment-width-fixing-25 mt-md-0 mt-3">
          <EmiShortDetails emiPayments={emiPayments} />
        </div>
      </div>
    </div>
  );
}
