import { useState } from "react";
import { ScaleLoader } from "react-spinners";
import UserList from "./AdminUi/UserList";
import AdminList from "./AdminUi/AdminList";
import { useGetAllUserQuery } from "../../../redux/features/admin/adminApi";
import BannedUserLists from "./AdminUi/BannedUserList";
import circlePng from "../../../assets/dashboards/circle.png";

const UserManagement = () => {
  const {
    data: userData,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetAllUserQuery();
  const allUsers = userData?.data;

  const users = allUsers?.filter(
    (user) => user.role !== "admin" && user.banStatus !== true
  );
  const admins = allUsers?.filter(
    (user) =>
      user.role === "admin" && user.banStatus === false && user.role !== "user"
  );
  const bannedUsers = allUsers?.filter((user) => user.banStatus === true);

  const [activeTab, setActiveTab] = useState("users");

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-end h-100 w-100">
        <ScaleLoader className="position-absolute top-50" color="#4c36d6" />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <p>Error: {error.message}</p>
        <button onClick={refetch}>Retry</button>
      </div>
    );
  }

  return (
    <userManagementComponent>
      <section className="m-4">
        <div>
          {" "}
          <staticsUserDetails>
            <div
              className="d-flex 
             gap-3 justify-content-center align-items-center usermange_ad"
            >
              <div className="w-100 card bg-gradient-danger card-img-holder text-white">
                <div className="card-body">
                  <img src={circlePng} className="card-img-absolute" alt="" />
                  <h4 className="font-weight-normal  mb-0">
                    Total Registered
                    <i className="mdi mdi-chart-line mdi-24px float-end" />
                  </h4>
                  <h2 className=" mb-0">{allUsers?.length}</h2>
                </div>
              </div>
              <div className="w-100 card bg-gradient-danger card-img-holder text-white">
                <div className="card-body">
                  <img src={circlePng} className="card-img-absolute" alt="" />
                  <h4 className="font-weight-normal  mb-0">
                    Total Users
                    <i className="mdi mdi-chart-line mdi-24px float-end" />
                  </h4>
                  <h2 className=" mb-0">{users?.length}</h2>
                </div>
              </div>
              <div className="w-100 card bg-gradient-info card-img-holder text-white">
                <div className="card-body">
                  <img src={circlePng} className="card-img-absolute" alt="" />
                  <h4 className="font-weight-normal  mb-0">
                    Admins
                    <i className="mdi mdi-chart-line mdi-24px float-end" />
                  </h4>
                  <h2 className=" mb-0">{admins?.length}</h2>
                </div>
              </div>
              <div className="w-100 card bg-gradient-success card-img-holder text-white">
                <div className="card-body">
                  <img src={circlePng} className="card-img-absolute" alt="" />
                  <h4 className="font-weight-normal  mb-0">
                    Banned Users
                    <i className="mdi mdi-chart-line mdi-24px float-end" />
                  </h4>
                  <h2 className=" mb-0">{bannedUsers?.length}</h2>
                </div>
              </div>
            </div>
          </staticsUserDetails>
          <div className="tabs ml-0 m-0 my-3 gap-2 ">
            <div
              className={` ${
                activeTab === "users"
                  ? "active active_company_menu2"
                  : "non-active_company_menu2 cursor-pointer"
              }`}
              onClick={() => setActiveTab("users")}
            >
              Users
            </div>
            <div
              className={` ${
                activeTab === "admins"
                  ? "active active_company_menu2"
                  : "non-active_company_menu2 cursor-pointer"
              }`}
              onClick={() => setActiveTab("admins")}
            >
              Admins
            </div>
            <div
              className={` ${
                activeTab === "bannedUsers"
                  ? "active active_company_menu2"
                  : "non-active_company_menu2 cursor-pointer"
              }`}
              onClick={() => setActiveTab("bannedUsers")}
            >
              Banned Users
            </div>
          </div>
        </div>

        <div>
          {activeTab === "users" && <UserList users={users} />}
          {activeTab === "admins" && <AdminList admins={admins} />}
          {activeTab === "bannedUsers" && (
            <BannedUserLists bannedUsers={bannedUsers} />
          )}
        </div>
      </section>
    </userManagementComponent>
  );
};

export default UserManagement;
