import baseApi from "./baseApi";

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserAddedCompany: builder.query({
      query: () => "/company/get-user-added-company",
      providesTags: ["user", "files"],
    }),
    usersLastAddedCompany: builder.query({
      query: () => "/company/get-last-added-company",
    }),
    postActiveCompanyId: builder.mutation({
      query: (_id) => ({
        url: "/company/active-selected-company",
        method: "POST",
        body: { company_id: _id },
      }),
      invalidatesTags: ["user", "company", "bank"],
    }),
    getSingleUserWithId: builder.mutation({
      query: (_id) => ({
        url: `/users/get-single-user/${_id}`,
        method: "GET",
      }),
    }),

    updateProfile: builder.mutation({
      query: (userData) => ({
        url: "/users/update-profile",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["user"],
    }),
    updateProfilePicture: builder.mutation({
      query: (userData) => ({
        url: "/users/update-profile-picture ",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["user"],
    }),
    adminEditUserProfileApi: builder.mutation({
      query: (userData) => ({
        url: "/users/admin-edit-user-profile",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["user"],
    }),
    CheckUserEmail: builder.mutation({
      query: (email) => ({
        url: `/users/check-email/${email}`,
        method: "GET",
      }),
    }),
    userActiveCompany: builder.query({
      query: () => "/users/get-active-company",
      providesTags: ["user", "company"],
    }),
    getNotifications: builder.query({
      query: () => "/users/notifications",
      providesTags: ["user"],
    }),
    readNotification: builder.mutation({
      query: () => ({
        url: `/users/read-notification`,
        method: "POST",
      }),
      invalidatesTags: ["user"],
    }),
    unreadNotifications: builder.query({
      query: () => "/users/unread-notifications",
      providesTags: ["notifications"],
    }),
  }),
});

export const {
  usePostActiveCompanyIdMutation,
  useGetUserAddedCompanyQuery,
  useCheckUserEmailMutation,
  useUsersLastAddedCompanyQuery,
  useUpdateProfileMutation,
  useUserActiveCompanyQuery,
  useGetSingleUserWithIdMutation,
  useAdminEditUserProfileApiMutation,
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useUnreadNotificationsQuery,
  useUpdateProfilePictureMutation,
} = userApi;
