import baseApi from "../../api/baseApi";

const filesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.mutation({
      query: (folder) => ({
        url: `/space/files?folder=${folder}`,
        method: "GET",
      }),
    }),
    getDocuments: builder.query({
      query: (folder) => `/space/files?folder=${folder}`,
    }),
    userDocuments: builder.query({
      query: (folder) => `/space/get-users-documents?folder=${folder}`,
      
      providesTags: ["files"],
    }),

    uploadFiles: builder.mutation({
      query: ({ formData, folderName }) => ({
        url: `/space/upload-files?folder=${folderName}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["files"],
    }),
    uploadFilesToCompany: builder.mutation({
      query: ({ formData, folderName }) => ({
        url: `/space/upload-files-to-company?folder=${folderName}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["files"],
    }),
    uploadRequireFiles: builder.mutation({
      query: ({ formData, folderName }) => ({
        url: `/space/upload-requirements-files?folder=${folderName}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["files", "company", "user"],
    }),
    deleteFiles: builder.mutation({
      query: (files) => ({
        url: `/space/delete-files`,
        method: "POST",
        body: { files },
      }),
      invalidatesTags: ["files"],
    }),
    copyFiles: builder.mutation({
      query: ({ files, destinationFolder }) => ({
        url: `/space/copy-files`,
        method: "POST",
        body: { files, destinationFolder },
      }),
      invalidatesTags: ["files"],
    }),
    cutFiles: builder.mutation({
      query: ({ files, destinationFolder }) => ({
        url: `/space/cut-files`,
        method: "POST",
        body: { files, destinationFolder },
      }),
      invalidatesTags: ["files"],
    }),
    createFolder: builder.mutation({
      query: (cleanData) => ({
        url: `/space/create-new-folder`,
        method: "POST",
        body: cleanData,
      }),
      invalidatesTags: ["files"],
    }),
  }),
});

export const {
  useGetInvoicesMutation,
  useGetDocumentsQuery,
  useUserDocumentsQuery,
  useUploadFilesMutation,
  useDeleteFilesMutation,
  useCopyFilesMutation,
  useCutFilesMutation,
  useCreateFolderMutation,
  useUploadRequireFilesMutation,
  useUserAdminUserDocumentsQuery,
  useUploadFilesToCompanyMutation,
} = filesApi;
