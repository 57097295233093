import React, { useEffect, useState } from "react";
import "../login/loginsignup.css";
import {
  useGetUserAddedCompanyQuery,
  usePostActiveCompanyIdMutation,
  useUserActiveCompanyQuery,
} from "../../redux/api/userApi";
import "../dashboardsfiles/dashboard.css";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApi";

import { useNavigate } from "react-router-dom";
const YourCompanies = () => {
  const navigate = useNavigate();
  const { data: userData } = useGetCurrentUserQuery();
  const user = userData?.data;
  const company_id = user?.activeCompany;

  const { data: singleCompany } = useUserActiveCompanyQuery();
  const companyInformation = singleCompany?.data;
  const { _id } = companyInformation || {};
  const activeCompany = _id;
  const [userAddedCompany, setUserAddedCompany] = useState([]);
  const { data } = useGetUserAddedCompanyQuery();
  useEffect(() => {
    setUserAddedCompany(data?.data || []);
  }, [data]);

  const [postActiveCompany, { isError }] = usePostActiveCompanyIdMutation();

  const handleSelectCompany = async (_id) => {
    try {
      const response = await postActiveCompany(_id);
      navigate("/dashboards/index");
      console.log("Data Saved Successfully:", response.data);
    } catch (error) {
      console.error("Error Saving Data:", error);
    }
  };

  return (
    <div className="companies">
      <div className="container">
        <div className="row Id-row">
          <div className="col-md-6">
            <div className="companies_text">
              <h4>Your companies</h4>
              <p>You Have {userAddedCompany.length} comapnies now</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="company_button_sec">
              {/* <a className="companybutton" href="/signup/intro">
                Register new company
              </a> */}
            </div>
          </div>
        </div>
        <div className="company-list row">
          {userAddedCompany?.map((company) => (
            <div
              onClick={() => handleSelectCompany(company._id)}
              className="col-md-4 col-sm-12 "
              key={company._id}
            >
              <div className="d-flex justify-content-between align-items-center bg-company-list  m-1 p-3  ">
                <div className="d-flex gap-2 justify-content-center align-item-center   ">
                  <div className="text-white position-relative">
                    <p className="avatar-text">D</p>
                    <div className="companyAvatarInPopup bg-primary"></div>
                  </div>
                  <div className="userAddedCompanyList text-decoration-none d-flex flex-column justify-content-center align-items-start">
                    <p className="text-decoration-none m-0 p-0 company-title-popup">
                      {company.company_name}
                    </p>
                    <p className="text-decoration-none m-0 p-0">
                      {company._id}
                    </p>
                  </div>
                </div>
                <div>
                  {company._id === activeCompany ? (
                    <span className="activeCompany-small-text">Logged in </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourCompanies;
