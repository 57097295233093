import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetSingleCompanyQuery } from "../../../redux/api/companyApi";
import AboutCompanyEdit from "./EditCompanyPages/AboutCompanyEdit";
import CompanyDirectorEdit from "./EditCompanyPages/CompanyDirectorEdit";
import ShareholderEdit from "./EditCompanyPages/ShareholderEdit";
import { useDispatch } from "react-redux";
import { setDirectors } from "../../../redux/features/company/nonPersistCompany";

export default function EditCompanyInformation() {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { data: singleCompany, isLoading } = useGetSingleCompanyQuery(_id);
  const company_information = singleCompany?.data;
  const [activeEdit, setActiveEdit] = useState("aboutCompany");
  const location = useLocation();
  useEffect(() => {
    if (
      company_information && // Ensure company_information is defined
      location.pathname.startsWith("/admin/dashboards/editCompanyInformation/")
    ) {
      dispatch(setDirectors(company_information.directors || [])); // Ensure directors is an array
    }
  }, [location, dispatch, company_information]);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  const {
    company_name,
    explanation_of_name,
    about,
    NSICS_codes,

    number_of_share,
    company_email,
    company_phone_number,
    company_country_code,
    address_line_one,

    directors,
    shareholders,
  } = company_information || {};

  return (
    <div className="m-4">
      <h3 className="my-2 font-weight-bold text-xl text-center">
        Edit Company Information
      </h3>
      <div className="d-flex justify-content-center align-items-center h-100 step_first_top">
        <div className="row ">
          <div className="col-md-5">
            <customCompanyInformation>
              <div className="bg-light border_review2 shadow p-4 mb-4">
                <div className="sparate_border ">
                  <h4 className="text-primary text_heding_review">
                    Company Details
                  </h4>
                  <div className="singleDirectorCard2 singleDirector mb-2">
                    <div className="mb-1">
                      <strong>Company name:</strong> {company_name}
                    </div>
                    <div className="mb-1">
                      <strong>Explanation of name:</strong>{" "}
                      {explanation_of_name}
                    </div>
                    <div className="mb-1">
                      <strong>What this business will be doing:</strong> {about}
                    </div>
                    <div className="mb-1">
                      <strong>Business Nature - NSICS Codes:</strong>
                      {NSICS_codes}
                    </div>
                    <div className="mb-1">
                      <div className="">
                        <div className="mb-1">
                          <strong>Number of Shares:</strong>${number_of_share}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 className="text-primary text_heding_review">
                      Company Contact Information
                    </h4>
                    <div className="singleDirectorCard2 singleDirector mb-2">
                      <div className="">
                        <strong>Email Address:</strong> {company_email}
                      </div>
                      <div className="">
                        <strong>Phone Number:</strong>
                        {company_country_code}
                        {company_phone_number}
                      </div>

                      <div className="">
                        <strong>Business (office) address:</strong>
                        {address_line_one}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sparate_border">
                  <div className="">
                    <h4 className="text-primary text_heding_review">
                      Directors
                    </h4>
                    {directors?.map((director, i) => (
                      <div
                        className="d-flex gap-3 justify-content-between align-items-center singleDirectorCard2 my-2 singleDirector"
                        key={i}
                      >
                        <div>
                          <div>
                            <strong>Name:</strong> {director.full_name}
                          </div>
                          <div>
                            {" "}
                            <strong>Email:</strong> {director.directors_email}
                          </div>
                          <div>
                            <strong>Country: </strong>{" "}
                            {director.directors_country_name}
                          </div>
                          <div>
                            <strong>Phone: </strong>{" "}
                            {director.directors_country_code}
                            {director.directors_phone_number}
                          </div>
                          <div>
                            <strong>Role: </strong> {director.role_of_directors}
                          </div>
                          <div>
                            <strong>Appointed:</strong>{" "}
                            {new Date(director.createdAt).toLocaleDateString()}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="text-primary text_heding_review">
                    Shareholders
                  </h4>

                  {shareholders?.map((item, i) => (
                    <div
                      key={i}
                      className="d-flex gap-3 justify-content-between align-items-center singleDirectorCard2 my-2 singleDirector"
                    >
                      <div>
                        <div className="">
                          <strong>Name:</strong> {item.shareholder_name}
                        </div>
                        <div className="">
                          <strong>Shares:</strong> ${item.share}
                        </div>
                        <div className="">
                          <strong>Email:</strong> {item.shareholder_email}
                        </div>
                        <div className="">
                          <strong>Mobile:</strong>{" "}
                          {item.shareholder_country_code}
                          {item.shareholder_phone_number}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="form-group text-muted mt-4">
                  <p className="mb-4">
                    Submit your incorporation request to get it reviewed by our
                    team! After submission, you may choose to pay for the
                    incorporation directly or go to your dashboard.
                  </p>
                </div>
              </div>
            </customCompanyInformation>
          </div>

          <div className="col-md-7">
            <div className="mb-3 d-flex justify-content-center align-align-items-center">
              <button
                onClick={() => setActiveEdit("aboutCompany")}
                className={`btn m-1 ${
                  activeEdit === "aboutCompany"
                    ? "btn-primary"
                    : "btn-secondary"
                }`}
              >
                Edit About Company
              </button>
              <button
                onClick={() => setActiveEdit("director")}
                className={`btn m-1 ${
                  activeEdit === "director" ? "btn-primary" : "btn-secondary"
                }`}
              >
                Edit Director
              </button>
              <button
                onClick={() => setActiveEdit("shareholder")}
                className={`btn m-1 ${
                  activeEdit === "shareholder" ? "btn-primary" : "btn-secondary"
                }`}
              >
                Edit Shareholder
              </button>
            </div>
            {activeEdit === "aboutCompany" && (
              <AboutCompanyEdit company_information={company_information} />
            )}
            {activeEdit === "director" && (
              <CompanyDirectorEdit
                company_information={company_information}
                setActiveEdit={setActiveEdit}
              />
            )}
            {activeEdit === "shareholder" && (
              <ShareholderEdit company_information={company_information} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
