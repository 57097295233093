import Badge from "@mui/material/Badge";
import { Box } from "@mui/material";
import Popover from "@mui/material/Popover";
import NotificationList from "./NotificationList";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useState, useEffect } from "react";
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from "../redux/api/userApi";
import socket from "../socket/socket";

export default function Notification() {
  const { data: notificationsData, refetch } = useGetNotificationsQuery();
  const notifications = notificationsData?.data || [];
  const [readNotification, { isLoading: isNotificationLoading }] =
    useReadNotificationMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    socket.on("user_notification", () => {
      refetch();
    });
    return () => {
      socket.off("user_notification");
    };
  }, [notificationsData, refetch]);

  useEffect(() => {
    const unreadNotifications = notifications.filter(
      (notification) => !notification.read
    );
    setUnreadCount(unreadNotifications.length);
  }, [notifications]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    const { data } = await readNotification();
    if (data) {
      console.log("Notification read successfully", data);
      // Update the unread count to 0 after closing the popover
      setUnreadCount(0);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Box sx={{ mr: 1 }}>
        <Badge
          className="cursor-pointer"
          onClick={handleClick}
          color="secondary"
          badgeContent={unreadCount}
          showZero
          max={999}
        >
          <NotificationsIcon style={{ color: "0f68ea" }} />
        </Badge>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <NotificationList notifications={notifications} />
      </Popover>
    </div>
  );
}
