import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import {
  useBanUserMutation,
  useDeleteUserFromDatabaseMutation,
  useGetUserAllAddedCompanyMutation,
  useMakeUserAdminMutation,
} from "../../../../redux/features/admin/adminApi";
import "../admin.css";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { FaCircleUser } from "react-icons/fa6";
const UserList = ({ users }) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [userCompanies, setUserCompanies] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [getUserAllAddedCompany] = useGetUserAllAddedCompanyMutation();
  const [makeUserAdmin] = useMakeUserAdminMutation();
  const [deleteUserFromDatabase] = useDeleteUserFromDatabaseMutation();
  const [banUser] = useBanUserMutation();
  const deleteUser = async (userId) => {
    console.log("Deleting user with ID:", userId);
    try {
      const res = await deleteUserFromDatabase({ userId }).unwrap();
      if (res.success === true) toast.success("User deleted successfully");
    } catch (error) {
      toast.error("Failed to delete user");
      console.error("Failed to delete user:", error);
    }
  };
  useEffect(() => {
    const fetchCompanyData = async () => {
      const companies = {};
      for (const user of users) {
        const { data: companyData } = await getUserAllAddedCompany(user._id);
        companies[user._id] = companyData?.data || [];
      }
      setUserCompanies(companies);
    };
    fetchCompanyData();
  }, [getUserAllAddedCompany, users]);

  const makeAdmin = async (userId) => {
    console.log("Making user admin with ID:", userId);
    try {
      const res = await makeUserAdmin({ userId }).unwrap();
      if (res.success === true) {
        toast.success("User made admin successfully");
      }
    } catch (error) {
      toast.error("Failed to make user admin");
      console.error("Failed to make user admin:", error);
    }
  };

  const banUserFromDatabase = async (userId) => {
    try {
      const { data } = await banUser({ userId });
      if (data?.success === true) {
        toast.success("User banned successfully");
      }
    } catch (error) {
      toast.error("Failed to ban user");
      console.error("Failed to ban user:", error);
    }
  };

  // Cleaned up code by standardizing variable names, removing debugging statements, improving readability, and more.

  const handleSelectChange = (userId, action) => {
    setSelectedUserId(userId);
    setSelectedAction(action);
  };

  const handleConfirmation = () => {
    if (selectedAction === "delete") {
      deleteUser(selectedUserId);
    } else if (selectedAction === "admin") {
      makeAdmin(selectedUserId);
    } else if (selectedAction === "ban") {
      banUserFromDatabase(selectedUserId);
    }
    setSelectedUserId(null);
    setSelectedAction("");
  };

  const filteredUsers = users?.filter(
    (user) =>
      user?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      `${user?.firstName} ${user?.lastName}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="card p-4">
      <div className="d-flex justify-content-between align-items-center my-2">
        <h5>All Users</h5>
        <searchContainer>
          <div className="group">
            <svg viewBox="0 0 24 24" aria-hidden="true" className="icon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
              </g>
            </svg>
            <input
              placeholder="Search by email or name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="input"
              type="search"
            />
          </div>
        </searchContainer>
      </div>
      <div className="overflow-x-scroll">
        <table className="table table-striped c_profile_table c_director_table">
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>

              <th>Role</th>
              <th>Join Date</th>
              <th>Details</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user) => {
              const {
                _id,
                avatar,
                email,
                firstName,
                lastName,
                countryCode,
                phone,
                role,
                createdAt,
              } = user;
              const company = userCompanies[_id];
              return (
                <tr key={_id}>
                  <td className="">
                    <div>
                      {" "}
                      {avatar ? (
                        <img
                          src={`${avatar}?${new Date().getTime()}`}
                          alt="Profile"
                          className="user-management-avatar"
                        />
                      ) : (
                        <FaCircleUser className="user-management-avatar-icon" />
                      )}
                    </div>
                  </td>
                  <td className="overflow-table-wrapper">
                    {firstName} {lastName}
                  </td>
                  <td>{email}</td>
                  <td>
                    {countryCode}
                    {phone}
                  </td>

                  <td>{role}</td>
                  <td>{new Date(createdAt).toLocaleDateString()}</td>
                  <td>
                    <Link to={`/admin/dashboards/userDetails/${_id}`}>
                      Details
                    </Link>
                  </td>
                  <td>
                    <select
                      className={clsx("border")}
                      aria-label="Project status"
                      onChange={(e) => handleSelectChange(_id, e.target.value)}
                    >
                      <option value="">Select Action</option>
                      <option value="delete">Delete User</option>
                      <option value="admin">Make Admin</option>
                      <option value="ban">Ban User</option>
                    </select>
                    {selectedUserId === _id && selectedAction && (
                      <button
                        className="confirm_btn"
                        onClick={handleConfirmation}
                      >
                        Confirm
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* Pagination */}
      </div>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            onClick={() => paginate(currentPage - 1)}
            className="page-link"
          >
            Previous
          </button>
        </li>
        {Array.from({
          length: Math.ceil(filteredUsers.length / usersPerPage),
        }).map((_, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          >
            <button onClick={() => paginate(index + 1)} className="page-link">
              {index + 1}
            </button>
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === Math.ceil(filteredUsers.length / usersPerPage)
              ? "disabled"
              : ""
          }`}
        >
          <button
            onClick={() => paginate(currentPage + 1)}
            className="page-link"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserList;
