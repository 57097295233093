import baseApi from "../../api/baseApi";

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => "/users/current-user",
      providesTags: ["user"],
    }),
    getUserByEmail: builder.query({
      query: (email) => ({
        url: `/users/get-user-by-email/${email}`,
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    login: builder.mutation({
      query: (userInfo) => ({
        url: "/users/login",
        method: "POST",
        body: userInfo,
      }),
      invalidatesTags: ["user"],
    }),
    signUp: builder.mutation({
      query: (registerUser) => ({
        url: "/users/register",
        method: "POST",
        body: registerUser,
      }),
      invalidatesTags: ["user"],
    }),
    verificationEmail: builder.mutation({
      query: (verifyData) => ({
        url: "/users/verification",
        method: "POST",
        body: verifyData,
      }),
      invalidatesTags: ["user"],
    }),
    resendOtp: builder.mutation({
      query: (email) => ({
        url: "/users/resend-otp",
        method: "POST",
        body: email,
      }),
      invalidatesTags: ["user"],
    }),
    updatePassword: builder.mutation({
      query: (credentials) => ({
        url: "/users/change-password",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["user"],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetCurrentUserQuery,
  useUpdatePasswordMutation,
  useSignUpMutation,
  useVerificationEmailMutation,
  useGetUserByEmailQuery,
  useResendOtpMutation,
} = userApi;
