import React from "react";

export default function AdminCompanyDirectors({ company_information }) {
  const { directors } = company_information || {};

  return (
    <div className="">
      <div className="card p-4 my-3">
        <div>
          <h4 className="text-black m-0 p-0">Directors</h4>
          <p className="profile_c_p">These are the current directors</p>
        </div>
        <div className="overflow-x-scroll">
          <table className="table table-striped">
            <thead className="c_profile_table c_director_table">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Country</th>
                <th>ID Number</th>
                <th>Appointed</th>
              </tr>
            </thead>
            <tbody className="c_profile_table c_director_table">
              {directors?.map((director) => (
                <tr key={director.id}>
                  <td>{director.full_name}</td>
                  <td>{director.directors_email}</td>
                  <td>{director.directors_country_name}</td>
                  <td>{director._id}</td>
                  <td>{new Date(director.createdAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
