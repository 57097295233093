import React, { useState } from "react";
import Modal from "../../../ui/Modal";
import "../../login/loginsignup.css";
import { Tab } from "@headlessui/react";
import CorporateShareholder from "./corporateShareholder";
import IndividualShareholder from "./individualShareholder";

const ShareHolderModal = ({ isOpen, setIsOpen, singleShareholder, setSingleShareholder }) => {
  const [activeTab, setActiveTab] = useState("individual");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="bg-white z-5 modal-index">
        <div className="">
          <div className="">
            <div className="">
              <h4>Add shareholder</h4>
              <p className="step4">
                Is this an individual or corporate shareholder?
              </p>
              <Tab.Group
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
              >
                <Tab.List className="nav nav-pills nav-fill mb-3 ">
                  <Tab className={`nav-item border-0 w-50`}>
                    <button
                      className={`nav-link ${
                        activeTab === "individual" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("individual")}
                    >
                      Individual
                    </button>
                  </Tab>
                  <Tab className={`nav-item border-0 w-50`}>
                    <button
                      className={`nav-link ${
                        activeTab === "corporate" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("corporate")}
                    >
                      Corporate
                    </button>
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    {activeTab === "individual" && (
                      <IndividualShareholder
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        singleShareholder={singleShareholder}
                        setSingleShareholder={setSingleShareholder}
                      />
                    )}
                  </Tab.Panel>
                  <Tab.Panel>
                    {activeTab === "corporate" && (
                      <CorporateShareholder
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        singleShareholder={singleShareholder}
                        setSingleShareholder={setSingleShareholder}
                      />
                    )}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareHolderModal;
