import React from "react";

import { Link } from "react-router-dom";
import PaginationNew from "../../../../ui/PaginationNew";
import { usePagination } from "../../../../hooks/usePagination";

export default function TransactionTable({ payments }) {
  const {
    currentItems: currentPayments,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    goToPage,
  } = usePagination(payments, 5);

  return (
    <paymentRecordTable>
      <div style={{ fontSize: "14px" }} className="col-12 grid-margin">
        <div className="card ">
          <div className="card-body">
            <h5 className="">Transaction History</h5>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Total Amount</th>
                    <th>Transaction ID</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPayments?.map((payment, index) => {
                    return (
                      <tr key={payment._id}>
                        <td>{index + 1}</td>

                        <td>
                          {payment.currency}{" "}
                          <span
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              padding: "0px 2px",
                            }}
                          >
                            ৳
                          </span>
                          {payment.total_amount}
                        </td>
                        {payment.isEmi && (
                          <>
                            <td>
                              {payment.currency}{" "}
                              <span
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  padding: "0px 2px",
                                }}
                              >
                                ৳
                              </span>
                              {payment.emi_amount.toFixed(2)}
                            </td>
                          </>
                        )}

                        <td>{payment.transaction_id}</td>
                        <td className="">
                          <div
                            className={`bg text-black text-center text-white p-1 paidStatusBox bg-${
                              payment.emi_option ? "danger" : "success"
                            }`}
                          >
                            <div className="paidStatusBtn">
                              {payment.emi_option ? "EMI" : "Normal"}
                            </div>
                          </div>
                        </td>
                        <td>
                          {new Date(payment.createdAt).toLocaleDateString()}
                        </td>
                        <td>
                          <Link
                            className="text-decoration-none"
                            to={`/dashboards/paymentDetails/${payment.transaction_id}`}
                          >
                            <div
                              className={`bg text-black text-white text-center p-1  paidStatusBox bg-warning 
                              `}
                            >
                              <div className="text-black">Details</div>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {payments.length > 0 && (
              <PaginationNew
                currentPage={currentPage}
                totalPages={totalPages}
                goToPage={goToPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            )}
          </div>
        </div>
      </div>
    </paymentRecordTable>
  );
}
