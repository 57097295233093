import React from "react";
import { Link } from "react-router-dom";
import "../login/loginsignup.css";
import introSvg from "../../assets/introflow/svg/1.svg";

function IntroFlow() {
  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img src={introSvg} width={400} className="intro-flow-img-custom-size mt-5 custom-intro-hight " alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-7 ">
            <div className="bg-white d-flex justify-content-center align-items-center h-100">
              <div className=" px-3 pb-3 mb-5 w-100 log_tex intro-margin-custom">
                <h3 className="get_started_text">
                  Get Started with onboarding
                </h3>
                <p>
                  This is the start of a new journey and we couldn't be more
                  excited to help you with starting your company. Begin the
                  simple 4 step process here
                </p>
                <div className="step-container">
                  <div className="step-group">
                    <div className="step">
                      <div className="step-badge">1</div>
                      <p className="step-text">
                        Submit Incorporation Details and Select Plan
                      </p>
                    </div>
                    <div className="flow-arrow"></div>{" "}
                    {/* Arrow from step 1 to step 2 */}
                    <div className="step">
                      <div className="step-badge">2</div>
                      <p className="step-text">
                        Verify Identity of All Directors / Shareholders
                      </p>
                    </div>
                    <div className="flow-arrow"></div>
                    <div className="step-group">
                      <div className="step">
                        <div className="step-badge">3</div>
                        <p className="step-text">
                          Signing of Pre-Incorporation Documents
                        </p>
                      </div>
                      <div className="flow-arrow"></div>{" "}
                      {/* Arrow from step 2 to step 3 */}
                      <div className="step">
                        <div className="step-badge">4</div>
                        <p className="step-text">
                          Submission to Department of State
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="intro_p">
                  <strong>Useful to know</strong>,<br />
                  Your Data is saved after every step, you may come back at any
                  time to complete the onboarding procces. After submisson out
                  team will get in touch with you.
                </p>
                <Link to="/signup/nameyourcompany">
                  <button
                    onClick={handleGetStarted}
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Get Started</strong>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function handleGetStarted() {
  console.log("Get Started button clicked");
}

export default IntroFlow;
