import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDirectors: [],
  
};

const nonPersistCompanySlice = createSlice({
  name: "nonPersistCompany",
  initialState,
  reducers: {
    setDirectors: (state, action) => {
      const directors = action.payload;
      state.allDirectors = directors;
    },
    setNewDirectors: (state, action) => {
      state.allDirectors = [...state.allDirectors, action.payload];
    },
    deleteDirector: (state, action) => {
      state.allDirectors = state.allDirectors.filter(
        (director) => director.directors_email !== action.payload
      );
    },
    updateDirector: (state, action) => {
      state.allDirectors = state.allDirectors.map((director) => {
        if (director.directors_email === action.payload.directors_email) {
          return action.payload;
        }
        return director;
      });
    },
  },
});

export const { setDirectors, setNewDirectors, deleteDirector, updateDirector } =
  nonPersistCompanySlice.actions;

export default nonPersistCompanySlice.reducer;
