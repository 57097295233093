import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { useGetAllCompanyQuery, useIsPaidQuery } from "../redux/api/companyApi";
import { ScaleLoader } from "react-spinners";
import { useUserActiveCompanyQuery } from "../redux/api/userApi";

const ProtectedRoute = ({ children, role }) => {
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);
  const company_information = useSelector(
    (state) => state?.company?.company_information
  );

  const location = useLocation();
  const { data: companyDataCollection, isLoading } = useGetAllCompanyQuery();
  const allCompany = companyDataCollection?.data;
  const isVerified = useSelector((state) => state?.auth?.isVerified);
  // const { data: companyInfo } = useUserActiveCompanyQuery();
  // const company_information = companyInfo?.data;
  // const { data: paidStatus, isLoading: isPaidLoading } = useIsPaidQuery(
  //   company_information?._id
  // );
  // const isPaid = paidStatus?.data;

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-end h-100 w-100 ">
        <ScaleLoader className="position-absolute top-50" color="#4c36d6" />
      </div>
    );
  }

  if (!token || !user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (role && !role.includes(user?.role)) {
    return <Navigate to="/not-authorized" state={{ from: location }} replace />;
  }

  if (!isVerified) {
    return (
      <Navigate
        to={`/verification/${user.email}`}
        state={{ from: location }}
        replace
      />
    );
  }

  const isOwnerOfCompany = allCompany?.some(
    (company) => company.owner === user._id
  );

  if (!isOwnerOfCompany && location.pathname.startsWith("/dashboards/")) {
    return <Navigate to="/signup/intro" state={{ from: location }} />;
  }

  const restrictedRoutes = [
    "/signup/intro",
    "/signup/nameyourcompany",
    "/signup/aboutcompany",
    "/signup/sharesandpaids",
    "/signup/businesscontactdetails",
    "/signup/whowillbethedirectors",
    "/signup/Shareholders",
    "/signup/reviewSubmission",
  ];

  // const nonPaidRestrictedRoutes = ["/dashboards/index"];
  // if (
  //   !isPaid &&
  //   nonPaidRestrictedRoutes.some((route) => location.pathname.includes(route))
  // ) {
  //   return <Navigate to="/signup/selectplan" state={{ from: location }} />;
  // }

  if (
    isOwnerOfCompany &&
    restrictedRoutes.some((route) => location.pathname.includes(route))
  ) {
    return <Navigate to="/dashboards/index" replace />;
  }

  return children;
};

export default ProtectedRoute;
