import * as React from "react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useGetBankDetailsQuery } from "../../../../redux/features/banks/bankApi";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { useUpdateBankStatusMutation } from "../../../../redux/api/companyApi";
import { useEffect, useState } from "react";
import { MdVerified } from "react-icons/md";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function AdminCompanyProfile({ company_information }) {
  const [status, setStatus] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [updateBankStatus] = useUpdateBankStatusMutation();

  const {
    _id,
    company_name,
    address_line_one,
    directors,
    createdAt,
    bank_information,
    payment_information,
  } = company_information || {};
  const { data: bankData } = useGetBankDetailsQuery(
    bank_information?.selected_bank
  );
  const bank_data = bankData?.data;

  const handleChangeBankStatus = async (status, handleClose) => {
    const cleanData = {
      company_id: _id,
      status: status,
    };
    console.log(cleanData);
    const { data } = await updateBankStatus(cleanData).unwrap();
    console.log(data);
    handleClose();
  };

  return (
    <div>
      <div className="">
        <div className="card p-4 my-3 ">
          <div className="">
            <Link to={`/admin/dashboards/editCompanyInformation/${_id}`}>
              <h6 className="d-flex justify-content-end align-items-center gap-2">
                Edit Information <FaEdit />{" "}
              </h6>
            </Link>
            <table className="table-responsive table table-striped  c_director_table in_t">
              <tbody className="bg-primary table_index c_profile_table c_director_table in_t">
                <tr className="table-mobile-responsive-row">
                  <th scope="row company_table_head_data2 ">Official Name</th>
                  <td className="overflow-direct-wrapper company_table_body_data2">
                    {company_name}
                  </td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th scope="row company_table_head_data2 ">
                    Registration Number
                  </th>
                  <td className="company_table_body_data2 overflow-direct-wrapper"  >{_id}</td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th scope="row company_table_head_data2 ">
                    Incorporation Date
                  </th>
                  <td className="company_table_body_data2">
                    {" "}
                    {new Date(createdAt).toLocaleDateString()}
                  </td>
                </tr>
                <tr className="table-mobile-responsive-row ">
                  <th scope="row company_table_head_data2 ">
                    Company Secretary
                  </th>
                  <td className="company_table_body_data2">
                    {directors?.map((director) => (
                      <div
                        style={{
                          borderBottom: "1px",
                          paddingBottom: "6px",
                        }}
                        key={director}
                        className="overflow-direct-wrapper"
                      >
                        {director.full_name} <br />
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                            textTransform: "uppercaseF",
                          }}
                        >
                          Role: {director.role_of_directors}
                        </div>
                      </div>
                    ))}
                  </td>
                </tr>

                <tr className="table-mobile-responsive-row">
                  <th scope="row company_table_head_data2 ">Total Share</th>
                  <td className="company_table_body_data2">$1000</td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th scope="row company_table_head_data2 ">
                    Business Address
                  </th>
                  <td className="overflow-direct-wrapper company_table_body_data2">
                    {address_line_one}
                  </td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th scope="row company_table_head_data2">Selected Bank</th>
                  {bank_information?.selected_bank ? (
                    <td className="d-flex gap-2 align-items-center">
                      {bank_data?.bank_name}{" "}
                      {bank_information.bank_status && (
                        <span
                          className={`bank-status-button-${bank_information.bank_status}`}
                        >
                          {bank_information.bank_status}{" "}
                          <Tooltip
                            title={`Bank account ${
                              bank_information.bank_status === "pending"
                                ? "pending for approval"
                                : bank_information.bank_status === "processing"
                                ? "processing"
                                : "active"
                            }`}
                          >
                            <InfoIcon
                              sx={{
                                width: "16px",
                                height: "16px",
                                color: "#050f26",
                              }}
                            />
                          </Tooltip>
                        </span>
                      )}
                      <div>
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handleClick}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          <FaEdit />
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() =>
                              handleChangeBankStatus("pending", handleClose)
                            }
                            disableRipple
                          >
                            <MoreHorizIcon />
                            Pending
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleChangeBankStatus("processing", handleClose)
                            }
                            disableRipple
                          >
                            <MoreHorizIcon />
                            Processing
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleChangeBankStatus("active", handleClose)
                            }
                            disableRipple
                          >
                            <MoreHorizIcon />
                            Active
                          </MenuItem>
                        </StyledMenu>
                      </div>
                    </td>
                  ) : (
                    <td> Not Selected</td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
