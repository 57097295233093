import React from "react";
import { useGetEmiPaymentsQuery } from "../../../redux/features/admin/adminApi";
import { usePagination } from "../../../hooks/usePagination";

import UserProfileHook from "../../../hooks/UserProfileHook";
import SingleCompanyHook from "../../../hooks/SingleCompanyHook";
import PaginationNew from "../../../ui/PaginationNew";
import { Link } from "react-router-dom";

export default function RunningEmiList({ emiPayments }) {
  const runningAllEmi = emiPayments?.filter(
    (payment) => payment.status === "pending"
  );

  const {
    currentItems: currentPayments,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    goToPage,
  } = usePagination(runningAllEmi, 5);

  const totalAmount = emiPayments?.reduce((acc, payment) => {
    const amount = parseFloat(payment.total_amount);
    return !isNaN(amount) ? acc + amount : acc;
  }, 0);

  return (
    <div>
      <div className="card  p-2">
        <h6 className="m-1 mb-3">Running EMI List</h6>

        <paymentRecordTable className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Owner</th>
                <th>Company </th>
                <th>Total Amount</th>
                <th>Paid Balance</th>
                <th>Unpaid Balance</th> 
                <th>Remains </th>
                <th>Next </th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {currentPayments.length === 0 ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    No Emi Running
                  </td>
                </tr>
              ) : (
                currentPayments.map((payment, index) => (
                  <tr key={payment._id}>
                    <td>{index + 1}</td>
                    <td>
                      <UserProfileHook _id={payment.owner} />
                    </td>
                    <td>
                      <SingleCompanyHook _id={payment.companyId} />
                    </td>
                    <td>{payment.total_amount}</td>
                    <td>{payment.paidBalance.toFixed()}</td>
                    <td>{payment.remainingBalance.toFixed()}</td>
                    <td>{payment.remainingInstallments}</td>
                    <td>
                      {new Date(
                        payment.nextInstallmentDate
                      ).toLocaleDateString()}
                    </td>
                    <td>
                      <Link
                        className="text-decoration-none"
                        to={`/admin/dashboards/paymentDetails/${payment.transactionId}`}
                      >
                        <div
                          className={`bg text-black text-white text-center p-1  paidStatusBox bg-warning 
                              `}
                        >
                          <div className="text-black">Details</div>
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </paymentRecordTable>

        {/* Pagination */}
        {runningAllEmi.length > 0 && (
          <PaginationNew
            currentPage={currentPage}
            totalPages={totalPages}
            goToPage={goToPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        )}
      </div>
    </div>
  );
}
