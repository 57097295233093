import React, { useEffect, useState } from "react";
import { IoGrid } from "react-icons/io5";
import { FaThList } from "react-icons/fa";
import { useUserDocumentsQuery } from "../../redux/features/files/filesApi";
import { useDispatch, useSelector } from "react-redux";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import Pagination from "../../ui/Pagination"; // Adjust the path as necessary
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { MdSort } from "react-icons/md";
import { ScaleLoader } from "react-spinners";
import useWindowWidth from "../../ui/useWindowWidth";
import socket from "../../socket/socket";
import { setFiles } from "../../redux/features/files/fileSlice";

const Documents = () => {
  const filesData = useSelector((state) => state?.files?.AllFiles);

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const windowWidth = useWindowWidth();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const { data: companyInfo, isLoading: companyLoading } =
    useUserActiveCompanyQuery();
  const activeCompanyId = companyInfo?.data?._id;
  const user = useSelector((state) => state.auth.user);

  const [currentFolder, setCurrentFolder] = useState(null);
  const [activeTab, setActiveTab] = useState("yourLetters");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [folderHistory, setFolderHistory] = useState([]);
  const [viewMode, setViewMode] = useState("list");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Sorting state
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const folders = {
    yourLetters: `UserData/${user?.email}/documents/companyData/${activeCompanyId}/mainDocuments/yourLetters/`,
    statutoryFiles: `UserData/${user?.email}/documents/companyData/${activeCompanyId}/mainDocuments/statutoryFiles/`,
    boardResolutions: `UserData/${user?.email}/documents/companyData/${activeCompanyId}/mainDocuments/boardResolutions/`,
  };

  const {
    data,
    error,
    isLoading: documentsLoading,
    refetch,
  } = useUserDocumentsQuery(currentFolder, { skip: !currentFolder });

  // useEffect(() => {
  //   dispatch(setFiles({ files: data?.data }));
  // }, [data]);

  useEffect(() => {
    socket.on("folder_created", () => {
      refetch();
    });
    socket.on("files_uploaded_to_company", () => {
      refetch();
    });
    socket.on("files_deleted", () => {
      refetch();
    });
    socket.on("files_moved", () => {
      refetch();
    });

    return () => {
      socket.off("folder_created");
      socket.off("files_uploaded_to_company");
      socket.off("files_deleted");
      socket.off("files_moved");
    };
  }, [refetch, data]);

  useEffect(() => {
    if (!companyLoading && activeCompanyId) {
      setCurrentFolder(folders.yourLetters);
    }
  }, [companyLoading, activeCompanyId, user?.email]);

  const handleTabClick = (tab) => {
    if (!loading) {
      setLoading(true);
      setActiveTab(tab);
      setCurrentFolder(folders[tab]);
      setFolderHistory([]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleFolderClick = (folderName) => {
    if (!loading) {
      setLoading(true);
      setFolderHistory([...folderHistory, currentFolder]);
      const newFolder = `${currentFolder}${folderName}`;
      setCurrentFolder(newFolder);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleBackClick = () => {
    if (!loading && folderHistory.length > 0) {
      setLoading(true);
      const newFolderHistory = [...folderHistory];
      const previousFolder = newFolderHistory.pop();
      setFolderHistory(newFolderHistory);
      setCurrentFolder(previousFolder);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Sorting and pagination logic
  const sortAndPaginate = (items) => {
    const sortedItems = [...items].sort((a, b) => {
      if (sortField === "name") {
        return sortOrder === "asc"
          ? (a.name || "").localeCompare(b.name || "")
          : (b.name || "").localeCompare(a.name || "");
      } else if (sortField === "size") {
        return sortOrder === "asc"
          ? (a.size || 0) - (b.size || 0)
          : (b.size || 0) - (a.size || 0);
      } else if (sortField === "date") {
        return sortOrder === "asc"
          ? new Date(a.lastModified || 0) - new Date(b.lastModified || 0)
          : new Date(b.lastModified || 0) - new Date(a.lastModified || 0);
      }
      return 0;
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = sortedItems.slice(
      startIndex,
      startIndex + itemsPerPage
    );
    return paginatedItems;
  };

  const handleSortChange = (event, field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
    handleClose(event); // Make sure to pass the event here
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredFolders = sortAndPaginate(
    data?.data.folders?.filter(
      (folder) =>
        folder.name &&
        folder.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []
  );

  const filteredFiles = sortAndPaginate(
    data?.data.files?.filter(
      (file) =>
        file.filename &&
        file.filename.toLowerCase().includes(searchQuery.toLowerCase()) &&
        file.size > 0
    ) || []
  );

  const totalItems =
    (data?.data.folders?.length || 0) + (data?.data.files?.length || 0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  if (companyLoading || !activeCompanyId || !currentFolder) {
    return (
      <div className="d-flex justify-content-center align-items-end h-100 w-100 ">
        <ScaleLoader className="position-absolute top-50" color="#4c36d6" />
      </div>
    );
  }

  return (
    <div>
      <div className="card p-4 m-4  file-table_docu">
        <div className="">
          <div className="mt-3">
            <h5 className="m-0 document-heading">Documents</h5>
            <p className="m-0 document-heading">
              Here you can find all your company documents such as your
              incorporation certificate
            </p>
          </div>
        </div>
        <div className="">
          <customDocumentTab>
            {" "}
            <div className="d-md-block d-flex justify-content-between">
              {Object.keys(folders).map((key) => (
                <button
                  key={key}
                  className={`tab custom-document-tab ${
                    activeTab === key ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(key)}
                  disabled={loading}
                >
                  {key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}
                </button>
              ))}
            </div>
          </customDocumentTab>

          <input
            type="text"
            placeholder="Search documents..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
          />
          <div className="d-flex justify-content-between back-sec align-items-center mt-3 py-2">
            <nav className="breadcrumb-nav">
              <button
                onClick={handleBackClick}
                disabled={folderHistory.length === 0 || loading}
              >
                &larr; Back
              </button>
              {currentFolder !== folders[activeTab] &&
                currentFolder
                  .replace(folders[activeTab], "")
                  .split("/")
                  .filter(Boolean)
                  .map((crumb, index) => (
                    <span key={index}>
                      {crumb}
                      {index <
                        currentFolder
                          .replace(folders[activeTab], "")
                          .split("/")
                          .filter(Boolean).length -
                          1}
                    </span>
                  ))}
            </nav>
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <div>
                <div>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <MdSort className="sort-menu-icon" />
                  </Button>
                  <Popper
                    className=" custom-dropdown-menu"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={(event) =>
                                  handleSortChange(event, "name")
                                }
                              >
                                Sort By Name
                              </MenuItem>
                              <MenuItem
                                onClick={(event) =>
                                  handleSortChange(event, "size")
                                }
                              >
                                Sort By Size
                              </MenuItem>
                              <MenuItem
                                onClick={(event) =>
                                  handleSortChange(event, "date")
                                }
                              >
                                Sort By Date
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>

              <button
                className="view-button d-flex align-items-center border-0 bg-transparent"
                onClick={() =>
                  setViewMode(viewMode === "list" ? "grid" : "list")
                }
              >
                {viewMode === "list" ? <IoGrid /> : <FaThList />}
              </button>
            </div>
          </div>

          <div className="position-relative ">
            {documentsLoading || loading ? (
              <div className="p-4">
                {" "}
                <div className="d-flex justify-content-center align-items-end h-100 w-100 ">
                  <ScaleLoader
                    className="position-absolute top-50"
                    color="#4c36d6"
                  />
                </div>
              </div>
            ) : (
              <>
                {viewMode === "list" ? (
                  <>
                    {windowWidth >= 768 ? (
                      <table className="file-table file-table_docu w-100">
                        <thead>
                          <tr className="">
                            <th className="table-padding-left">Serial </th>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Type</th>
                            <th>Modified</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredFolders?.map((folder, index) => (
                            <tr
                              key={folder.name}
                              onClick={() => handleFolderClick(folder.name)}
                              className={loading ? "disabled" : ""}
                            >
                              <td className="table-padding-left">
                                {index + 1}
                              </td>
                              <td>
                                <i className="folders_styling2"></i>{" "}
                                <div className="filename">
                                  {folder.name
                                    .replace(currentFolder, "")
                                    .replace(/\//g, "")}
                                </div>
                              </td>
                              <td>—</td>
                              <td>Folder</td>
                              <td>—</td>
                            </tr>
                          ))}
                          {filteredFiles?.map((file, index) => (
                            <tr key={file.key}>
                              <td className="table-padding-left">
                                {index +
                                  1 +
                                  (filteredFolders
                                    ? filteredFolders.length
                                    : 0)}
                              </td>
                              <td>
                                <i className="fas fa-file text-primary files_style"></i>{" "}
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {file.filename.replace(/\//g, "")}
                                </a>
                              </td>
                              <td>{file.size}</td>
                              <td>File</td>
                              <td>
                                {new Date(
                                  file.lastModified
                                ).toLocaleDateString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <table className="file-table file-table_docu w-100">
                        <thead>
                          <tr>
                            <th>Serial</th>
                            <th>Details</th>
                            {/* <th>Size</th>
                          <th>Type</th>
                          <th>Modified</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredFolders?.map((folder, index) => (
                            <tr
                              key={folder.name}
                              onClick={() => handleFolderClick(folder.name)}
                              className={loading ? "disabled" : ""}
                            >
                              <td>{index + 1}</td>
                              <td className="d-flex py-3 gap-1 justify-content-lg-start align-align-items-center">
                                <i className="folders_styling2"></i>{" "}
                                <div className="d-flex  align-items-center table-font-weight">
                                  {folder.name
                                    .replace(currentFolder, "")
                                    .replace(/\//g, "")}
                                </div>
                              </td>
                            </tr>
                          ))}
                          {filteredFiles?.map((file, index) => (
                            <tr key={file.key}>
                              <td>
                                {index +
                                  1 +
                                  (filteredFolders
                                    ? filteredFolders.length
                                    : 0)}
                              </td>
                              <td className="d-flex gap-1 justify-content-lg-start align-align-items-center">
                                <i className="fas fa-file text-primary files_style"></i>{" "}
                                <div className="d-flex flex-column justify-content-center file-table-margin">
                                  <a
                                    className="table-font-weight"
                                    href={file.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {file.filename.replace(/\//g, "")}
                                  </a>
                                  <div className="d-flex gap-2">
                                    <div>
                                      {" "}
                                      Modified :
                                      {new Date(
                                        file.lastModified
                                      ).toLocaleDateString()}
                                    </div>
                                    <div>Size : {file.size}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </>
                ) : (
                  <div className="grid-view row mt-4">
                    {filteredFolders?.map((folder, index) => (
                      <div
                        className="col-lg-3 col-md-4 col-6"
                        key={folder.name}
                      >
                        <div
                          className={`grid-item folder ${
                            loading ? "disabled" : ""
                          }`}
                          onClick={() => handleFolderClick(folder.name)}
                        >
                          <i className="folders_styling"></i>
                          <span className="folder-file-name-absolute ">
                            {folder.name
                              .replace(currentFolder, "")
                              .replace(/\//g, "")}
                          </span>
                        </div>
                      </div>
                    ))}
                    {filteredFiles?.map((file, index) => (
                      <div className="col-lg-3 col-md-4 col-6" key={file.key}>
                        <div className="grid-item file">
                          <i className="fas fa-file text-primary files-styling"></i>
                          <span>
                            <a
                              href={file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.filename.replace(/\//g, "")}
                            </a>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="docu_pagi">
            {/* {(documentsLoading || loading) && <p>Loading...</p>} */}
            {error && <p>Error fetching contents: {error?.message}</p>}

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
