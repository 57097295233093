import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import {
  useBanUserMutation,
  useDeleteUserFromDatabaseMutation,
  useGetUserAllAddedCompanyMutation,
  useMakeUserAdminMutation,
  useRemoveAdminMutation,
} from "../../../../redux/features/admin/adminApi";
import "../admin.css";
import toast from "react-hot-toast";

import { FaCircleUser } from "react-icons/fa6";
const AdminList = ({ admins }) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [userCompanies, setUserCompanies] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [banUser] = useBanUserMutation();
  const [removeAdmin] = useRemoveAdminMutation();
  const [deleteUserFromDatabase] = useDeleteUserFromDatabaseMutation();
  const deleteUser = async (userId) => {
    console.log("Deleting user with ID:", userId);
    try {
      const res = await deleteUserFromDatabase({ userId }).unwrap();
      if (res.success === true) toast.success("User deleted successfully");
    } catch (error) {
      toast.error("Failed to delete user");
      console.error("Failed to delete user:", error);
    }
  };

  const removeAdminFromDatabase = async (userId) => {
    console.log("Making user admin with ID:", userId);
    try {
      const res = await removeAdmin({ userId }).unwrap();
      if (res.success === true) {
        toast.success("User made admin successfully");
      }
    } catch (error) {
      toast.error("Failed to make user admin");
      console.error("Failed to make user admin:", error);
    }
  };

  const banUserFromDatabase = async (userId) => {
    try {
      const { data } = await banUser({ userId });
      if (data?.success === true) {
        toast.success("User banned successfully");
      }
    } catch (error) {
      toast.error("Failed to ban user");
      console.error("Failed to ban user:", error);
    }
  };

  const handleSelectChange = (userId, action) => {
    setSelectedUserId(userId);
    setSelectedAction(action);
  };

  const handleConfirmation = () => {
    if (selectedAction === "delete") {
      deleteUser(selectedUserId);
    } else if (selectedAction === "admin") {
      removeAdminFromDatabase(selectedUserId);
    } else if (selectedAction === "ban") {
      banUserFromDatabase(selectedUserId);
    }
    setSelectedUserId(null);
    setSelectedAction("");
  };

  const filteredUsers = admins?.filter(
    (admin) =>
      admin?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      `${admin?.firstName} ${admin?.lastName}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="card p-4">
      <div className="d-flex justify-content-between align-items-center my-2">
        <h5>All Admins</h5>
        <searchContainer>
          <div className="group">
            <svg viewBox="0 0 24 24" aria-hidden="true" className="icon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
              </g>
            </svg>
            <input
              placeholder="Search by email or name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="input"
              type="search"
            />
          </div>
        </searchContainer>
      </div>
      <div className="overflow-x-scroll">
        <table className="table table-striped c_profile_table c_director_table">
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>

              <th>Role</th>
              <th>Join Date</th>

              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((admin) => {
              const {
                _id,
                avatar,
                email,
                firstName,
                lastName,
                phone,
                role,
                countryCode,
                createdAt,
              } = admin;

              return (
                <tr key={_id}>
                  <td>
                    <div>
                      
                    {avatar ? (
                      <img
                      src={avatar}
                      alt="Profile"
                      className="user-management-avatar"
                      />
                    ) : (
                      <FaCircleUser className="user-management-avatar-icon" />
                    )}
                    </div>
                  </td>
                  <td className="overflow-table-wrapper">
                    {firstName} {lastName}
                  </td>
                  <td>{email}</td>
                  <td>
                    {countryCode}
                    {phone}
                  </td>

                  <td>{role}</td>
                  <td>{new Date(createdAt).toLocaleDateString()}</td>

                  <td>
                    <select
                      className={clsx("border")}
                      aria-label="Project status"
                      onChange={(e) => handleSelectChange(_id, e.target.value)}
                    >
                      <option value="">Select Action</option>
                      <option value="delete">Delete User</option>
                      <option value="admin">Remove Admin</option>
                      <option value="ban">Ban User</option>
                    </select>
                    {selectedUserId === _id && selectedAction && (
                      <button
                        className="confirm_btn"
                        onClick={handleConfirmation}
                      >
                        Confirm
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            onClick={() => paginate(currentPage - 1)}
            className="page-link"
          >
            Previous
          </button>
        </li>
        {Array.from({
          length: Math.ceil(filteredUsers.length / usersPerPage),
        }).map((_, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          >
            <button onClick={() => paginate(index + 1)} className="page-link">
              {index + 1}
            </button>
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === Math.ceil(filteredUsers.length / usersPerPage)
              ? "disabled"
              : ""
          }`}
        >
          <button
            onClick={() => paginate(currentPage + 1)}
            className="page-link"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AdminList;
