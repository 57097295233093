 import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../login/loginsignup.css";
import { useIntroContext } from "../../hooks/useIntroContext";
import nameOfCompany from "../../assets/introflow/svg/2.svg";
import vertical1 from "../../assets/introflow/Number Vertical/1.png";

function NameYourCompany() {
  const { updateCompanyData, companyData } = useIntroContext();
  const [companyName, setCompanyName] = useState("");
  const [explanation, setExplanation] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [explanationError, setExplanationError] = useState("");
  const location = useLocation();
  const fromReview = location.state?.fromReview || false;
  const navigate = useNavigate();

  useEffect(() => {
    if (companyData) {
      setCompanyName(companyData.company_name || "");
      setExplanation(companyData.explanation_of_name || "");
    }
  }, [companyData]);

  const handleGetStarted = () => {
    // Validate fields before proceeding
    const isValid = validateFields();
    if (isValid) {
      const data = {
        company_name: companyName,
        explanation_of_name: explanation,
      };
      updateCompanyData(data);
      if (fromReview === true) {
        navigate("/signup/reviewSubmission");
      } else {
        navigate("/signup/aboutbusiness");
      }
    } else {
      console.log("Form validation failed");
    }
  };

  const validateFields = () => {
    setCompanyNameError("");
    setExplanationError("");
    let isValid = true;

    if (!companyName.trim()) {
      setCompanyNameError("Company name is required");
      isValid = false;
    } else if (companyName.length > 50) {
      setCompanyNameError("Company name cannot exceed 50 characters");
      isValid = false;
    } else {
      setCompanyNameError("");
    }

    if (!explanation.trim()) {
      setExplanationError("Explanation is required");
      isValid = false;
    } else if (explanation.length > 250) {
      setExplanationError("Explanation cannot exceed 250 characters");
      isValid = false;
    } else {
      setExplanationError("");
    }

    return isValid;
  };

  const handleCompanyName = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setCompanyName(value);
      setCompanyNameError("");
    } else {
      setCompanyNameError("Company name cannot exceed 50 characters");
    }
  };

  const handleExplanation = (e) => {
    const value = e.target.value;
    if (value.length <= 250) {
      setExplanation(value);
      setExplanationError("");
    } else {
      setExplanationError("Explanation cannot exceed 250 characters");
    }
  };

  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 position-relative">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  className="position-absolute verticalNumber"
                  src={vertical1}
                  alt=""
                />
                <img
                  src={nameOfCompany}
                  width={400}
                  className=" mt-5 custom-intro-hight intro-flow-img-custom-size"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3 pb-3 mb-5 w-100 log_tex intro-margin-custom m-5">
                <h3>Name Your Company</h3>
                <p>
                  <strong>Instructions for naming your company</strong>
                  <br />
                  Please provide us with a name and a clear explanation of the
                  meaning of the name. It is important you explain abbreviations
                  or non-USA non-English words.
                </p>
                <form>
                  <div className="mb-3">
                    <label htmlFor="companyName">
                      <strong>Company Name</strong>
                    </label>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Your preferred name"
                        className="form-control rounded-0"
                        value={companyName}
                        onChange={handleCompanyName}
                      />
                      <span className="usa_step1">USA</span>
                    </div>
                    {companyNameError && (
                      <div className="text-danger">{companyNameError}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="explanation">
                      <strong>Explanation of name</strong>
                    </label>
                    <textarea
                      name="comments"
                      id="comments"
                      className="form-control rounded-0"
                      placeholder="Please explain the name"
                      value={explanation}
                      onChange={handleExplanation}
                    ></textarea>
                    {explanationError && (
                      <div className="text-danger">{explanationError}</div>
                    )}
                  </div>
                </form>
                {fromReview ? (
                  <button
                    onClick={handleGetStarted}
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Update Info </strong>
                  </button>
                ) : (
                  <button
                    onClick={handleGetStarted}
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Next Step →</strong>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NameYourCompany;
