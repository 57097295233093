import React, { useState } from "react";
import circlePng from "../../../assets/dashboards/circle.png";
import { useGetAllPaymentInfoQuery } from "../../../redux/features/payment/paymentApi";
import UserProfileHook from "../../../hooks/UserProfileHook";
import SingleCompanyHook from "../../../hooks/SingleCompanyHook";
import { Link } from "react-router-dom";
import EmiRecords from "./EmiRecords";

export default function PaymentRecords() {
  const { data: userPayments } = useGetAllPaymentInfoQuery();

  const payments = userPayments?.data || [];

  // Sort payments by date (newest first)
  const sortedPayments = [...payments].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const totalAmount = sortedPayments.reduce((acc, payment) => {
    const amount = Number(payment.total_amount);
    return acc + (isNaN(amount) ? 0 : amount);
  }, 0);

  const [currentPageEmi, setCurrentPageEmi] = useState(1);
  const [currentPageSimple, setCurrentPageSimple] = useState(1);
  const paymentsPerPage = 5;

  const paidPayments = sortedPayments?.filter((payment) => payment.paidStatus);
  const emiPayments = paidPayments?.filter((payment) => payment.emi_option);
  const simplePayments = paidPayments?.filter((payment) => !payment.emi_option);

  const totalPagesEmi = Math.ceil(emiPayments?.length / paymentsPerPage);
  const totalPagesSimple = Math.ceil(simplePayments?.length / paymentsPerPage);

  const handleFirstPage = (setCurrentPage) => setCurrentPage(1);
  const handleLastPage = (setCurrentPage, totalPages) =>
    setCurrentPage(totalPages);
  const handlePreviousPage = (setCurrentPage) =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = (setCurrentPage, totalPages) =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const handlePageClick = (setCurrentPage, pageNumber) =>
    setCurrentPage(pageNumber);

  const renderPageNumbers = (
    currentPage,
    totalPages,
    handleFirstPage,
    handleLastPage,
    handlePageClick,
    setCurrentPage
  ) => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`btn ${currentPage === i ? "btn-primary" : "btn-link"}`}
          onClick={() => handlePageClick(setCurrentPage, i)}
        >
          {i}
        </button>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button
              className="btn btn-link"
              onClick={() => handleFirstPage(setCurrentPage)}
            >
              1
            </button>
            {startPage > 2 && <span>...</span>}
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <button
              className="btn btn-link"
              onClick={() => handleLastPage(setCurrentPage, totalPages)}
            >
              {totalPages}
            </button>
          </>
        )}
      </>
    );
  };

  const renderTable = (
    payments,
    currentPage,
    setCurrentPage,
    totalPages,
    handleFirstPage,
    handleLastPage,
    handlePreviousPage,
    handleNextPage,
    handlePageClick,
    isEmi
  ) => {
    const indexOfLastPayment = currentPage * paymentsPerPage;
    const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
    const currentPayments = payments.slice(
      indexOfFirstPayment,
      indexOfLastPayment
    );

    return (
      <div className="col-12 grid-margin">
        <div className="card  my-2">
          <div className="card-body">
            <h4 className="card-title">
              {isEmi ? "EMI" : "Simple"} Paid Transactions
            </h4>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Owner</th>
                    <th>Company Name</th>
                    <th>Total Amount</th>
                    {isEmi && (
                      <>
                        <th>EMI Amount</th>
                      </>
                    )}
                    <th>Currency</th>
                    <th>Transaction ID</th>
                    <th className="text-center">Payment Status</th>
                    <th>Created At</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPayments?.map((payment, index) => {
                    return (
                      <tr key={payment._id}>
                        <td>{indexOfFirstPayment + index + 1}</td>
                        <td className="overflow-table-wrapper">
                          <UserProfileHook _id={payment.owner} />
                        </td>
                        <td className="overflow-table-wrapper">
                          <SingleCompanyHook _id={payment.company} />
                        </td>
                        <td>
                          <span
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              padding: "0px 2px",
                            }}
                          >
                            ৳
                          </span>
                          {payment.total_amount}
                        </td>
                        {isEmi && (
                          <>
                            <td>
                              <span
                                style={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  padding: "0px 2px",
                                }}
                              >
                                ৳
                              </span>
                              {payment.emi_amount.toFixed(2)}
                            </td>
                          </>
                        )}
                        <td>{payment.currency}</td>
                        <td>{payment.transaction_id}</td>
                        <td className="">
                          <div
                            className={`bg text-black text-center text-white p-1 paidStatusBox bg-${
                              payment.emi_option ? "danger" : "success"
                            }`}
                          >
                            <div className="paidStatusBtn">
                              {payment.emi_option ? "EMI" : "Paid"}
                            </div>
                          </div>
                        </td>
                        <td>
                          {new Date(payment.createdAt).toLocaleDateString()}
                        </td>
                        <td>
                          <Link
                            className="text-decoration-none"
                            to={`/admin/dashboards/paymentDetails/${payment.transaction_id}`}
                          >
                            <div
                              className={`bg text-black text-white text-center p-1  paidStatusBox bg-warning 
                              `}
                            >
                              <div className="text-black">Details</div>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button
                className="btn btn-link"
                onClick={() => handlePreviousPage(setCurrentPage)}
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              {renderPageNumbers(
                currentPage,
                totalPages,
                handleFirstPage,
                handleLastPage,
                handlePageClick,
                setCurrentPage
              )}
              <button
                className="btn btn-link"
                onClick={() => handleNextPage(setCurrentPage, totalPages)}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="m-4">
      <div>
        <staticsUserDetails>
          <div className="d-md-flex gap-3 justify-content-center align-items-center text-center">
            <div className="w-100 card bg-gradient-danger my-md-0 my-2 card-img-holder text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h4 className="font-weight-normal mb-3 pay_ad">
                  Total Amount
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h4>
                <h2 className="mb-3">
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "35px",
                      padding: "0px 2px",
                    }}
                  >
                    ৳
                  </span>
                  {totalAmount}
                </h2>
              </div>
            </div>
            <div className="w-100 card bg-gradient-info my-md-0 my-2 card-img-holder text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h4 className="font-weight-normal mb-3 pay_ad">
                  Total Transaction
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h4>
                <h2 className="mb-3">{sortedPayments.length}</h2>
              </div>
            </div>
          </div>
        </staticsUserDetails>
      </div>
      <paymentRecordTable className="row mt-2">
        {renderTable(
          simplePayments,
          currentPageSimple,
          setCurrentPageSimple,
          totalPagesSimple,
          handleFirstPage,
          handleLastPage,
          handlePreviousPage,
          handleNextPage,
          handlePageClick,
          false
        )}
        {renderTable(
          emiPayments,
          currentPageEmi,
          setCurrentPageEmi,
          totalPagesEmi,
          handleFirstPage,
          handleLastPage,
          handlePreviousPage,
          handleNextPage,
          handlePageClick,
          true
        )}
      </paymentRecordTable>

      <EmiRecords />
    </div>
  );
}
