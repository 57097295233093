import React, { useState } from "react";
import {
  useGetAllCompanyQuery,
  useGetAllCompanyWithFilterQuery,
} from "../../../redux/api/companyApi";
import UserProfileHook from "../../../hooks/UserProfileHook";
import circlePng from "../../../assets/dashboards/circle.png";
import { Link } from "react-router-dom";
import VerifyCompanyTable from "./AdminUi/VerifyCompanyTable";
import SingleCompanyHook from "../../../hooks/SingleCompanyHook";

export default function AllCompanyList() {
  // const { data: companyData } = useGetAllCompanyWithFilterQuery();
  const { data: companyData } = useGetAllCompanyQuery();
  const allCompany = companyData?.data || [];
  console.log(allCompany);
  const [currentPagePaid, setCurrentPagePaid] = useState(1);
  const [currentPageUnpaid, setCurrentPageUnpaid] = useState(1);
  const [
    currentPageDeletedUsersPaidCompany,
    setCurrentPageDeletedUsersPaidCompany,
  ] = useState(1);
  const [currentPageNotVerified, setCurrentPageNotVerified] = useState(1);
  const companiesPerPage = 5;

  const paidCompanies = allCompany?.filter(
    (payment) =>
      payment?.payment_information?.paidStatus && !payment?.is_user_deleted
  );
  const unpaidCompanies = allCompany?.filter(
    (payment) => !payment?.payment_information?.paidStatus 
  );
  const notVerifiedCompanies = allCompany?.filter(
    (pending) =>
      pending?.isVerified === false &&
      pending?.isFilesProvided === true &&
      !pending?.is_user_deleted
  );
  const deletedUsersPaidCompany = allCompany?.filter(
    (deleted) =>
      deleted?.is_user_deleted === true &&
      deleted?.payment_information?.paidStatus
  );

  const totalPagesPaid = Math.ceil(paidCompanies?.length / companiesPerPage);
  const totalPagesUnpaid = Math.ceil(
    unpaidCompanies?.length / companiesPerPage
  );
  const totalPagesNotVerified = Math.ceil(
    notVerifiedCompanies?.length / companiesPerPage
  );

  const totalPagesDeletedUsersPaidCompany = Math.ceil(
    deletedUsersPaidCompany?.length / companiesPerPage
  );

  const handleFirstPagePaid = () => setCurrentPagePaid(1);
  const handleLastPagePaid = () => setCurrentPagePaid(totalPagesPaid);
  const handlePreviousPagePaid = () =>
    setCurrentPagePaid((prev) => Math.max(prev - 1, 1));
  const handleNextPagePaid = () =>
    setCurrentPagePaid((prev) => Math.min(prev + 1, totalPagesPaid));

  const handleFirstPageUnpaid = () => setCurrentPageUnpaid(1);
  const handleLastPageUnpaid = () => setCurrentPageUnpaid(totalPagesUnpaid);
  const handlePreviousPageUnpaid = () =>
    setCurrentPageUnpaid((prev) => Math.max(prev - 1, 1));
  const handleNextPageUnpaid = () =>
    setCurrentPageUnpaid((prev) => Math.min(prev + 1, totalPagesUnpaid));

  const handleFirstPageNotVerified = () => setCurrentPageNotVerified(1);
  const handleLastPageNotVerified = () =>
    setCurrentPageNotVerified(totalPagesNotVerified);
  const handlePreviousPageNotVerified = () =>
    setCurrentPageNotVerified((prev) => Math.max(prev - 1, 1));
  const handleNextPageNotVerified = () =>
    setCurrentPageNotVerified((prev) =>
      Math.min(prev + 1, totalPagesNotVerified)
    );

  const handleFirstPageDeletedUsersPaidCompany = () =>
    setCurrentPageDeletedUsersPaidCompany(1);
  const handleLastPageDeletedUsersPaidCompany = () =>
    setCurrentPageDeletedUsersPaidCompany(totalPagesDeletedUsersPaidCompany);
  const handlePreviousPageDeletedUsersPaidCompany = () =>
    setCurrentPageDeletedUsersPaidCompany((prev) => Math.max(prev - 1, 1));
  const handleNextPageDeletedUsersPaidCompany = () =>
    setCurrentPageDeletedUsersPaidCompany((prev) =>
      Math.min(prev + 1, totalPagesDeletedUsersPaidCompany)
    );

  const handlePageClickPaid = (pageNumber) => setCurrentPagePaid(pageNumber);
  const handlePageClickUnpaid = (pageNumber) =>
    setCurrentPageUnpaid(pageNumber);
  const handlePageClickNotVerified = (pageNumber) =>
    setCurrentPageNotVerified(pageNumber);
  const handlePageClickDeletedUsersPaidCompany = (pageNumber) =>
    setCurrentPageDeletedUsersPaidCompany(pageNumber);

  const renderPageNumbers = (
    currentPage,
    totalPages,
    handleFirstPage,
    handleLastPage,
    handlePageClick
  ) => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`btn ${currentPage === i ? "btn-primary" : "btn-link"}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button className="btn btn-link" onClick={handleFirstPage}>
              1
            </button>
            {startPage > 2 && <span>...</span>}
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <button className="btn btn-link" onClick={handleLastPage}>
              {totalPages}
            </button>
          </>
        )}
      </>
    );
  };

  const renderTable = (
    companies,
    currentPage,
    totalPages,
    handleFirstPage,
    handleLastPage,
    handlePreviousPage,
    handleNextPage,
    handlePageClick
  ) => {
    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentCompany = companies.slice(
      indexOfFirstCompany,
      indexOfLastCompany
    );

    return (
      <div className="col-12 grid-margin">
        <div className="card my-2">
          <div className="card-body">
            <h4 className="card-title">
              {companies[0]?.is_user_deleted ? "Deleted " : ""}
              {companies[0]?.payment_information?.paidStatus
                ? "Paid"
                : "Unpaid"}{" "}
              Companies
            </h4>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Company Name</th>
                    <th>Owner</th>
                    <th>Email</th>
                    <th>Shareholders</th>
                    <th>Directors</th>
                    <th className="text-center">Status</th>
                    <th>Registered</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCompany?.map((item, index) => {
                    return (
                      <tr key={item._id}>
                        <td>{indexOfFirstCompany + index + 1}</td>
                        <td className="overflow-table-wrapper">
                          <SingleCompanyHook _id={item?._id} />
                        </td>
                        <td className="overflow-table-wrapper">
                          <UserProfileHook _id={item?.owner} />
                        </td>
                        <td>{item.company_email}</td>
                        <td>{item.shareholders.length}</td>
                        <td>{item.directors.length}</td>
                        <td className="text-center">
                          <div
                            className={`bg text-black text-white p-1 paidStatusBox bg-${
                              item?.payment_information?.paidStatus
                                ? "success"
                                : "danger"
                            }`}
                          >
                            <div className="paidStatusBtn">
                              {item?.payment_information?.paidStatus
                                ? "Paid"
                                : "Unpaid"}
                            </div>
                          </div>
                        </td>
                        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                        <td>
                          <Link
                            to={`/admin/dashboards/adminCompanyDetails/${item._id}`}
                          >
                            Details
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button
                className="btn btn-link"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              {renderPageNumbers(
                currentPage,
                totalPages,
                handleFirstPage,
                handleLastPage,
                handlePageClick
              )}
              <button
                className="btn btn-link"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="m-4">
      <adminIndexTag className="row">
        <div className="col-md-4 mb-3">
          <div className="card bg-gradient-danger text-white">
            <div className="card-body">
              <img src={circlePng} className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3">
                Total Company
                <i className="mdi mdi-chart-line mdi-24px float-end" />
              </h4>
              <h2 className="mb-3">{allCompany?.length}</h2>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="card bg-gradient-info text-white">
            <div className="card-body">
              <img src={circlePng} className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3">
                Paid Company
                <i className="mdi mdi-chart-line mdi-24px float-end" />
              </h4>
              <h2 className="mb-3">{paidCompanies.length}</h2>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="card bg-gradient-success text-white">
            <div className="card-body">
              <img src={circlePng} className="card-img-absolute" alt="" />
              <h4 className="font-weight-normal mb-3">
                Unpaid Company
                <i className="mdi mdi-chart-line mdi-24px float-end" />
              </h4>
              <h2 className="mb-3">{unpaidCompanies.length}</h2>
            </div>
          </div>
        </div>
      </adminIndexTag>
      <paymentRecordTable className="row">
        {/* {totalPagesNotVerified > 0 && (
          <VerifyCompanyTable
            companies={notVerifiedCompanies}
            currentPage={currentPageNotVerified}
            totalPages={totalPagesNotVerified}
            handleFirstPage={handleFirstPageNotVerified}
            handleLastPage={handleLastPageNotVerified}
            handlePreviousPage={handlePreviousPageNotVerified}
            handleNextPage={handleNextPageNotVerified}
            handlePageClick={handlePageClickNotVerified}
          />
        )} */}

        <VerifyCompanyTable
          companies={notVerifiedCompanies}
          currentPage={currentPageNotVerified}
          totalPages={totalPagesNotVerified}
          handleFirstPage={handleFirstPageNotVerified}
          handleLastPage={handleLastPageNotVerified}
          handlePreviousPage={handlePreviousPageNotVerified}
          handleNextPage={handleNextPageNotVerified}
          handlePageClick={handlePageClickNotVerified}
        />
        {renderTable(
          paidCompanies,
          currentPagePaid,
          totalPagesPaid,
          handleFirstPagePaid,
          handleLastPagePaid,
          handlePreviousPagePaid,
          handleNextPagePaid,
          handlePageClickPaid
        )}
        {renderTable(
          unpaidCompanies,
          currentPageUnpaid,
          totalPagesUnpaid,
          handleFirstPageUnpaid,
          handleLastPageUnpaid,
          handlePreviousPageUnpaid,
          handleNextPageUnpaid,
          handlePageClickUnpaid
        )}
        {renderTable(
          deletedUsersPaidCompany,
          currentPageDeletedUsersPaidCompany,
          totalPagesDeletedUsersPaidCompany,
          handleFirstPageDeletedUsersPaidCompany,
          handleLastPageDeletedUsersPaidCompany,
          handlePreviousPageDeletedUsersPaidCompany,
          handleNextPageDeletedUsersPaidCompany,
          handlePageClickDeletedUsersPaidCompany
        )}
      </paymentRecordTable>
    </div>
  );
}
