import React, { useState } from "react";
import "../login/loginsignup.css";
import makepaymentSvg from "../../assets/introflow/svg/12.svg";
import { useIntroContext } from "../../hooks/useIntroContext";
import { useMakePaymentMutation } from "../../redux/features/payment/paymentApi";

import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
const MakePayment = () => {
  const { data: companyInfo } = useUserActiveCompanyQuery();
  const company_information = companyInfo?.data;

  const { selectedPlanData } = useIntroContext();

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const [makePayment, { isLoading, isError, data, error }] =
    useMakePaymentMutation();
  const handlePayment = async () => {
    const allInformations = {
      company_information,
      selected_plan: selectedPlanData?.selected_plan,
    };
    console.log(allInformations);
    try {
      const result = await makePayment(allInformations).unwrap();
      console.log(result);

      if (result?.url) {
        window.location.replace(result.url);
      } else {
        console.log("Payment URL not found in response.");
      }
    } catch (err) {
      console.error("Payment error:", err);
    }
  };

  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  src={makepaymentSvg}
                  width={400}
                  className="intro-flow-img-custom-size mt-5 custom-intro-hight "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className="px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h5 className="get_started_text text-center ">
                  Make payment for your incorporation
                </h5>
                <p className="text-center ">
                  Use the checkout form below to complete payment using your
                  debit or credit card.
                </p>
                <div className="tab_border">
                  <div className="dropdown tab_con">
                    <div className="tab" onClick={toggleExpansion}>
                      <strong>SSM Incorporation Fee USA</strong>
                    </div>

                    {isExpanded && (
                      <div className="additional-text">
                        This is the fee we pay on your behalf to the government
                        of USA for the purpose of incorporating your USA..
                      </div>
                    )}
                  </div>

                  <div className="dropdown tab_con">
                    <div className="tab" onClick={toggleExpansion}>
                      <strong>Incorporation Service</strong>
                    </div>

                    {isExpanded && (
                      <div className="additional-text">
                        This is the all-inclusive fee for the incorporation of
                        your Sdn Bhd. This fee includes our service fee, costs
                        for KYC & bankruptcy checks, opening bank accounts and
                        everything else needed for starting your business.
                      </div>
                    )}
                  </div>

                  <div className="dropdown tab_con">
                    <div className="tab" onClick={toggleExpansion}>
                      <strong>Corporate Secretary</strong>
                    </div>

                    {isExpanded && (
                      <div className="additional-text">
                        In accordance with the Companies Act of 2016 it is
                        required to appoint a company secretary, have a
                        registered office, file annual returns and more. This
                        fee includes everything that you require in the ordinary
                        course of business. Including access to our platform.
                      </div>
                    )}
                  </div>
                </div>

                <button
                  onClick={handlePayment}
                  className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                >
                  <strong>Next Step →</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePayment;
