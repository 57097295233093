import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../login/loginsignup.css";
import { IntroContext } from "../../providers/IntroProvider";
import ShareHolderModal from "./components/shareHolderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTrash } from "react-icons/fa";
import shareholderSvg from "../../assets/introflow/svg/8.svg";
import vertical5 from "../../assets/introflow/Number Vertical/5.png";
import { AuthContext } from "../../providers/AuthProvider";

function ShareHolders() {
  const navigate = useNavigate();
  const location = useLocation();
  const fromReview = location.state?.fromReview || false;
  const { companyData, updateCompanyData } = useContext(IntroContext);

  const directors = companyData?.directors || [];
  console.log(directors, "directors");
  const [singleShareholder, setSingleShareholder] = useState([
    ...directors.map((director) => ({
      shareholder_type: "individual",
      shareholder_name: director?.full_name,
      shareholder_email: director?.directors_email,
      shareholder_country_code: director?.directors_country_code,
      shareholder_phone_number: director?.directors_phone_number,
      share: "",
    })),
  ]);

  const [shareCount, setShareCount] = useState(1000);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isShareFilled, setIsShareFilled] = useState(false); // State to track if shares are filled

  useEffect(() => {
    let totalShares = 0;
    singleShareholder.forEach((shareholder) => {
      if (shareholder.share) {
        totalShares += parseInt(shareholder.share);
      }
    });

    const newShareCount = 1000 - totalShares;

    if (newShareCount < 0) {
      setShareCount(0);
      setErrorMessage("Share count exceeded. Please adjust the shares.");
    } else {
      setShareCount(newShareCount);
      setErrorMessage("");
    }

    const allSharesFilled = singleShareholder.every(
      (shareholder) => shareholder.share && shareholder.share.trim() !== ""
    );
    setIsShareFilled(allSharesFilled);
  }, [singleShareholder]);

  const handleSubmit = () => {
    if (!isShareFilled) {
      setErrorMessage("Please fill all share fields.");
      return;
    }

    const data = {
      shareholders: singleShareholder,
    };

    updateCompanyData(data);

    navigate("/signup/reviewSubmission");
  };

  // const handleModal = () => {
  //   setIsOpen(true);
  //   setSingleShareholder([...singleShareholder]);
  // };

  // const handleDeleteShareholder = (email) => {
  //   let filteredEmails = singleShareholder.filter(
  //     (item) => item.shareholder_email !== email
  //   );
  //   setSingleShareholder(filteredEmails);
  // };

  const handleShare = (event, email) => {
    const addedShare = event.target.value;
    const index = singleShareholder.findIndex(
      (item) => item.shareholder_email === email
    );
    if (index !== -1) {
      const updatedShareholder = {
        ...singleShareholder[index],
        share: addedShare,
      };
      const updatedShareholders = [...singleShareholder];
      updatedShareholders[index] = updatedShareholder;
      setSingleShareholder(updatedShareholders);
    }
  };

  return (
    <div className="">
      <div className="intro-flow-bg">
        <ShareHolderModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          singleShareholder={singleShareholder}
          setSingleShareholder={setSingleShareholder}
        ></ShareHolderModal>

        <div className="row ">
          <div className="col-md-5 z-0">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  className="position-absolute verticalNumber z-5"
                  src={vertical5}
                  alt=""
                />
                <img
                  src={shareholderSvg}
                  width={400}
                  className="shareholder-index mt-5 custom-intro-hight "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7  ">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3 pb-3 mb-5 w-100 intro-margin-custom m-5">
                <h3>Shareholders</h3>
                <p>
                  These are the owners of the company, represented through
                  shares.
                </p>
                <h5 className="youhaveshare">
                  You have <strong>{shareCount}</strong> / 1000 shares left to
                  distribute
                </h5>

                {errorMessage && <p className="text-danger">{errorMessage}</p>}

                <div>
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        {/* <th scope="col">Action</th> */}
                        <th scope="col">Name</th>
                        <th scope="col"># OF SHARES</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {singleShareholder?.map((item, index) => (
                        <tr key={index} className=" ">
                          {/* <td className="col-md-3 d-flex justify-content-center align-items-center "> */}
                          {/* <td className="col-md-2">
                            <FaTrash
                              onClick={() =>
                                handleDeleteShareholder(item.shareholder_email)
                              }
                              className="border-black border-2"
                            />
                          </td> */}

                          <td className="col-md-8">
                            {/* <div className="col-md-9  d-flex justify-content-between  w-100 align-items-center"> */}
                            {item.shareholder_companyName ? (
                              <div>
                                <div>{item.shareholder_companyName}</div>
                                <div className="">{item.shareholder_email}</div>
                              </div>
                            ) : (
                              <div>
                                <div>{item.shareholder_name}</div>
                                <div className="">{item.shareholder_email}</div>
                              </div>
                            )}{" "}
                          </td>
                          <td className="col-md-4">
                            <input
                              type="number"
                              placeholder="Add share"
                              name="share"
                              value={item.share}
                              onChange={(event) =>
                                handleShare(event, item.shareholder_email)
                              }
                              className="form-control rounded-0 "
                            />
                            {/* </div> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="py-2">
                  {shareCount !== 0 && (
                    <n className="text-danger ">
                      Shares must be divided by all shareholders.
                    </n>
                  )}
                </div>

                {fromReview ? (
                  <button
                    onClick={handleSubmit}
                    disabled={
                      !isShareFilled ||
                      singleShareholder.length === 0 ||
                      shareCount !== 0 ||
                      errorMessage
                    }
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Update</strong>
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    disabled={
                      !isShareFilled ||
                      singleShareholder.length === 0 ||
                      shareCount !== 0 ||
                      errorMessage
                    }
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                  >
                    <strong>Next Step →</strong>
                  </button>
                )}

                {/* <button
                  onClick={handleSubmit}
                  disabled={
                    !isShareFilled ||
                    singleShareholder.length === 0 ||
                    shareCount !== 0 ||
                    errorMessage
                  }
                  className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                >
                  <strong>Next Step →</strong>
                </button> */}
                {/* <button
                  onClick={() => handleModal()}
                  className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                >
                  <strong>Add a shareholder +</strong>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareHolders;
