import React from "react";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import { MdVerified } from "react-icons/md";
import { Button, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useGetBankDetailsQuery } from "../../redux/features/banks/bankApi";
export default function CompanyProfile() {
  const { data: companyInfo } = useUserActiveCompanyQuery();
  const company_information = companyInfo?.data;
  const {
    _id,
    company_name,
    explanation_of_name,
    about,

    number_of_share,
    company_email,
    company_phone_number,
    company_country_code,
    address_line_one,
    nomber_of_share,
    directors,
    shareholders,
    payment_information,
    createdAt,
    isVerified,
    bank_information,
  } = company_information || {};

  const { data: bankData } = useGetBankDetailsQuery(
    bank_information?.selected_bank
  );
  const bank_data = bankData?.data;

  return (
    <div>
      {" "}
      <div className="">
        <div className="card p-4 mt-3 ">
          <div className="">
            <div style={{ marginBottom: "14px" }}>
              <h3 className="text-black m-0 p-0 d-flex align-items-center">
                <div
                  style={{ overflowWrap: "anywhere" }}
                  className="d-flex gap-1 align-items-center justify-content-center"
                >
                  <div>{company_name} </div>
                  {payment_information?.paidStatus === true && (
                    <div className="d-flex gap-1 align-items-center justify-content-center">
                      <Tooltip
                        className="d-flex align-items-center"
                        title="Company is verified"
                        placement="top"
                      >
                        {isVerified && (
                          <>
                            <div className="d-flex align-items-center">
                              <Tooltip
                                title="Company is verified by admin ✅"
                                placement="top"
                              >
                                <div className="d-flex align-items-center">
                                  <MdVerified className=" text-primary" />
                                </div>
                              </Tooltip>
                            </div>
                            <div className="activeCompany-small-text2">
                              Verified &
                            </div>
                          </>
                        )}
                      </Tooltip>
                    </div>
                  )}
                </div>

                <span className="activeCompany-small-text">Active</span>
              </h3>
              <p className="m-0 p-0 data_p_index">{_id} 🟢</p>
            </div>
            <table className="table-responsive table table-striped">
              <tbody className="c_profile_table c_director_table">
                <tr className="table-mobile-responsive-row">
                  <th className="company_table_head_data">Official Name</th>
                  <td className="overflow-direct-wrapper company_table_body_data">
                    {company_name}
                  </td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th>Registration Number</th>
                  <td className="overflow-direct-wrapper ">{_id}</td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th>Incorporation Date</th>
                  <td className="overflow-direct-wrapper ">
                    {" "}
                    {new Date(createdAt).toLocaleDateString()}
                  </td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th className="overflow-direct-wrapper ">
                    Company Secretary
                  </th>
                  <td className="overflow-direct-wrapper ">
                    {directors?.map((director) => (
                      <div key={director} className="">
                        {director.full_name} <br />
                        {director.role_of_directors}
                      </div>
                    ))}
                  </td>
                </tr>

                <tr className="table-mobile-responsive-row">
                  <th>Total Shares</th>
                  <td>${number_of_share}</td>
                </tr>
                <tr className="table-mobile-responsive-row">
                  <th>Business Address</th>
                  <td className="overflow-direct-wrapper ">
                    {address_line_one}
                  </td>
                </tr>
                {bank_information?.selected_bank ? (
                  <tr className="table-mobile-responsive-row">
                    <th scope="row">Selected Bank</th>
                    <td className="overflow-direct-wrapper ">
                      <div className="d-flex gap-2 align-items-center">
                        {bank_data?.bank_name} {""}
                        {bank_information.bank_status === "pending" && (
                          <span className="bank-status-button-pending d-flex justify-content-center align-items-center gap-2">
                            Pending{" "}
                            <Tooltip title="Bank account pending for approval.">
                              <InfoIcon
                                sx={{
                                  width: "16px",
                                  height: "16px",
                                  color: "#050f26",
                                }}
                              />
                            </Tooltip>
                          </span>
                        )}
                        {bank_information.bank_status === "processing" && (
                          <span className="bank-status-button-processing d-flex justify-content-center align-items-center gap-2">
                            Processing{" "}
                            <Tooltip title="Bank account processing">
                              <InfoIcon
                                sx={{
                                  width: "16px",
                                  height: "16px",
                                  color: "#050f26",
                                }}
                              />
                            </Tooltip>
                          </span>
                        )}
                        {bank_information.bank_status === "active" && (
                          <span className="activeCompany-small-text d-flex justify-content-center align-items-center gap-2">
                            Active{" "}
                            <Tooltip title="Bank account active">
                              <InfoIcon
                                sx={{
                                  width: "16px",
                                  height: "16px",
                                  color: "#050f26",
                                }}
                              />
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
