import React from "react";
import { Link, useParams } from "react-router-dom";
import { useGetSinglePaymentInfoByTransactionIdApiQuery } from "../../../redux/features/payment/paymentApi";
import {
  useGetSingleUserQuery,
  useSelectedPlanQuery,
} from "../../../redux/features/admin/adminApi";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdAdminPanelSettings } from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { FaCcMastercard } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import EmiDetails from "./../Admin/AdminUi/EmiDetails";
export default function UserPaymentDetails() {
  const { _id } = useParams();

  const { data: payment } = useGetSinglePaymentInfoByTransactionIdApiQuery(_id);
  const paymentInfo = payment?.data || {};

  const { data: userData } = useGetSingleUserQuery(paymentInfo?.owner);
  const user = userData?.data || {};
  const { data: servicePlan } = useSelectedPlanQuery(paymentInfo?.service_plan);
  const service_plan = servicePlan?.data || {};

  const { title, category, price, description } = service_plan;
  const {
    total_amount,
    card_type,
    card_issuer_country,
    card_brand,
    card_issuer,
    bank_tran_id,
    currency,
    transaction_id,
    val_id,
    paidStatus,
    validEndOn,
    store_amount,
    card_no,
    tran_date,
    emi_option,
    emi_max_inst_option,
    emi_selected_inst,
    emi_amount,
    emi_inst_amount,
  } = paymentInfo;
  const { firstName, lastName, email, phone, countryCode, createdAt, role } =
    user;

  return (
    <paymentDetails className="m-5">
      <transactionCard className="w-100 ">
        <div className="rating-card2 d-flex justify-content-center mx-4 mb-4">
          <div
            style={{ fontSize: "22px" }}
            className="text-primary-tr text-center "
          >
            Payment Details Information
          </div>
        </div>
      </transactionCard>
      <section className="d-md-flex gap-4 mx-4 ">
        <section>
          <section className="w-100   paymentDetails-card text-white">
            <div className="d-flex justify-content-between align-items-center p-3">
              <div>
                <strong>User ID:</strong> <br />
                <i>{user?._id}</i>
              </div>
            </div>
            <div class="bg-danger border-1 border-top border-secondary" />
            <div className="mt-3">
              <div className="d-flex justify-content-between align-items-center card-info-holder g-4 mx-2 p-3 gap-3 ">
                <div className="d-flex gap-2 align-items-center ">
                  <div>
                    <FaUser
                      style={{
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </div>
                  <div>
                    <h5 className="m-0 overflow-direct-wrapper">
                      {firstName} {lastName}
                    </h5>
                  </div>
                </div>
                <div>
                  <h5 className="m-0">
                    {" "}
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        padding: "0px 2px",
                      }}
                    >
                      ৳
                    </span>
                    {total_amount}
                  </h5>
                </div>
              </div>
              <div className="d-md-flex gap-2">
                <div className="w-100 d-flex flex-column my-2 ">
                  <div className="  d-flex align-items-center card-info-holder g-4 mx-2 p-3 gap-3">
                    <div className="">
                      <MdEmail
                        style={{
                          height: "30px",
                          width: "30px",
                        }}
                      />
                    </div>
                    <div>
                      <div>
                        <strong>Email:</strong>
                      </div>
                      <div>{email}</div>
                    </div>
                  </div>

                  <div className=" mt-2 d-flex align-items-center card-info-holder g-4 mx-2 p-3 gap-3">
                    <div className="">
                      <FaPhoneVolume
                        style={{
                          height: "30px",
                          width: "30px",
                        }}
                      />
                    </div>
                    <div>
                      <div>
                        <strong>Phone:</strong>
                      </div>
                      <div>
                        {countryCode}
                        {phone}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex flex-column my-2 ">
                  <div className=" d-flex align-items-center card-info-holder g-4 mx-2 p-3 gap-3">
                    <div className="">
                      <BsFillCalendarDateFill
                        style={{
                          height: "30px",
                          width: "30px",
                        }}
                      />
                    </div>
                    <div>
                      <div>
                        <strong>Joined At</strong>
                      </div>
                      <div> {new Date(createdAt).toLocaleDateString()}</div>
                    </div>
                  </div>

                  <div className=" d-flex mt-2 align-items-center card-info-holder g-4 mx-2 p-3 gap-3">
                    <div className="">
                      <MdAdminPanelSettings
                        style={{
                          height: "40px",
                          width: "40px",
                        }}
                      />
                    </div>
                    <div>
                      <div>
                        <strong>Role:</strong>
                      </div>
                      <div>{role}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <priceCard>
            <newPriceCard>
              <div
                style={{
                
                  background: "#0f0c29",
                  background:
                    "linear-gradient(to right bottom, #443e89, #0f0c29)",
                 
                  borderRadius: "20px",
                }}
                className="d-md-flex paymentDetails_select_package  gap-4 mb-4 "
              >
                <div>
                  <h5 className="my-2 text-white text-center">
                    Selected Package
                  </h5>
                </div>

                <div className="card  col-md-12 md-mt-0 mt-2">
                  <div
                    style={{
                      background: "#0f0c29",
                      background:
                        "linear-gradient(to right bottom, #5f51ff, #000000)",
                      color: "white",
                    }}
                    className={` 
                                         bg-outline-success
                                    `}
                  >
                    <div className="card-header  header-basic">
                      <h2 className="py-2 text-center">
                        {title}{" "}
                        <span
                          style={{
                            fontWeight: "700",
                            fontSize: "35px",
                            padding: "0px 2px",
                          }}
                        >
                          ৳
                        </span>
                        {price}{" "}
                      </h2>
                    </div>
                    <div className="p-4">
                      {/* <h4
                        style={{
                          textAlign: "center",
                          background: "#2dbdff",
                          padding: "20px",
                          borderRadius: "20px",
                          color: "white",
                        }}
                      >
                        Price BDT: {price}
                      </h4> */}
                      <ul className="list-unstyled">
                        {description?.map((desc, descIndex) => (
                          <li key={descIndex}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              style={{
                                height: "25px",
                                marginRight: "5px",
                              }}
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              ></path>
                            </svg>
                            {desc}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </newPriceCard>
          </priceCard>
        </section>
        <section className="w-100 h-100  ">
          <priceStatusCard className="border border-1 h-100 ">
            <div className="card">
              <span className="small-text">Payment Information</span>
              <span className="title">
                {" "}
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "35px",
                    padding: "0px 2px",
                  }}
                >
                  ৳
                </span>
                {total_amount} Payment Done
              </span>
              {/* <span className="desc">
                Download Lorem mobile banking app for IOS & Android to manage
                your online money.
              </span> */}
              <div className="text-white mt-4">
                <p>
                  <strong>Plan:</strong> {title}
                </p>
                <p>
                  <strong>Card Issuer Country:</strong> {card_issuer_country}
                </p>
                <p>
                  <strong>Card Issuer:</strong> {card_issuer}
                </p>
                <p>
                  <strong>Currency Code:</strong> {currency}
                </p>
              </div>
              <div className="buttons">
                <div className="button d-flex align-items-center gap-2 ">
                  <FaCcMastercard
                    style={{
                      height: "34px",
                      width: "34px",
                      color: "white",
                    }}
                  />

                  <div className="button-text google">
                    <span>Payment By</span>
                    <span>{card_type}</span>
                  </div>
                </div>
                <div className="button">
                  <BsBank2
                    style={{
                      height: "30px",
                      width: "30px",
                      color: "black",
                    }}
                  />

                  <div className="button-text apple">
                    <span>Card Brand</span>
                    <span>{card_brand}</span>
                  </div>
                </div>
              </div>
            </div>
          </priceStatusCard>
          <section className="d-md-flex h-100 gap-4">
            <transactionCard className="w-100 border border-1 h-100 card">
              <div className="rating-card">
                <div className="text-wrapper-tr">
                  <div style={{ fontSize: "17px" }} className="text-primary-tr">
                    All Transaction Information
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Transaction ID</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {transaction_id}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Validate ID</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {val_id}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Bank Transaction ID</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {bank_tran_id}
                  </div>
                </div>
              </div>
            </transactionCard>

            <transactionCard className="w-100 border border-1 mt-md-0 mt-3 h-100 card">
              <div className="rating-card">
                <div className="text-wrapper-tr">
                  <div style={{ fontSize: "17px" }} className="text-primary-tr">
                    Balance Information
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Total Amount</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {total_amount}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Store Amount</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {store_amount}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Currency </div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {currency}
                  </div>
                </div>
              </div>
            </transactionCard>
          </section>
          <section className="d-md-flex h-100 gap-4 mt-4">
            <transactionCard className="mt-md-0 mt- w-100 border border-1 h-100 card">
              <div className="rating-card">
                <div className="text-wrapper-tr">
                  <div style={{ fontSize: "17px" }} className="text-primary-tr">
                    Time Information
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Payment Time </div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {new Date(paymentInfo.createdAt).toLocaleTimeString()} -{" "}
                    {new Date(paymentInfo.createdAt).toLocaleDateString()}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Successful Time</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {new Date(tran_date).toLocaleTimeString()} -{" "}
                    {new Date(tran_date).toLocaleDateString()}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Valid End Date & Time</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {new Date(validEndOn).toLocaleTimeString()} -{" "}
                    {new Date(validEndOn).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </transactionCard>

            <transactionCard className="w-100 mt-md-0 mt-3 border border-1 h-100 card">
              <div className="rating-card">
                {!emi_option ? (
                  <div
                    style={{ minHeight: "180px" }}
                    className=" d-flex justify-content-center align-items-center text-primary-tr"
                  >
                    No EMI
                  </div>
                ) : (
                  <>
                    <div className="text-wrapper-tr">
                      <div
                        style={{ fontSize: "17px" }}
                        className="text-primary-tr"
                      >
                        EMI Informations
                      </div>
                    </div>
                    <div className="socials-container ">
                      <div className="text-primary-tr ">EMI Amount</div>
                      <div style={{ fontSize: "12px" }} className="text-white ">
                        {emi_amount}
                      </div>
                    </div>

                    <div className="socials-container ">
                      <div className="text-primary-tr ">Total Amount </div>
                      <div style={{ fontSize: "12px" }} className="text-white ">
                        {total_amount}
                      </div>
                    </div>
                    <div className="socials-container ">
                      <div className="text-primary-tr ">
                        EMI Instalment Month
                      </div>
                      <div style={{ fontSize: "12px" }} className="text-white ">
                        {emi_selected_inst}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </transactionCard>
          </section>
        </section>
      </section>
      {emi_option && (
        <section>
          <EmiDetails transaction_id={transaction_id} />
        </section>
      )}
    </paymentDetails>
  );
}
