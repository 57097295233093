import baseApi from "../../api/baseApi";

const paymentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    makePayment: builder.mutation({
      query: (allInformations) => ({
        url: "/payment/make-payment",
        method: "POST",
        body: allInformations,
      }),
      invalidatesTags: ["company", "user"],
    }),
    getAllPaymentInfo: builder.query({
      query: () => "/payment/get-all-payment-info",
      providesTags: ["payment"],
    }),
    getSinglePaymentInfo: builder.query({
      query: (current_payment_id) =>
        `/payment/get-single-payment-info/${current_payment_id}`,
      providesTags: ["payment"],
    }),
    getSinglePaymentInfoByTransactionIdApi: builder.query({
      query: (transaction_id) =>
        `/payment/get-single-payment-info-by-transactionId/${transaction_id}`,
      providesTags: ["payment"],
    }),
    getCompanyPayments: builder.query({
      query: (companyId) =>
        `/payment/get-company-all-payment-info/${companyId}`,
      providesTags: ["payment"],
    }),
  }),
});

export const {
  useMakePaymentMutation,
  useGetAllPaymentInfoQuery,
  useGetSinglePaymentInfoQuery,
  useGetSinglePaymentInfoByTransactionIdApiQuery,
  useGetCompanyPaymentsQuery,
} = paymentApi;
