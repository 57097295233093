import { useParams } from "react-router-dom";
import { useGetSingleCompanyQuery } from "../../../redux/api/companyApi";
import AdminCompanyProfile from "./AdminUi/AdminCompanyProfile";
import AdminCompanyShareholders from "./AdminUi/AdminCompanyShareholders";
import AdminCompanyDirectors from "./AdminUi/AdminCompanyDirectors";
import circlePng from "../../../assets/dashboards/circle.png";

import { useGetSingleUserQuery } from "../../../redux/features/admin/adminApi";
import UserDocuments from "./AdminUi/UserDocuments";
export default function AdminCompanyDetails() {
  const { _id } = useParams();
  const { data: singleCompany, isLoading: companyLoading } =
    useGetSingleCompanyQuery(_id);
  const company_information = singleCompany?.data;
  const { data: userData } = useGetSingleUserQuery(company_information?.owner);
  const user = userData?.data;

  return (
    <>
      <div className=" m-4">
        <staticsUserDetails>
          <div className="d-md-flex gap-3 justify-content-center align-items-center text-center">
            <div className=" w-100  card bg-gradient-danger card-img-holder text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h5 className="font-weight-normal mb-3 ">
                  Number Of Shareholders
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h5>
                <h4 className="mb-3">
                  {company_information?.shareholders?.length}
                </h4>
              </div>
            </div>
            <div className=" w-100 card bg-gradient-info card-img-holder text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h5 className="font-weight-normal mb-3 ">
                  Number Of Directors
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h5>
                <h4 className="mb-3">
                  {company_information?.directors?.length}
                </h4>
              </div>
            </div>
            <div className=" w-100 card bg-gradient-success card-img-holder text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h5 className="font-weight-normal mb-3 ">
                  Share Count
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h5>
                <h4 className="mb-3">$1000</h4>
              </div>
            </div>
          </div>
        </staticsUserDetails>
        <AdminCompanyProfile company_information={company_information} />
        <AdminCompanyShareholders company_information={company_information} />
        <AdminCompanyDirectors company_information={company_information} />
        <div>
          <UserDocuments
            user={user}
            company_information={company_information}
            companyLoading={companyLoading}
          />
        </div>
      </div>
    </>
  );
}
