import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import IntroProvider from "./providers/IntroProvider";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import router from "./Routes/Routes";
import AuthProvider from "./providers/AuthProvider";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import MaintenancePage from "./ui/MaintenancePage";

const isMaintenance = process.env.REACT_APP_IS_MAINTENANCE;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    {isMaintenance === "true" ? (
      <MaintenancePage />
    ) : (
      <Provider store={store}>
        <AuthProvider>
          <PersistGate loading={null} persistor={persistor}>
            <IntroProvider>
              <RouterProvider router={router} />
              <Toaster
                toastOptions={{
                  maxToasts: 3, // Limit the number of toasts
                }}
              />
            </IntroProvider>
          </PersistGate>
        </AuthProvider>
      </Provider>
    )}
  </>
  /* </React.StrictMode> */
);

reportWebVitals();
