import React, { useState } from "react";

import AddNewShareHolderEdit from "./AddNewShareHolderEdit";
import { useSelector } from "react-redux";

export default function ShareholderEdit({ company_information }) {
  const [anchorEls, setAnchorEls] = useState({});
  const companyData = company_information;
  const { _id, shareholders } = companyData || {};
  const allShareholders = useSelector(
    (state) => state.nonPersistCompany.allDirectors
  );
  return (
    <div>
      <div className="bg-white p-3  rounded-3">
        {allShareholders?.length < 1 ? (
          <div>
            <h4 className="text-center">No shareholders yet</h4>
            <div>Must have at least one shareholder</div>
          </div>
        ) : (
          <div>
            <AddNewShareHolderEdit
              shareholders={allShareholders}
              company_id={_id}
            />
          </div>
        )}
      </div>
    </div>
  );
}
