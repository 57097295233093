import React, { useState, useEffect, useRef } from "react";
import "../../components/login/loginsignup.css";
import "../../components/dashboardsfiles/dashboard.css";
import logoSvg from "../../../src/assets/logo.svg";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
  FaUniversity,
  FaTags,
  FaSchool,
  FaUserCircle,
  FaFolderOpen,
  FaFile,
} from "react-icons/fa";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import {
  useGetUserAddedCompanyQuery,
  usePostActiveCompanyIdMutation,
} from "../../redux/api/userApi";

import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApi";

const AdminDashboard = () => {
  const { data: userData } = useGetCurrentUserQuery();
  const user = userData?.data;

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const sidebarRef = useRef(null);
  const accountMenuRef = useRef(null);

  const activeCompany = user?.activeCompany;
  useEffect(() => {
    !isOpen && setIsAccountMenuOpen(false);
  }, [isOpen]);
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // const isMobileView = window.innerWidth <= 768;
    const handleClickOutside = (event) => {
      if (
        accountMenuRef.current &&
        !accountMenuRef.current.contains(event.target) &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setIsAccountMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleAccountMenuMain = () => {
    if (isAccountMenuOpen === true) {
      setIsAccountMenuOpen(false);
    }
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const closeAccountMenu = () => {
    setIsAccountMenuOpen(false);
  };

  const location = useLocation();

  const isSidebarPage =
    [
      "/admin/dashboards/index",
      "/admin/dashboards/userManagement",
      "/admin/dashboards/paymentRecords",
      "/admin/dashboards/userDetails",
      "/admin/dashboards/adminCompanyDetails",
      "/admin/dashboards/adminProfileSetting",
      "/admin/dashboards/adminUpdatePassword",
      "/admin/dashboards/allCompanyList",
    ].includes(location.pathname) ||
    location.pathname.match(/^\/admin\/dashboards\/userDetails\/\w+$/) ||
    location.pathname.match(
      /^\/admin\/dashboards\/adminCompanyDetails\/\w+$/
    ) ||
    location.pathname.match(
      /^\/admin\/dashboards\/adminEditUserProfile\/\w+$/
    ) ||
    location.pathname.match(
      /^\/admin\/dashboards\/editCompanyInformation\/\w+$/
    ) ||
    location.pathname.match(/^\/admin\/dashboards\/paymentDetails\/\w+$/);

  const menuItem = [
    {
      path: "index",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "userManagement",
      name: "Manage User",
      icon: <FaSchool />,
    },
    {
      path: "paymentRecords",
      name: "Payments",
      icon: <FaFolderOpen />,
    },
    {
      path: "allCompanyList",
      name: "Companies",
      icon: <FaFolderOpen />,
    },
  ];

  const renderAccountMenu = () => (
    <div ref={accountMenuRef}>
      <NavLink
        to="/admin/dashboards/adminProfileSetting"
        className="account_menu_item d-md-block d-none"
        onClick={closeAccountMenu}
      >
        Admin Profile Setting
      </NavLink>

      <div className="d-md-block d-none" onClick={() => dispatch(logout())}>
        <div className="account_menu_item" onClick={closeAccountMenu}>
          Logout
        </div>
      </div>
      <NavLink
        to="/admin/dashboards/adminProfileSetting"
        className="account_menu_item d-md-none d-block"
        onClick={handleCombinedClick}
      >
        Admin Profile Setting
      </NavLink>

      <div className="d-md-none d-block" onClick={() => dispatch(logout())}>
        <div className="account_menu_item" onClick={handleCombinedClick}>
          Logout
        </div>
      </div>
    </div>
  );
  const handleCombinedClick = () => {
    closeAccountMenu();
    toggleSidebar();
    closeAccountMenu();
  };

  return (
    <div ref={sidebarRef}>
      <customNavbar style={{ zIndex: "9999" }}>
        {" "}
        <div style={{ background: "#050f26" }}>
          <div className="d-flex align-items-center px-2 justify-content-between custom-transition">
            <div className="bars custom-transition">
              <FaBars onClick={toggleSidebar} />
            </div>
            <img className="w-25 z-3 h-25 logo" src={logoSvg} alt="" />
          </div>
        </div>
        <div className="mobile-sidebar-container">
          <div className="account_menu3 ">
            {isAccountMenuOpen && renderAccountMenu()}
          </div>
          {isSidebarPage && (
            <div
              style={{ width: isOpen ? "170px" : "0px" }}
              className=" sidebar-mobile"
            >
              <div>
                {menuItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className={`link`}
                    activeClassName="active"
                    onClick={handleCombinedClick}
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text "
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))}
              </div>
              <div className="link" onClick={toggleAccountMenu}>
                <div className="icon">
                  <FaUserCircle />
                </div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Account
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      </customNavbar>
      <div className="containerSide d-flex ">
        <div className="custom-transition main-sidebar">
          {isAccountMenuOpen && (
            <div
              style={{ left: isOpen ? "153px" : "48px" }}
              className="account_menu"
            >
              {isAccountMenuOpen && renderAccountMenu()}
            </div>
          )}
          {isSidebarPage && (
            <div
              style={{ width: isOpen ? "150px" : "48px" }}
              className=" sidebar"
            >
              <div className="top_section custom-transition">
                <img
                  style={{
                    display: "block",
                    width: "125px",
                    top: "-17px",
                    display: isOpen ? "block" : "none",
                  }}
                  className="z-3  logo"
                  src={logoSvg}
                  alt=""
                />
                <div
                  style={{
                    marginLeft: isOpen ? "-15px" : "0px",
                    padding: "10px 10px",
                  }}
                  className="bars custom-transition"
                >
                  <FaBars onClick={toggleSidebar} />
                </div>
              </div>
              <div>
                {menuItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className={`link`}
                    activeClassName="active"
                    onClick={closeAccountMenu}
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text "
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))}
              </div>
              <div className="link cursor-pointer" onClick={toggleAccountMenu}>
                <div className="icon">
                  <FaUserCircle />
                </div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Account
                </div>
              </div>
            </div>
          )}
        </div>
        <main
          className="custom-transition main-outlet"
          style={{ marginLeft: isOpen ? "150px" : "40px" }}
          onClick={toggleAccountMenuMain}
        >
          <section>
            <Outlet />
          </section>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
