import React from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import "chart.js/auto";

export default function EmiShortDetails({ emiPayments }) {
  // Calculate total, remaining, and paid amounts
  const totalAmount = emiPayments?.reduce((acc, payment) => {
    const amount = parseFloat(payment.total_amount);
    return !isNaN(amount) ? acc + amount : acc;
  }, 0);

  const remainingAmount = emiPayments?.reduce((acc, payment) => {
    const amount = parseFloat(payment.remainingBalance);
    return !isNaN(amount) ? acc + amount : acc;
  }, 0);

  const paidAmount = emiPayments?.reduce((acc, payment) => {
    const amount = parseFloat(payment.paidBalance);
    return !isNaN(amount) ? acc + amount : acc;
  }, 0);

  // Calculate percentages
  const paidPercentage = totalAmount ? (paidAmount / totalAmount) * 100 : 0;
  const remainingPercentage = totalAmount
    ? (remainingAmount / totalAmount) * 100
    : 0;

  // Data for the Pie Chart
  const chartData = {
    labels: [
      `Paid Amount (${paidPercentage.toFixed(2)})`,
      `Remaining Amount (${remainingPercentage.toFixed(2)})`,
    ],
    datasets: [
      {
        data: [paidAmount, remainingAmount],
        backgroundColor: ["#36A2EB", "#FF6384"],
        hoverBackgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  // Options for the Pie Chart, including the DataLabels plugin
  const chartOptions = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce(
            (acc, val) => acc + val,
            0
          );
          const percentage = (value / total) * 100;
          return `${percentage.toFixed(2)}%`;
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw || 0;
            return `${label}: $${value.toFixed(2)}`;
          },
        },
      },
    },
  };

  return (
    <div className="emi-short-details">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          background: "white",
          padding: "20px",
          borderRadius: "7px",
        }}
        className=""
      >
        <Pie
          data={chartData}
          options={chartOptions}
          plugins={[ChartDataLabels]}
        />
      </div>

      {/* <div className="d-flex flex-column align-items-center justify-content-center">
        <h6 className="m-1 mb-3">Total Amount: ${totalAmount.toFixed(2)}</h6>
        <h6 className="m-1 mb-3">Paid Amount: ${paidAmount.toFixed(2)}</h6>
        <h6 className="m-1 mb-3">
          Remaining Amount: ${remainingAmount.toFixed(2)}
        </h6>
      </div> */}
      <div style={{ padding: "0px 12px", margin: "15px 0px" }}>
        <transactionCard className="my-2 h-100 ">
          <div className="rating-card4">
            <div className="text-wrapper-tr4">
              <div style={{ fontSize: "17px" }} className="text-primary-tr">
                Total Balance <br /> Amount
              </div>
            </div>
            <div className="socials-container ">
              <div className="text-primary-tr d-flex align-items-center  ">
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "13px",
                    padding: "0px 2px",
                  }}
                >
                  ৳
                </span>{" "}
                {totalAmount ? totalAmount.toFixed(2) : "N/A"}
              </div>
            </div>
          </div>
        </transactionCard>
        <transactionCard className="my-2 h-100 ">
          <div className="rating-card4">
            <div className="text-wrapper-tr4  ">
              <div style={{ fontSize: "17px" }} className="text-primary-tr">
                Paid Balance <br /> Amount
              </div>
            </div>

            <div className="socials-container ">
              <div className="text-primary-tr d-flex align-items-center  ">
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "13px",
                    padding: "0px 2px",
                  }}
                >
                  ৳
                </span>
                {paidAmount ? paidAmount.toFixed(2) : "N/A"}
              </div>
            </div>
          </div>
        </transactionCard>
        <transactionCard className="my-2 h-100 ">
          <div className="rating-card4">
            <div className="text-wrapper-tr4">
              <div style={{ fontSize: "17px" }} className="text-primary-tr">
                Remaining <br /> Balance
              </div>
            </div>

            <div className="socials-container ">
              <div className="text-primary-tr d-flex align-items-center ">
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "13px",
                    padding: "0px 2px",
                  }}
                >
                  ৳
                </span>
                {remainingAmount ? remainingAmount.toFixed(2) : "N/A"}{" "}
              </div>
            </div>
          </div>
        </transactionCard>
      </div>
    </div>
  );
}
