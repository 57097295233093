import React from "react";
import { useGetCurrentUserQuery } from "../../redux/features/auth/authApi";

import Timeline from "../../ui/Timeline";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";

export default function ProvideData() {
  const { data: userData } = useGetCurrentUserQuery();
  const { data: companyInfo, isLoading: companyLoading } =
    useUserActiveCompanyQuery();
  const activeCompanyId = companyInfo?.data?._id;
  console.log(activeCompanyId);
  const user = userData?.data;
  return (
    <div>
      <h5 className="mb-4 text-center">
        To gain access to the dashboard and complete your company registration,
        ensure all necessary steps are fulfilled.
      </h5>
      <Timeline activeCompanyId={activeCompanyId} user={user} />
    </div>
  );
}
