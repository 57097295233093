import React, { useEffect, useRef, useState } from "react";
import circlePng from "../../../assets/dashboards/circle.png";
import { useGetAllUserQuery } from "../../../redux/features/admin/adminApi";
import { useGetAllCompanyQuery } from "../../../redux/api/companyApi";
import { useGetAllPaymentInfoQuery } from "../../../redux/features/payment/paymentApi";
import { MdAdminPanelSettings } from "react-icons/md";
import { FaCcMastercard } from "react-icons/fa";
import UserChart from "./AdminUi/UserChart";
import PaymentChart from "./AdminUi/PaymentChart";
import { Link } from "react-router-dom";
import Notification from "../../../ui/Notification";
import AdminNotificationsMain from "./AdminUi/AdminNotificationsMain";

// Helper function to get the start of the week

const AdminIndex = () => {
  const { data: userData } = useGetAllUserQuery();
  const allUsers = userData?.data;
  const { data: companyData } = useGetAllCompanyQuery();
  const allCompany = companyData?.data;
  const { data: userPayments } = useGetAllPaymentInfoQuery();
  const payments = userPayments?.data || [];

  const totalAmount = payments.reduce((acc, payment) => {
    const amount = Number(payment.total_amount);
    return acc + (isNaN(amount) ? 0 : amount);
  }, 0);

  const users = allUsers?.filter((user) => user.role === "user");
  const admins = allUsers?.filter((user) => user.role === "admin");
  const bannedUsers = allUsers?.filter((user) => user.banStatus === true);
  return (
    <div className="m-4">
      <div className="d-flex justify-content-end mt-3 mx-4 mb-2 position-relative">
        <AdminNotificationsMain />
      </div>
      <adminIndexTag className="">
        <div className="row">
          <div className="col-md-3 mb-3">
            <div className="card bg-gradient-danger text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h4 className="font-weight-normal mb-2">
                  Total Registered
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h4>
                <h2 className="mb-2">{allUsers?.length}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="card bg-gradient-info text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h4 className="font-weight-normal mb-2">
                  Total Company
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h4>
                <h2 className="mb-2">{allCompany?.length}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="card bg-gradient-success text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h4 className="font-weight-normal mb-2">
                  Total Amount
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h4>
                <h2 className="mb-2">
                  {totalAmount} <span className="tk-sign">৳</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="card bg-gradient-pending text-white">
              <div className="card-body">
                <img src={circlePng} className="card-img-absolute" alt="" />
                <h4 className="font-weight-normal mb-2">
                  Total Transaction
                  <i className="mdi mdi-chart-line mdi-24px float-end" />
                </h4>
                <h2 className="mb-2">{payments.length}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center">
          <div className="col-md-6">
            <PaymentChart />
          </div>
          <div className="col-md-6 ">
            <UserChart />
          </div>
        </div>
        <div className=" h-100 gap-4 m-3 d-flex justify-content-center ad_index_card_main">
          <div className="col-md-6 w-50  card-custom-flex2 ad_index_card_main2">
            {" "}
            <staticsUserDetails>
              <div className="d-flex m-3 gap-3 justify-content-center align-items-center ad_index">
                <div className="w-100 card bg-gradient-danger card-img-holder text-white ad_index_card">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Registered
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h2 className="mb-2">{allUsers?.length}</h2>
                  </div>
                </div>
                <div className="w-100 card bg-gradient-danger card-img-holder text-white">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Total Users
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h2 className="mb-2">{users?.length}</h2>
                  </div>
                </div>
                <div className="w-100 card bg-gradient-info card-img-holder text-white ad_index_card">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Admins
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h2 className="mb-2">{admins?.length}</h2>
                  </div>
                </div>
                <div className="w-100 card bg-gradient-success card-img-holder text-white ad_index_card">
                  <div className="card-body">
                    <img src={circlePng} className="card-img-absolute" alt="" />
                    <h6 className="font-weight-normal mb-2">
                      Banned
                      <i className="mdi mdi-chart-line mdi-24px float-end" />
                    </h6>
                    <h2 className="mb-2">{bannedUsers?.length}</h2>
                  </div>
                </div>
              </div>
            </staticsUserDetails>
            <div className="d-flex justify-content-center align-items-center ad_index_btn">
              <MdAdminPanelSettings className="userManageIcon" />
              <Link to="/admin/dashboards/userManagement">
                <button className="btn btn-primary">
                  Manage User From Here
                </button>
              </Link>{" "}
            </div>
          </div>
          <div className=" w-50 card-custom-flex col-md-6   ad_index_peyment">
            <div className="d-flex  flex-column justify-content-center align-items-center">
              <div className=" userManageIcon">
                <FaCcMastercard />
              </div>{" "}
              <Link to="/admin/dashboards/paymentRecords">
                <button className="btn btn-primary">
                  Check Payments And Transactions From Here
                </button>
              </Link>{" "}
            </div>
          </div>
        </div>
      </adminIndexTag>
    </div>
  );
};

export default AdminIndex;
