export default function AdminCompanyShareholders({ company_information }) {
  const { _id, company_name, shareholders,  number_of_share } =
    company_information || {};

  return (
    <div className="">
      <div className="">
        <div className="card p-4 my-3">
          <h5>Shareholders</h5>
          <h6>These are the current shareholders</h6>
          <div className="overflow-x-scroll">
            <table className="table table-striped c_profile_table c_director_table">
              <thead className="c_profile_table c_director_table">
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>ID</th>
                  <th>Shares</th>
                </tr>
              </thead>
              <tbody>
                {shareholders?.map((shareholder) => (
                  <tr key={shareholder.id}>
                    <td>{shareholder.shareholder_name}</td>
                    <td>{shareholder.shareholder_email}</td>
                    <td>{shareholder._id}</td>
                    <td>${shareholder.share}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
