import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useEditBasicCompanyInformationMutation } from "../../../../redux/api/companyApi";
import toast from "react-hot-toast";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function AboutCompanyEdit({ company_information }) {
  const companyData = company_information;
  const [isFormValid, setIsFormValid] = useState(true);
  const {
    _id,
    company_name,
    explanation_of_name,
    about,
    NSICS_codes,
    company_email,
    company_phone_number,
    company_country_code,
    address_line_one,
    business_type,
  } = companyData || {};

  const [companyName, setCompanyName] = useState(company_name);
  const [explanation, setExplanation] = useState(explanation_of_name);
  const [businessDescription, setBusinessDescription] = useState(about);
  const [msicCode, setMsicCode] = useState();
  const [email, setEmail] = useState(company_email);
  const [phone, setPhone] = useState(company_phone_number);
  const [countryCode, setCountryCode] = useState({
    value: company_country_code,
    label: company_country_code,
  });
  const [addressLineOne, setAddressLineOne] = useState(address_line_one);
  const [noAddress, setNoAddress] = useState(
    address_line_one === "not have address"
  );
  const [businessType, setBusinessType] = useState(business_type);

  const [companyNameError, setCompanyNameError] = useState("");
  const [explanationError, setExplanationError] = useState("");
  const [businessDescriptionError, setBusinessDescriptionError] = useState("");
  const [msicCodeError, setMsicCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressLineError, setAddressLineError] = useState("");
  const [businessTypeError, setBusinessTypeError] = useState("");
  useEffect(() => {
    if (companyData) {
      setMsicCode(companyData.NSICS_codes || "");
    }
  }, [companyData]);
  const [editBasicCompanyInformation, { isLoading }] =
    useEditBasicCompanyInformationMutation();
  useEffect(() => {
    const isValid = validateFields();
    setIsFormValid(isValid);
  }, [
    companyName,
    explanation,
    businessDescription,
    msicCode,
    email,
    phone,
    addressLineOne,
    businessType,
    noAddress,
  ]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateFields();
    if (!isValid) {
      toast.error("Please fill in all required fields");
      return;
    }
    if (isValid) {
      const info = {
        company_id: _id,
        company_name: companyName,
        explanation_of_name: explanation,
        about: businessDescription,
        NSICS_codes: msicCode,
        company_email: email,
        company_country_code: countryCode.value,
        company_phone_number: phone,
        address_line_one: noAddress ? "not have address" : addressLineOne,
        business_type: businessType,
      };
      const { data, statusCode } = await editBasicCompanyInformation(
        info
      ).unwrap();
      if (statusCode === 200) {
        toast.success(data, {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
      }
    } else {
      console.log("Form validation failed");
    }
  };

  const validateFields = () => {
    let isValid = true;

    if (!companyName.trim()) {
      setCompanyNameError("Company name is required");
      isValid = false;
    } else if (companyName.length > 50) {
      setCompanyNameError("Company name cannot exceed 50 characters");
      isValid = false;
    } else {
      setCompanyNameError("");
    }

    if (!explanation.trim()) {
      setExplanationError("Explanation is required");
      isValid = false;
    } else if (explanation.length > 250) {
      setExplanationError("Explanation cannot exceed 250 characters");
      isValid = false;
    } else {
      setExplanationError("");
    }

    if (!businessDescription.trim()) {
      setBusinessDescriptionError("Business description is required");
      isValid = false;
    } else if (businessDescription.length > 250) {
      setBusinessDescriptionError(
        "Business description cannot exceed 250 characters"
      );
      isValid = false;
    } else {
      setBusinessDescriptionError("");
    }

    if (!msicCode) {
      setMsicCodeError("Please select MSIC code");
      isValid = false;
    } else {
      setMsicCodeError("");
    }

    if (!email.trim()) {
      setEmailError("Email address is required");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!phone.trim()) {
      setPhoneError("Phone number is required");
      isValid = false;
    } else if (!/^\d+$/.test(phone)) {
      setPhoneError("Invalid phone number");
      isValid = false;
    } else if (phone.length < 10) {
      setPhoneError("Less than 10 digits not allowed");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (!noAddress && !addressLineOne.trim()) {
      setAddressLineError("Address line is required");
      isValid = false;
    } else {
      setAddressLineError("");
    }

    if (!businessType.trim()) {
      setBusinessTypeError("Business type is required");
      isValid = false;
    } else if (businessType.length > 50) {
      setBusinessTypeError("Business type cannot exceed 50 characters");
      isValid = false;
    } else {
      setBusinessTypeError("");
    }

    return isValid;
  };

  const handleCompanyName = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setCompanyName(value);
      setCompanyNameError("");
    } else {
      setCompanyNameError("Company name cannot exceed 50 characters");
    }
  };

  const handleBusinessTypeChange = (e) => {
    setBusinessType(e.target.value);
  };

  const handleExplanation = (e) => {
    const value = e.target.value;
    if (value.length <= 250) {
      setExplanation(value);
      setExplanationError("");
    } else {
      setExplanationError("Explanation cannot exceed 250 characters");
    }
  };

  const handleBusinessDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 250) {
      setBusinessDescription(value);
      setBusinessDescriptionError("");
    } else {
      setBusinessDescriptionError(
        "Business description cannot exceed 250 characters"
      );
    }
  };

  const handleMsicCodeChange = (e) => {
    setMsicCode(e.target.value);
    setMsicCodeError("");
  };

  const handlePhoneChange = (selectedOption) => {
    setCountryCode(selectedOption);
    setPhone("");
    setPhoneError("");
    validatePhone(""); // Reset phone error
  };

  const handlePhoneInputChange = (e) => {
    const { value } = e.target;
    const onlyNumbers = value.replace(/[^0-9]/g, "");
    if (onlyNumbers.length <= 10) {
      setPhone(onlyNumbers);
      validatePhone(onlyNumbers);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const validateEmail = (emailValue) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailPattern.test(emailValue);

    if (!emailValue) {
      setEmailError("Email address is required");
    } else if (!isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = (phoneValue) => {
    if (!phoneValue) {
      setPhoneError("Phone number is required");
    } else {
      setPhoneError("");
    }
  };

  const handleAddressLineChange = (value, line) => {
    if (line === 1) {
      if (value.length > 100) {
        setAddressLineError("Max Address line length is 100 characters");
      } else {
        setAddressLineOne(value);
        setAddressLineError("");
      }
    }
  };

  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+880", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" },
    // Add more country options as needed
  ];

  // Compare current values with initial values from companyData to enable/disable the update button
  const isFormChanged = () => {
    return (
      companyName !== company_name ||
      explanation !== explanation_of_name ||
      businessDescription !== about ||
      msicCode !== NSICS_codes ||
      email !== company_email ||
      phone !== company_phone_number ||
      countryCode.value !== company_country_code ||
      addressLineOne !== address_line_one ||
      noAddress !== (address_line_one === "not have address") ||
      businessType !== companyData.business_type
    );
  };

  const handleNoAddressChange = (e) => {
    setNoAddress(e.target.checked);
    if (e.target.checked) {
      setAddressLineOne("not have address");
      setAddressLineError("");
    } else {
      setAddressLineOne("");
    }
  };

  return (
    <div className="container">
      <div className="mt-3">
        <h4>Basic Information</h4>
        <form onSubmit={handleSubmit}>
          <div className="mb-1">
            <label htmlFor="companyName">
              Company Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="companyName"
              className="form-control mt-1"
              placeholder="Company name"
              value={companyName}
              onChange={handleCompanyName}
            />
            {companyNameError && (
              <small className="text-danger">{companyNameError}</small>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="explanation">
              Explanation of Company Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="explanation"
              className="form-control mt-1"
              placeholder="Explanation of company name"
              value={explanation}
              onChange={handleExplanation}
            />
            {explanationError && (
              <small className="text-danger">{explanationError}</small>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="about">
              Business Description <span className="text-danger">*</span>
            </label>
            <textarea
              id="about"
              className="form-control mt-1"
              placeholder="Business description"
              value={businessDescription}
              onChange={handleBusinessDescriptionChange}
            />
            {businessDescriptionError && (
              <small className="text-danger">{businessDescriptionError}</small>
            )}
          </div>
          <div className="mb-1">
            <label htmlFor="pet-select">Select your NAICS codes</label>
            <br />
            <select
              name="pets"
              id="pet-select"
              value={msicCode}
              onChange={handleMsicCodeChange}
            >
              <option value="">Select NAICS codes</option>
              <option value="551100">
                551100 (Management of Companies and Enterprises)
              </option>
              <option value="551111">
                551111 (Offices of Bank Holding Companies)
              </option>
              <option value="551112">
                551112 (Offices of Other Holding Companies)
              </option>
              <option value="551114">
                551114 (Corporate, Subsidiary, and Regional Managing Offices)
              </option>
            </select>
            {msicCodeError && (
              <div className="text-danger">{msicCodeError}</div>
            )}
          </div>

          <div className="mb-1">
            <label htmlFor="email">
              Email Address <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              id="email"
              className="form-control mt-1"
              placeholder="Email address"
              value={email}
              onChange={handleEmailChange}
            />
            {emailError && <small className="text-danger">{emailError}</small>}
          </div>
          <div className="mb-1">
            <label htmlFor="phone">
              <strong>Phone</strong>
            </label>
            <div className="d-flex align-items-center gap-1">
              <div className="phone-box-selector phone_box rounded-2 gap-1">
                <Select
                  value={countryCode}
                  onChange={handlePhoneChange}
                  options={countryCodes}
                  isSearchable={false} // Disable text input
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "130px",
                    }),
                  }}
                />
              </div>
              <input
                type="tel"
                placeholder={
                  countryCode
                    ? `${countryCode.placeholder}`
                    : "Enter Your Phone Number"
                }
                value={phone}
                onChange={handlePhoneInputChange}
                className="form-control rounded-2"
              />
            </div>
            {phoneError && <div className="text-danger">{phoneError}</div>}
          </div>
          <div className="mb-1">
            <label htmlFor="addressLineOne">
              Address Line One <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="addressLineOne"
              className="form-control mt-1"
              placeholder="Address line one"
              value={noAddress ? "not have address" : addressLineOne}
              onChange={(e) => handleAddressLineChange(e.target.value, 1)}
              disabled={noAddress}
            />
            {addressLineError && (
              <small className="text-danger">{addressLineError}</small>
            )}
          </div>
          <div className="mb-1 form-check">
            <input
              type="checkbox"
              id="noAddress"
              className="form-check-input"
              checked={noAddress}
              onChange={handleNoAddressChange}
            />
            <label htmlFor="noAddress" className="form-check-label">
              No Address
            </label>
          </div>
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{ fontWeight: "semibol", color: "black" }}
            >
              <strong>Select Business Type</strong>
            </FormLabel>
            <RadioGroup
              value={businessType} // Use the state value here
              onChange={handleBusinessTypeChange} // Update state on change
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="llc" control={<Radio />} label="LLC" />
              <FormControlLabel
                value="corporation"
                control={<Radio />}
                label="Corporation"
              />
            </RadioGroup>
          </FormControl>
          <br />
          <button
            type="submit"
            className="btn btn-primary mt-3"
            disabled={isLoading || !isFormChanged() || !isFormValid}
          >
            {isLoading ? "Updating..." : "Update"}
          </button>
        </form>
      </div>
    </div>
  );
}
