import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import "../login/loginsignup.css";
import { useIntroContext } from "../../hooks/useIntroContext";
import reviewSubmissionSvg from "../../assets/introflow/svg/9.svg";
import { useRegisterCompanyMutation } from "../../redux/api/companyApi";
import { usePostActiveCompanyIdMutation } from "../../redux/api/userApi";
import toast from "react-hot-toast";

function ReviewSubmission() {
  const { companyData } = useIntroContext();
  const {
    company_name,
    explanation_of_name,
    about,
    NSICS_codes,
    number_of_share,
    company_email,
    company_phone_number,
    company_country_code,
    address_line_one,
    directors,
    shareholders,
    business_type,
  } = companyData;

  const [registerCompany, { error }] = useRegisterCompanyMutation();
  const [postActiveCompany, {}] = usePostActiveCompanyIdMutation();
  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);

  const validateFields = () => {
    const newErrors = [];
    if (!company_name) newErrors.push("Company name is required");
    if (!explanation_of_name) newErrors.push("Explanation of name is required");
    if (!about) newErrors.push("About is required");
    if (!NSICS_codes) newErrors.push("NSICS codes are required");
    if (!number_of_share) newErrors.push("Number of shares is required");
    if (!company_email) newErrors.push("Company email is required");
    if (!company_phone_number)
      newErrors.push("Company phone number is required");
    if (!company_country_code)
      newErrors.push("Company country code is required");
    if (!address_line_one) newErrors.push("Address line one is required");
    if (!directors || directors.length === 0)
      newErrors.push("At least one director is required");
    if (!shareholders || shareholders.length === 0)
      newErrors.push("At least one shareholder is required");
    if (!business_type) newErrors.push("Business type is required");

    const directorsEmails = directors.map(
      (director) => director.directors_email
    );
    const shareholdersEmails = shareholders?.map(
      (shareholder) => shareholder.shareholder_email
    );
    const missingEmails = directorsEmails.filter(
      (email) => !shareholdersEmails?.includes(email)
    );

    if (missingEmails.length > 0)
      newErrors.push(
        "All directors must be shareholders & must give shares between them"
      );

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      toast.error("Please fill all the required fields");
      return;
    }

    try {
      const cleanData = removeCircularReferences(companyData);
      await registerCompany(cleanData)
        .unwrap()
        .then(({ data, statusCode, message }) => {
          if (statusCode === 201) {
            navigate("/signup/submittedsuccessfull");
            postActiveCompany(data._id)
              .unwrap()
              .then((response) => {
                localStorage.removeItem("companyData");
                // Handle successful data saving
              })
              .catch((error) => {
                console.error("Error activating company:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error Saving Data:", error);
        });
    } catch (error) {
      console.error("Error Saving Data:", error);
    }
  };

  const removeCircularReferences = (obj, seen = new WeakSet()) => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (seen.has(obj)) {
      return null; // Circular reference found, return null
    }

    seen.add(obj);

    const cleanObj = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      const value = removeCircularReferences(obj[key], seen);
      if (value !== null) {
        cleanObj[key] = value;
      }
    }

    return cleanObj;
  };
  return (
    <div className="">
      <div className="intro-flow-bg">
        <div className="row ">
          <div className="col-md-5 ">
            <div className=" d-flex bg-left justify-content-center align-items-center sticky-top">
              <div className="custom-intro-hight">
                <img
                  src={reviewSubmissionSvg}
                  width={400}
                  className=" mt-5 custom-intro-hight "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="bg-white d-flex justify-content-center align-items-center h-100 step_first_top ">
              <div className=" px-3  w-100 intro-margin-custom ">
                <h3 className="my-2 font-weight-bold  text-xl">
                  Review submission 🕵️
                </h3>
                <p className="mb-4">
                  That's it! All your data has been saved carefully. By
                  submitting you will let our account managers know to review
                  the information. Don't worry, you may still make changes after
                  submission.
                </p>

                <div className="bg-light border_review shadow  p-4 mb-4 ">
                  <div className="">
                    <h4 className="text-primary text_heding_review text-center">
                      Company Details
                    </h4>
                    <div className="">
                      <div className="sub-heading-review d-flex gap-2 align-content-center">
                        Company name and explanation
                        <Link
                          to="/signup/nameyourcompany"
                          state={{ fromReview: true }}
                        >
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </Link>
                      </div>
                      <div className="d-flex gap-2">
                        <span className="">Suggested company name:</span>
                        <n className="genaarte_value">{company_name}</n>
                      </div>
                      <div className="d-flex gap-2">
                        <span className="">Explanation of name:</span>
                        <n className="genaarte_value">{explanation_of_name}</n>
                      </div>
                    </div>
                    <div className="">
                      <div className="sub-heading-review d-flex gap-2 align-content-center">
                        Business Details And NSICS Codes
                        <Link
                          to="/signup/aboutbusiness"
                          state={{ fromReview: true }}
                        >
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </Link>
                      </div>
                      <div className="d-flex gap-2">
                        <span className="">What your business will be do:</span>
                        <n className="genaarte_value">{about}</n>
                      </div>
                      <div className="d-flex gap-2">
                        <span className="genaarte_value">
                          Business Nature -NSICS Codes:
                        </span>
                        <n className="genaarte_value">{NSICS_codes}</n>
                      </div>
                    </div>

                    <div className="">
                      <div className="sub-heading-review">
                        Total Shares{" "}
                        <Link
                          to="/signup/sharesandpaids"
                          state={{ fromReview: true }}
                        >
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </Link>
                      </div>
                      <div className="d-flex gap-2">
                        <span className=""> Number of Shares:</span>
                        <n className="genaarte_value">${number_of_share}</n>
                      </div>
                    </div>
                  </div>
                  <hr class=" border-2 border-top border-secondary opacity-75" />
                  <div className="">
                    <div className=" ">
                      <h4 className="text-primary text_heding_review  text-center">
                        Company Contact Information
                      </h4>
                      <div className="">
                        <div className="sub-heading-review d-flex gap-2 align-content-center">
                          Company contact details
                          <Link
                            to="/signup/businesscontactdetails"
                            state={{ fromReview: true }}
                          >
                            <i class="fa fa-edit" aria-hidden="true"></i>
                          </Link>
                        </div>
                        <div className="d-flex gap-2">
                          <span className="font-weight-bold">
                            Email Address:
                          </span>
                          <n className="genaarte_value">{company_email}</n>
                        </div>
                        <div className="d-flex gap-2">
                          <span className="font-weight-bold">
                            Phone Number:
                          </span>
                          <n className="genaarte_value">
                            {company_country_code}
                            {company_phone_number}
                          </n>
                        </div>
                        <div className="d-flex gap-2">
                          <span className="font-weight-bold">
                            Business Type:
                          </span>
                          <n className="genaarte_value text-uppercase">
                            {business_type}
                          </n>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <span className="font-weight-bold">
                          Business (office) address:
                        </span>
                        <n className="genaarte_value">{address_line_one}</n>
                      </div>
                    </div>
                  </div>
                  <hr class=" border-2 border-top border-secondary opacity-75" />
                  <div className="">
                    <div className="">
                      <h4 className="text-primary text_heding_review text-center">
                        Directors
                      </h4>
                      <div className="sub-heading-review d-flex gap-2 align-content-center">
                        All directors and their contact details
                        <Link
                          to="/signup/whowillbethedirectors"
                          state={{ fromReview: true }}
                        >
                          <i class="fa fa-edit" aria-hidden="true"></i>
                        </Link>
                      </div>
                      <div className="row">
                        {directors?.map((item, i) => (
                          <div className="col-lg-6   p-2 " key={i}>
                            <div className="rounded-1 p-2">
                              <div className="d-flex gap-2">
                                <>{i + 1}</>{" "}
                                <div>
                                  <div className="d-flex gap-2">
                                    <span className="font-weight-bold">
                                      Full Name:
                                    </span>
                                    <n className="genaarte_value">
                                      {" "}
                                      {item.full_name}
                                    </n>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <span className="">Email:</span>
                                    <n className="genaarte_value">
                                      {item.directors_email}
                                    </n>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <span className="">Mobile:</span>
                                    <n className="genaarte_value">
                                      {item.directors_country_code}
                                      {item.directors_phone_number}
                                    </n>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <span className="">Role:</span>
                                    <n className="genaarte_value">
                                      {item.role_of_directors}
                                    </n>
                                  </div>
                                  <div className="d-flex gap-2">
                                    <span className="">
                                      Director Country Name:
                                    </span>
                                    <n className="genaarte_value">
                                      {item.directors_country_name}
                                    </n>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}{" "}
                      </div>
                    </div>
                  </div>
                  <hr class=" border-2 border-top border-secondary opacity-75" />
                  <div className="">
                    <h4 className="text-primary text_heding_review text-center">
                      Shareholders
                    </h4>

                    <div className="sub-heading-review d-flex gap-2 align-content-center">
                      All directors and their contact details{" "}
                      <Link
                        to="/signup/shareholders"
                        state={{ fromReview: true }}
                      >
                        <i class="fa fa-edit" aria-hidden="true"></i>
                      </Link>
                    </div>
                    <div className="row">
                      {shareholders?.map((item, i) => (
                        <div className="col-lg-6   p-2 " key={i}>
                          <div className="rounded-1 p-2">
                            <div className="d-flex gap-2">
                              <>{i + 1}</>
                              <div>
                                <div className="d-flex gap-2">
                                  <span className="font-weight-bold">
                                    Name:
                                  </span>
                                  <n className="genaarte_value">
                                    {" "}
                                    {item.shareholder_name}
                                  </n>
                                </div>
                                <div className="d-flex gap-2">
                                  <span className="font-weight-bold">
                                    Shares:
                                  </span>
                                  <n className="genaarte_value">
                                    {" "}
                                    ${item.share}
                                  </n>
                                </div>
                                <div className="d-flex gap-2">
                                  <span className="">Email:</span>
                                  <n className="genaarte_value">
                                    {item.shareholder_email}
                                  </n>
                                </div>
                                <div className="d-flex gap-2">
                                  <span className="">Mobile:</span>
                                  <n className="genaarte_value">
                                    {item.shareholder_country_code}
                                    {item.shareholder_phone_number}
                                  </n>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {errors.length > 0 && (
                  <div className="alert alert-danger">
                    <ul>
                      {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="form-group text-muted mt-4">
                  <p className="mb-4">
                    Submit your incorporation request to get it reviewed by our
                    team! After submission you may choose to pay for the
                    incorporation directly or go to your dashboard.
                  </p>
                </div>
                <div>
                  <button
                    className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
                    onClick={handleSubmit}
                  >
                    <strong>Next step →</strong>
                  </button>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewSubmission;
