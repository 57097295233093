import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  useAdminEditUserProfileApiMutation,
  useUpdateProfileMutation,
} from "../../../redux/api/userApi";
import { useGetSingleUserQuery } from "../../../redux/features/admin/adminApi";

const AdminEditUserProfile = () => {
  const { _id } = useParams();
  const { data: userDetails } = useGetSingleUserQuery(_id);
  const [activeButton, setActiveButton] = useState("profile");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);

  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const [adminEditUserProfileApi, { isLoading: isLoading2 }] =
    useAdminEditUserProfileApiMutation();

  const user = userDetails?.data;
  const avatar = userDetails?.data?.avatar;

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    if (file) {
      formData.append("avatar", file);
    }
    formData.append("_id", _id);  // Add the ID to the formData

  
  
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const result = await adminEditUserProfileApi(formData).unwrap(); 
      if (result?.success === true) {
        toast.success("Profile updated successfully", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
        // Clear form values after successful submission
        setFirstName("");
        setLastName("");
        setFile(null);
      } else {
        toast.error("Failed to update profile");
      }
    } catch (err) {
      toast.error("Failed to update profile");
      console.error("Failed to update profile", err);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFile(file);
  };

  const isSignUpButtonDisabled = !firstName || !lastName;

  return (
    <div>
      <div className="profile-container ">
        <div className="profile">
          <div className="position-relative border-bottom">
            <div className="banner "></div>
            <div className="content ">
              <div className="profile-info">
                <img
                  src={avatar}
                  alt="Profile"
                  className="profile-img position-absolute"
                />
                <div className="details  ">
                  <div className="name m-0">
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="location m-0">Email: {user?.email}</div>
                  <div className="location m-0">
                    Joined At: {new Date(user?.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className=" d-flex gap-3  justify-content-center align-items-center ">
                <div className="role-detector text-uppercase">
                  Role: {user?.role}
                </div>
              </div>
            </div>
          </div>
          <div className=" p-5 ">
            <div className="row Id-row">
              <div className="col-md-8">
                <div className="edit_profile">
                  <h4>Edit Profile</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter your first name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                      />
                      {errors.firstName && (
                        <p className="error-message">{errors.firstName}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={handleLastNameChange}
                      />
                      {errors.lastName && (
                        <p className="error-message">{errors.lastName}</p>
                      )}
                    </div>
                    <button
                      disabled={isSignUpButtonDisabled}
                      className={`btn btn_up btn-primary${
                        !isSignUpButtonDisabled
                          ? "disabled text-white bg-primary"
                          : ""
                      }`}
                      type="submit"
                    >
                      Update Profile
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-md-4 ustify-content-center gap-2">
                <div className="choose_image  d-flex align-items-center">
                  <img
                    src="https://static.thenounproject.com/png/363640-200.png"
                    alt="placeholder"
                    className="placeholder-icon"
                  />
                  <label htmlFor="file-input" className="file-label">
                    <span className="file-label-text">Choose File</span>
                  </label>
                  <input
                    id="file-input"
                    className="file-input"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <span className="file-name">
                    {file ? file.name : "No file chosen"}
                  </span>
                </div>
                <div className=" d-flex justify-content-center align-items-center ">
                  <iframe
                    title="lottie"
                    src="https://lottie.host/embed/17a88c09-b8cf-436e-8675-d4ca915b5af5/H76TkDGmRO.json"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditUserProfile;