import { Link } from "react-router-dom";
import { useGetSingleCompanyQuery } from "../redux/api/companyApi";

const SingleCompanyHook = ({ _id }) => {
  const { data: company } = useGetSingleCompanyQuery(_id);
  const company_information = company?.data;
  return (
    <>
      {!company?.data ? (
        "Company Deleted"
      ) : (
        <Link
          className={"nav-link text-primary"}
          to={`/admin/dashboards/adminCompanyDetails/${_id}`}
        >
          <span>{company ? company_information?.company_name : "Null"}</span>
        </Link>
      )}
    </>
  );
};

export default SingleCompanyHook;
