import React, { useState } from "react";
import Select from "react-select";

function CorporateShareholder({
  setSingleShareholder,
  singleShareholder,
  isOpen,
  setIsOpen,
}) {
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState(""); // State for email error
  const [forms, setForms] = useState({
    companyName: "",
    fullName: "",
    email: "",
    phone: "",
    countryCode: null,
  });
  const countryCodes = [
    { value: "+1", label: "🇺🇸 +1", placeholder: "123-456-7890", flag: "🇺🇸" },
    { value: "+880", label: "🇧🇩 +880", placeholder: "1234-567890", flag: "🇧🇩" },
  ];

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = () => {
    const data = {
      shareholder_type: "company",
      shareholder_company_name: forms.companyName,
      shareholder_name: forms.fullName,
      shareholder_email: forms.email,
      shareholder_country_code: forms.countryCode?.value,
      shareholder_phone_number: forms.phone,
    };
    setSingleShareholder((prevShareholders) => [...prevShareholders, data]);
  };

  const handlePhoneChange = (selectedOption) => {
    setForms((prevForms) => ({
      ...prevForms,
      countryCode: selectedOption,
      phone: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      // Validate email format
      const emailError = validateEmail(value);

      // Check if the email already exists in singleShareholder
      const emailExists = singleShareholder.some(
        (shareholder) => shareholder.shareholder_email === value
      );

      // If email already exists, set error message
      if (emailExists) {
        setEmailError("Email already added as a shareholder.");
      } else {
        setEmailError(emailError); // Set the email error message
      }
    }

    setForms((prevForms) => ({
      ...prevForms,
      [name]: value,
    }));
  };

  const validatePhone = () => {
    if (!forms.phone) {
      setPhoneError("Phone number is required");
    } else {
      setPhoneError("");
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email address is required";
    } else if (!emailPattern.test(email)) {
      return "Invalid email address";
    }
    return "";
  };

  // Function to check if all fields are filled and no errors
  const isFormValid = () => {
    return (
      forms.companyName &&
      forms.fullName &&
      forms.email &&
      forms.phone &&
      !phoneError &&
      !emailError // Include emailError in the validation
    );
  };

  return (
    <div className="modal-index">
      {" "}
      <form>
        <div className="mb-3">
          <label htmlFor="companyName">Official Company name</label>
          <input
            type="text"
            name="companyName"
            placeholder="Company"
            className="form-control rounded-0"
            value={forms.companyName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="fullName">Full name as per IC/Passport</label>
          <input
            type="text"
            name="fullName"
            placeholder="Full name as per IC"
            className="form-control rounded-0"
            value={forms.fullName}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email">Email address</label>
          <br />
          <input
            type="email"
            name="email"
            placeholder="Email address"
            className="form-control rounded-0"
            value={forms.email}
            onChange={handleChange}
          />
          {emailError && <div className="text-danger">{emailError}</div>}
        </div>
        <p>This will be the email address used for SSM & Company matters</p>
        <div className="mb-3">
          <label htmlFor="phone">Phone</label>
          <div className="d-flex gap-1 align-items-center">
            <div className=" phone-box-selector phone_box rounded-2 gap-1">
              <Select
                value={forms.countryCode}
                onChange={handlePhoneChange}
                options={countryCodes}
                className="mr-2"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "130px",
                  }),
                }}
              />
            </div>
            <input
              type="number"
              placeholder={
                forms.countryCode
                  ? `${forms.countryCode.placeholder}`
                  : "Enter Your Phone Number"
              }
              value={forms.phone}
              onChange={handleChange}
              onBlur={validatePhone}
              name="phone"
              className="form-control rounded-0"
            />
          </div>
          {phoneError && <div className="text-danger">{phoneError}</div>}
        </div>
      </form>
      <button
        onClick={() => {
          handleSubmit();
          closeModal();
        }}
        className="btn btn-primary border w-100 rounded-0 text-decoration-none getstarted1"
        disabled={!isFormValid()} // Disable button if form is not valid
      >
        <strong>Add shareholder</strong>
      </button>
    </div>
  );
}

export default CorporateShareholder;
