import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import toast from "react-hot-toast";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ProvideTradeLicense({ tradeLicense, setTradeLicense }) {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const excludedFileTypes = [
        "application/zip",
        "application/x-7z-compressed",
        "application/x-rar-compressed",
        "application/gzip",
        "application/x-tar",
      ];

      if (excludedFileTypes.includes(file.type)) {
        toast.error("This file type is not allowed.");
        event.target.value = null; // Reset the file input
        return;
      }

      const fileParts = file.name.split(".");
      const fileExtension = fileParts.pop();
      const renamedFileName = `tradeLicenseCopy.${fileExtension}`;
      const renamedFile = new File([file], renamedFileName, {
        type: file.type,
      });
      setTradeLicense(renamedFile);
    }
  };

  return (
    <div className="">
      <div className="d-flex justify-content-center align-align-items-center flex-column">
        <div className="file-upload-title">
          Upload your current company trade license printed copy if available
        </div>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {tradeLicense ? <>{tradeLicense.name}</> : "       Select file"}
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange}
            accept="image/*,application/pdf"
            
          />
        </Button>
      </div>
    </div>
  );
}
