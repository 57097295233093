import { useUserActiveCompanyQuery } from "../../../../redux/api/userApi";
import { useSelectedPlanQuery } from "../../../../redux/features/admin/adminApi";
import { useGetCurrentUserQuery } from "../../../../redux/features/auth/authApi";
import { VscGraphLine } from "react-icons/vsc";
import { FcPaid } from "react-icons/fc";
import {
  useGetCompanyPaymentsQuery,
  useGetSinglePaymentInfoByTransactionIdApiQuery,
  useGetSinglePaymentInfoQuery,
} from "../../../../redux/features/payment/paymentApi";
import TransactionTable from "./TransactionTable";
import { GrTime } from "react-icons/gr";
export default function Billing() {
  const { data: userData } = useGetCurrentUserQuery();
  const user = userData?.data;
  console.log(user);
  const { data: companyInfo } = useUserActiveCompanyQuery();
  const companyInformation = companyInfo?.data;
  const { data: userPayments } = useGetCompanyPaymentsQuery(
    companyInformation?._id
  );

  const payments = userPayments?.data || [];
  const totalAmount =
    payments?.length > 0
      ? payments.reduce(
          (acc, payment) => acc + parseInt(payment.total_amount, 10),
          0
        )
      : 0;
  const { data: lastPaymentInfo } = useGetSinglePaymentInfoQuery(
    companyInformation?.payment_information?.current_payment_id
  );

  const lastPaymentInformationInfo = lastPaymentInfo?.data || {};
  const { data: servicePlan } = useSelectedPlanQuery(
    lastPaymentInformationInfo?.service_plan
  );
  const service_plan = servicePlan?.data || {};
  const { title, category, price, description } = service_plan;
  const {
    total_amount,
    card_type,
    card_issuer_country,
    card_brand,
    card_issuer,
    bank_tran_id,
    currency,
    transaction_id,
    val_id,
    paidStatus,
    validEndOn,
    store_amount,
    card_no,
    tran_date,
    emi_option,
    emi_max_inst_option,
    emi_selected_inst,
    emi_amount,
    emi_inst_amount,
    createdAt,
  } = lastPaymentInformationInfo;
  return (
    
    <div className="m-4">
      <section className="d-md-flex gap-3 justify-content-between align-content-center">
        <div className="payment-width-fixing-75  d-md-flex flex-column gap-3">
          <div className="d-md-flex gap-3">
            <section
              style={{ fontSize: "16px" }}
              className="billing-width-fixing-50 mb-md-0 mb-3 card billing_status p-4"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="bold-text-billing">Company Name:</div>{" "}
                <div>{companyInformation?.company_name}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="bold-text-billing"> Company Email:</div>
                <div>{companyInformation?.company_email}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="bold-text-billing">Company ID:</div>
                <div> {companyInformation?._id}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="bold-text-billing">Company Joined Date:</div>
                <div> {new Date(createdAt).toLocaleDateString()}</div>
              </div>
            </section>
            <section className="billing-width-fixing-50 d-flex justify-content-center align-content-center gap-3">
              <div className="card total_price_billing d-flex justify-content-center align-items-center w-100 py-md-0 py-0 py-4 ">
                <div className=" d-flex justify-content-center align-items-center w-100 gap-4 ">
                  <VscGraphLine style={{ fontSize: "50px", color: "white" }} />
                  <div>
                    <strong>
                      Total Paid
                      <div>
                        {" "}
                        {totalAmount} {currency}
                      </div>
                    </strong>
                  </div>{" "}
                </div>
              </div>
              <div className="card total_count_billing d-flex justify-content-center align-items-center w-100 ">
                <div className=" d-flex justify-content-center align-items-center w-100 gap-4  ">
                  <GrTime style={{ fontSize: "50px", color: "white" }} />
                  <strong>
                    <div>
                      Counts
                      <div>{payments?.length}</div>
                    </div>
                  </strong>
                </div>
              </div>
            </section>
          </div>
          <div className="card my-md-0 my-3 ">
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                marginLeft: "20px",
              }}
              className="mt-3 "
            >
              Last Payment Details
            </div>
            <div className=" p-4 d-md-flex justify-content-evenly align-content-center flex-row text-center last_billing_card text-white position-relative">
              <div>
                <strong>Amount</strong>
                <div>
                  {currency}{" "}
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      padding: "0px 2px",
                    }}
                  >
                    ৳
                  </span>
                  {total_amount}
                </div>
              </div>
              <div className="">
                <div className="verticalLine2"></div>
              </div>
              <div>
                <strong>TransactionID</strong>
                <div>{transaction_id}</div>
              </div>
              <div className="">
                <div className="verticalLine2"></div>
              </div>
              <div>
                <strong>Transaction Date</strong>
                <div>
                  {new Date(createdAt).toLocaleTimeString()} -{" "}
                  {new Date(createdAt).toLocaleDateString()}
                </div>
              </div>
              <div className="">
                <div className="verticalLine2"></div>
              </div>
              <div>
                <strong>Transaction With</strong>
                <div>{card_issuer}</div>
              </div>
            </div>
          </div>

          <div>
            <TransactionTable payments={payments} />
          </div>
        </div>

        <div className="payment-width-fixing-25 d-flex flex-column gap-3">
          <div className="card p-4 my-md-0 my-3 d-flex justify-content-between align-content-center text-center">
            <h6>Our Payment Gateway</h6>
            <img
              className="mt-3"
              src="https://securepay.sslcommerz.com/public/image/sslcommerz.png "
              alt=""
            />
            <div className="mt-3">
              SSLCommerz: A secure and reliable payment gateway offering easy
              online transactions with support for EMI and various methods
            </div>
          </div>
          <div className="  d-flex justify-content-center align-content-center">
            <priceCard>
              <newPriceCard>
                <div
                  style={{
                    background: "#0f0c29",
                    background:
                      "linear-gradient(to right bottom, #443e89, #0f0c29)",

                    borderRadius: "20px",
                  }}
                  className=" mb-4 "
                >
                  <div className="card ">
                    <div
                      style={{
                        background: "#0f0c29",
                        background:
                          "linear-gradient(to right bottom, #5f51ff, #000000)",
                        color: "white",
                      }}
                      className={` 
                                         bg-outline-success
                                    `}
                    >
                      <div className="card-header  header-basic">
                        <h2
                          style={{ fontSize: "25px" }}
                          className=" text-center"
                        >
                          {title}{" "}
                          <span
                            style={{
                              fontWeight: "700",
                              fontSize: "28px",
                              padding: "0px 2px",
                            }}
                          >
                            ৳
                          </span>
                          {price}{" "}
                        </h2>
                      </div>
                      <div style={{ fontSize: "14px" }} className="p-2">
                        {/* <h4
                        style={{
                          textAlign: "center",
                          background: "#2dbdff",
                          padding: "20px",
                          borderRadius: "20px",
                          color: "white",
                        }}
                      >
                        Price BDT: {price}
                      </h4> */}
                        <ul className="list-unstyled">
                          {description?.map((desc, descIndex) => (
                            <li key={descIndex}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                style={{
                                  height: "25px",
                                  marginRight: "5px",
                                }}
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                ></path>
                              </svg>
                              {desc}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </newPriceCard>
            </priceCard>
          </div>
        </div>
      </section>
      <div></div>
    </div>
  );
}
