import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetBankDetailsQuery } from "../../redux/features/banks/bankApi";
import toast from "react-hot-toast";
import { useUserActiveCompanyQuery } from "../../redux/api/userApi";
import { usePatchActiveBankMutation } from "../../redux/api/companyApi";
import { isPending } from "@reduxjs/toolkit";

export default function BankDetails() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { _id } = useParams();
  const [patchActiveBank, { isLoading }] = usePatchActiveBankMutation();
  const { data: bankDetails, isLoading: isLoadingBank } =
    useGetBankDetailsQuery(_id);
  const isDisabled = isButtonDisabled || isLoading || isLoadingBank;
  const bank = bankDetails?.data;
  const { bank_name, description, image_src, facilities, additional_info } =
    bank || {};
  const { data: companyInfo } = useUserActiveCompanyQuery();
  const company_information = companyInfo?.data;
  const isPending = company_information?.bank_information?.bank_status;
  useEffect(() => {
    let interval;
    if (isButtonDisabled && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsButtonDisabled(false); // Re-enable button after countdown
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isButtonDisabled, countdown]);
  const handleActiveBank = async () => {
    const cleanData = {
      bankId: _id,
      company_id: company_information?._id,
    };
    setIsButtonDisabled(true); // Disable button immediately
    setCountdown(3);
    try {
      const { data } = await patchActiveBank(cleanData);
      if (data.statusCode === 200) {
        toast.success(data.data, {
          duration: 2500,
          style: { background: "#0B5ED7" },
          className: "text-white",
        });
      } else {
        toast.error(data.data || "Failed to update bank selection", {
          duration: 2500,
          style: { background: "#0B5ED7" },
          className: "text-white",
        });
      }
    } catch (error) {
      toast.error("An error occurred while updating bank selection", {
        duration: 2500,
        style: { background: "#0B5ED7" },
        className: "text-white",
      });
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);
    }
  };

  return (
    <div className="m-4 mt-4">
      <div className="card px-3">
        <div className="card-body">
          <div className="d-md-flex align-items-top mt-3">
            <img
              src={image_src}
              alt="Bank Logo"
              className="img-thumbnail me-3"
              style={{ width: "150px", height: "150px" }}
            />
            <div>
              <div className=" my-2   d-flex align-items-center ">
                <h2>{bank_name}</h2>
              </div>
              <p>{description}</p>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center mx-md-2">
              <Link to={additional_info?.online_presence?.website}>
                {" "}
                <h5>Visit Website</h5>
              </Link>
              <div className="d-md-flex flex-column justify-content-center align-items-end">
                {isPending === "pending" ? (
                  <>
                    {isButtonDisabled && countdown > 0 ? (
                      <div className="countdown-timer">
                        Please wait for {countdown} seconds
                      </div>
                    ) : (
                      <>
                        <div
                          onClick={!isDisabled ? handleActiveBank : undefined}
                          className={`-uppercase ${
                            _id ===
                            company_information?.bank_information.selected_bank
                              ? "pendingBank"
                              : "activeBank-detector text"
                          } ${isDisabled ? "disabled" : ""}`}
                          aria-disabled={isDisabled}
                        >
                          {_id ===
                          company_information?.bank_information.selected_bank
                            ? "Selected"
                            : "Select"}
                        </div>
                        <div className="text-small-custom">
                          Click Here for {""}
                          {_id ===
                          company_information?.bank_information.selected_bank
                            ? "Deselect"
                            : "Select"}
                          Bank
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="">
                    {(_id ===
                      company_information?.bank_information.selected_bank &&
                      company_information?.bank_information.bank_status) ===
                      "processing" && (
                      <div className="d-md-flex flex-column justify-content-center align-items-center">
                        <div
                          className="
                            -uppercase
                            processingBank
                            "
                        >
                          {_id ===
                            company_information?.bank_information
                              .selected_bank &&
                            company_information?.bank_information.bank_status}
                        </div>
                        <div>
                          <div className="text-small-custom">
                            Your bank account is being processed
                          </div>
                        </div>
                      </div>
                    )}
                    {(_id ===
                      company_information?.bank_information.selected_bank &&
                      company_information?.bank_information.bank_status) ===
                      "active" && (
                      <div className="d-md-flex flex-column justify-content-center align-items-center">
                        <div
                          className="
                            -uppercase
                            activeBank
                            "
                        >
                          {_id ===
                            company_information?.bank_information
                              .selected_bank &&
                            company_information?.bank_information.bank_status}
                        </div>
                        <div>
                          <div className="text-small-custom ">
                            Your bank account is active
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <h3 className="mt-2">Facilities</h3>
          <ul className="list-group ">
            {facilities?.map((facility, index) => (
              <li key={index} className="list-group-item">
                <strong>{facility.facility_name}</strong>
                <div>{facility.description}</div>
              </li>
            ))}
          </ul>
          <h3 className="mt-4">Additional Information</h3>
          <ul className="list-group list-group-flush ">
            <li className="list-group-item">
              <strong>Headquarters:</strong> {additional_info?.headquarters}
            </li>
            <li className="list-group-item">
              <strong>Founded:</strong> {additional_info?.founded}
            </li>
            <li className="list-group-item">
              <strong>Branch Network:</strong> {additional_info?.branch_network}
            </li>
            <li className="list-group-item">
              <strong>Commitment:</strong> {additional_info?.commitment}
            </li>
            <li className="list-group-item">
              <strong>Services:</strong>
              <ul className="row ">
                {additional_info?.services.map((service, index) => (
                  <li className="" key={index}>
                    {service}
                  </li>
                ))}
              </ul>
            </li>
            <li className="list-group-item">
              <strong>Online Presence:</strong>
              <ul>
                <li>{additional_info?.online_presence?.mobile_app}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
