import React from "react";

export default function PaginationNew({
  currentPage,
  totalPages,
  goToPage,
  prevPage,
  nextPage,
}) {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const minPagesToShow = 2;

    // Calculate the range of pages to show
    let startPage = Math.max(1, currentPage - minPagesToShow);
    let endPage = Math.min(totalPages, currentPage + minPagesToShow);

    // Adjust startPage and endPage to avoid duplicates
    if (startPage === 1) {
      endPage = Math.min(totalPages, minPagesToShow * 2 + 1);
    }
    if (endPage === totalPages) {
      startPage = Math.max(1, totalPages - minPagesToShow * 2);
    }

    // Add the first page if it's not included in the range
    if (startPage > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => goToPage(1)}
          className={`btn ${
            currentPage === 1 ? "btn-primary text-white active" : "btn-link"
          }`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(<span key="ellipsis-1">...</span>);
      }
    }

    // Add the page numbers within the range
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`btn ${
            currentPage === i ? "btn-primary text-white active" : "btn-link"
          }`}
        >
          {i}
        </button>
      );
    }

    // Add the last page if it's not included in the range
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis-2">...</span>);
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => goToPage(totalPages)}
          className={`btn ${
            currentPage === totalPages
              ? "btn-primary text-white active"
              : "btn-link"
          }`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-3">
      <button
        className="btn btn-link"
        onClick={prevPage}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      {renderPageNumbers()}
      <button
        className="btn btn-link"
        onClick={nextPage}
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
}
