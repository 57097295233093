import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useUpdateProfileMutation,
  useUpdateProfilePictureMutation,
} from "../../redux/api/userApi";
import toast from "react-hot-toast";

import { useGetCurrentUserQuery } from "../../redux/features/auth/authApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FaCircleUser } from "react-icons/fa6";
import socket from "../../socket/socket";

const Profile = () => {
  const [activeButton, setActiveButton] = useState("profile");
  const [firstName, setFirstName] = useState("");
  console.log(firstName);
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null); // State to store image preview URL
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();
  const { data: userData, refetch } = useGetCurrentUserQuery();
  const user = userData?.data;
  const avatar = userData?.data?.avatar; // Add timestamp to avatar URL
  const [updateProfilePicture, { isLoading: isUploading }] =
    useUpdateProfilePictureMutation();

  useEffect(() => {
    socket.on("profile_picture_update", () => {
      refetch();
      setFilePreview(null);
    });
    return () => {
      socket.off("profile_picture_update");
    };
  }, [userData, refetch]);

  useEffect(() => {
    if (userData) {
      setFirstName(userData.data.firstName);
      setLastName(userData.data.lastName);
    }
  }, [userData]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const validateName = (name, fieldName) => {
    if (!name) {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: false,
      }));
      return;
    }
    const minLength = 2; // Minimum length for name
    const namePattern = /^[a-zA-Z ""]+$/;
    const isValidName = name.length >= minLength && namePattern.test(name);
  };
  const handleFirstNameChange = (event) => {
    const firstNameValue = event.target.value;
    if (firstNameValue.length <= 50) {
      setFirstName(firstNameValue);
      validateName(firstNameValue, "firstName");
    }
  };

  const handleLastNameChange = (event) => {
    const lastNameValue = event.target.value;
    if (lastNameValue.length <= 50) {
      setLastName(lastNameValue);
      validateName(lastNameValue, "lastName");
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validatePassword(event.target.value);
  };

  const validatePassword = (password) => {
    const uppercasePattern = /[A-Z]/;
    const specialCharacterPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const numericPattern = /\d/;

    let isValidPassword = true;
    let errorMessage = "";
    if (password.length < 8) {
      isValidPassword = false;
      errorMessage = "Password must be at least 8 characters long";
    }
    if (!uppercasePattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one uppercase letter";
    }
    if (!specialCharacterPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one special character";
    }
    if (!numericPattern.test(password)) {
      isValidPassword = false;
      errorMessage = "Password must contain at least one numeric digit";
    }
    setErrors((prev) => ({
      ...prev,
      password: isValidPassword ? null : errorMessage,
    }));
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append("firstName", firstName);
  //   formData.append("lastName", lastName);
  //   formData.append("password", password);
  //   if (file) {
  //     formData.append("avatar", file);
  //   }
  //   try {
  //     const result = await updateProfile(formData).unwrap();
  //     console.log(result);
  //     if (result?.success === true) {
  //       toast.success("Profile updated successfully", {
  //         duration: 2500,
  //         style: { background: "#050F26" },
  //         className: "text-white",
  //       });
  //     } else {
  //       toast.error("Failed to update profile");
  //     }
  //   } catch (err) {
  //     toast.error("Failed to update profile");
  //     console.error("Failed to update profile", err);
  //   }
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = { firstName, lastName, password };

    try {
      const result = await updateProfile(formData).unwrap();
      if (result?.success === true) {
        toast.success("Profile updated successfully", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
      } else {
        toast.error("Failed to update profile");
      }
    } catch (err) {
      toast.error("Failed to update profile");
      console.error("Failed to update profile", err);
    }
  };

  const handleUploadProfilePicture = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("avatar", file);

    try {
      const result = await updateProfilePicture(formData).unwrap();
      if (result?.success === true) {
        toast.success("Profile picture updated successfully", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });
      } else {
        toast.error("Failed to upload profile picture");
      }
    } catch (err) {
      toast.error("Failed to update profile picture");
      console.error("Failed to upload profile picture", err);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFile(file);
    // Generate a unique URL each time a new file is selected to avoid caching
    setFilePreview(URL.createObjectURL(file));
  };

  const isSignUpButtonDisabled =
    !firstName ||
    !lastName ||
    !password ||
    Object.values(errors).some((error) => error !== null);

  const isProfileUploadButtonDisabled =
    !file || Object.values(errors).some((error) => error !== null);

  return (
    <div  >
      <div className=" card p-4 m-md-4">
        <div className="profile_bg">
          <div className="row Id-row">
            <div className="lottie_update">
              <iframe src="https://lottie.host/embed/17a88c09-b8cf-436e-8675-d4ca915b5af5/H76TkDGmRO.json"></iframe>
            </div>
            <div className="col-md-3">
              <div className="profile_button mb-md-0 mb-3 d-md-block d-flex gap-1">
                <Link
                  to="/dashboards/profile"
                  className={`profilebtn ${
                    activeButton === "profile" ? "active" : ""
                  }`}
                  onClick={() => handleButtonClick("profile")}
                >
                  Profile
                </Link>
                <br />
                <Link
                  to="/dashboards/updatepassword"
                  onClick={() => handleButtonClick("password")}
                  className={`profilebtn ${
                    activeButton === "password" ? "active" : ""
                  }`}
                >
                  Password
                </Link>
              </div>
            </div>
            <div className="col-md-9 d-md-flex d-flex flex-md-row flex-column-reverse">
              <div className="col-md-8">
                <div className="edit_profile">
                  <h4>Edit Profile</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter your first name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                      />
                      {errors.firstName && (
                        <p className="error-message">{errors.firstName}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        placeholder="Enter your last name here"
                        value={lastName}
                        onChange={handleLastNameChange}
                      />
                      {errors.lastName && (
                        <p className="error-message">{errors.lastName}</p>
                      )}
                    </div>
                    <div className="">
                      <label htmlFor="password">
                        <strong>Password</strong>
                      </label>
                      <div className="input-group">
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          placeholder="Enter Your Password here"
                          value={password}
                          onChange={handlePasswordChange}
                          className="form-control rounded-0"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary eye_btn"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={isPasswordVisible ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                      {errors.password && (
                        <span className="text-danger"> {errors.password}</span>
                      )}
                      <div className="text-muted" style={{ fontSize: "12px" }}>
                        (Minimum 8 characters with one uppercase, one number,
                        and one special character. Example: P@ss1234)
                      </div>
                    </div>
                    <button
                      disabled={isSignUpButtonDisabled}
                      className={`btn my-2 py-1 px-4 btn_up update-profile-button btn-primary${
                        isSignUpButtonDisabled ? " disabled" : ""
                      }`}
                      type="submit"
                    >
                      {isUpdating ? "Submitting..." : "Update Profile"}
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-md-4 justify-content-center gap-2">
                <div className="d-md-flex flex-column align-items-center justify-content-center">
                  <div className="choose_image d-flex align-items-center">
                    <img
                      src="https://static.thenounproject.com/png/363640-200.png"
                      alt="placeholder"
                      className="placeholder-icon"
                    />
                    <label htmlFor="file-input" className="file-label">
                      <span className="file-label-text">Choose File</span>
                    </label>
                    <input
                      id="file-input"
                      className="file-input"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                  <span className="file-name">
                    {file ? file.name : "No file chosen"}
                  </span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mt-md-5 my-md-0 my-3">
                  {filePreview ? (
                    <img
                      src={filePreview}
                      alt="Profile"
                      className="w-50 h-50 border border-black border-1"
                      key={filePreview} // Adding a key prop to force re-render on change
                    />
                  ) : (
                    <>
                      {avatar ? (
                        <img
                          src={`${avatar}?${new Date().getTime()}`}
                          alt="Profile"
                          className="w-50 h-50 border border-black border-1"
                          // Ensure a unique key for avatar
                        />
                      ) : (
                        <FaCircleUser className="user-management-avatar-icon w-50 h-50" />
                      )}
                    </>
                  )}
                  <button
                    disabled={isProfileUploadButtonDisabled}
                    className={`btn my-2 py-1 px-4 btn_up update-profile-button btn-primary${
                      isProfileUploadButtonDisabled ? " disabled" : ""
                    }`}
                    onClick={handleUploadProfilePicture}
                  >
                    {isUploading ? "Uploading..." : "Update Profile Picture"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
